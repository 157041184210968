import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyDe9BnZwALCWY-xKaAidixMHdnEcKfX58M',
  authDomain: 'siamaas-prod.firebaseapp.com',
  projectId: 'siamaas-prod',
  storageBucket: 'siamaas-prod.appspot.com',
  messagingSenderId: '873192270538',
  appId: '1:873192270538:web:eabc3dc56ba49518272233',
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);

export default app;
