import { QueryClient, useQueryClient } from "react-query";
import { useRecoilValue } from "recoil";
import { fncDetails } from "../config/selector";
export const queryClient = new QueryClient();

export function Vpermission() {
  const queryParams = useRecoilValue(fncDetails);

  const _qC = useQueryClient();
  const data = _qC.getQueryData([
    "getPermissionsMatrix",
    queryParams["name"],
    queryParams["id"],
  ]);
  // console.log(data);
  return data !== undefined ? data["data"] : {};
}