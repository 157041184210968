import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Get, Post, Put } from 'utils/AxiosUtils';

import {
  updatePmRecord,
  savePmRecord,
  getPMAction,
  postActivityAction,
  savePMTeamAction,
  getStatusTimeline,
  updatePMTeamAction,
} from 'utils/EndPoints';

const initialState = {
  getPMActionByIdData: [],
  getTimelineData: {},
  initialValues: {
    serviceId: null,
    serviceName: '',
    ciId: '',
    ciCode: '',
    ciName: '',
    serviceCriticalityId: '',
    serviceCriticalityVal: '',
    urgencyId: '',
    urgencyVal: '',
    priorityId: '',
    priorityVal: '',
    consumerCompanyId: '',
    consumerCompanyName: '',
    locationId: '',
    locationName: '',
    asgUserName: '',
    asgUserFullname: '',
    asgGroupId: '',
    asgGroupName: '',
    asgCompanyId: '',
    asgCompanyName: '',
    statusId: '9',
    statusVal: 'Assigned',
    requestedByName: '',
    requestedByFullname: '',
    requestedForName: '',
    requestedForFullname: '',
    createdByName: null,
    createdByFullname: null,
    modifiedByName: null,
    modifiedByFullname: null,
    reportedThroughId: '',
    reportedThroughVal: '',
    description: '',
    additionalInfo: '',
    categoryId: '',
    catTier1: '',
    catTier2: '',
    createdThrough: '',
    updatedThrough: '',
    externalEmails: [],
  },

  status: 'idle',
  error: null,
};

export const getTimeline = createAsyncThunk(
  'problem/getTimeline',
  async ({ pmId, companyID }) => {
    const response = await Get(
      getStatusTimeline(pmId) + `?tenantId=${companyID}`,
      companyID
    );
    return response.data;
  }
);

export const getPMActionById = createAsyncThunk(
  'problem/getPMAction',
  async ({ pmId, companyID }, thunkAPI) => {
    const response = await Get(
      getPMAction(pmId) + `?tenantId=${companyID}`,
      companyID
    );
    thunkAPI.dispatch(
      getTimeline({
        pmId: response?.data?.data?.id,
        companyID: response?.data?.data?.consumerCompanyId,
      })
    );
    return response.data;
  }
);

export const createPM = createAsyncThunk(
  'problem/crete',
  async (params, { compID }) => {
    const response = await Post(
      savePmRecord + `?tenantId=${params?.consumerCompanyId}`,
      params,
      params?.consumerCompanyId
    );

    return response.data;
  }
);

export const updatePM = createAsyncThunk(
  'problem/updatePmRecord',
  async ({ params, compID }, thunkAPI) => {
    const response = await Put(
      updatePmRecord + `?tenantId=${params?.consumerCompanyId}`,
      params,
      params?.consumerCompanyId
    );
    thunkAPI.dispatch(
      getTimeline({
        pmId: response?.data?.data?.id,
        companyID: response?.data?.data?.consumerCompanyId,
      })
    );
    thunkAPI.dispatch(
      getPMActionById({
        pmId: response?.data?.data?.id,
        companyID: response?.data?.data?.consumerCompanyId,
      })
    );

    return response.data;
  }
);

export const activityAction = createAsyncThunk(
  'problem/postActivityAction',
  async ({ params, compID }, thunkAPI) => {
    const response = await Post(
      postActivityAction + `?tenantId=${compID}`,
      params,
      compID
    );

    thunkAPI.dispatch(
      getPMActionById({
        pmId: params?.itemId || response?.payload?.data?.itemId,
        companyID: compID,
      })
    );
    return response.data;
  }
);

export const postPMTeamAction = createAsyncThunk(
  'problem/postPMTeamAction',
  async ({ params, compID }, thunkAPI) => {
    const response = await Post(
      savePMTeamAction + `?tenantId=${compID}`,
      params,
      compID
    );

    thunkAPI.dispatch(
      getPMActionById({
        pmId: response?.data?.data?.id,
        companyID: compID,
      })
    );
    return response.data;
  }
);

export const editPMTeamAction = createAsyncThunk(
  'problem/editPMTeamAction',
  async ({ params, compID }, thunkAPI) => {
    const response = await Put(
      updatePMTeamAction + `?tenantId=${compID}`,
      params,
      compID
    );

    return response.data;
  }
);

const problemSlice = createSlice({
  name: 'problem',
  initialState,
  reducers: {
    setInitialValues: (state, action) => {
      state.initialValues = { ...state.initialValues, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPMActionById.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.getPMActionByIdData = action.payload;
      })
      .addCase(getPMActionById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(getTimeline.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.getTimelineData = action.payload;
      })
      .addCase(getTimeline.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(createPM.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(createPM.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(updatePM.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(updatePM.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(activityAction.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(activityAction.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(postPMTeamAction.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(postPMTeamAction.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(editPMTeamAction.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(editPMTeamAction.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { setInitialValues } = problemSlice.actions;
export default problemSlice.reducer;
