import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ForumService from "components/GovernanceMeeting/console/forums/http-services";

const initialState = [];

export const getAllFroums = createAsyncThunk("forum/all", async (params) => {
  const res = await ForumService.getAll(params);
  return res.data;
});

export const createForum = createAsyncThunk("forum/create", async (params) => {
  const res = await ForumService.create(params);
  return res.data;
});

export const updateForum = createAsyncThunk("forum/update", async (params) => {
  const res = await ForumService.update(params);
  return res.data;
});

export const getForumById = createAsyncThunk(
  "forum/getById",
  async (params) => {
    const res = await ForumService.getById(params);
    return res.data;
  }
);

const forumSlice = createSlice({
  name: "forum",
  initialState,
  extraReducers: {
    [createForum.fulfilled]: (state, action) => {
      state.push(action.payload);
    },
    [updateForum.fulfilled]: (state, action) => {
      return action.payload;
    },
    [getAllFroums.fulfilled]: (state, action) => {
      return [...action.payload];
    },
    [getForumById.fulfilled]: (state, action) => {
      return [action.payload];
    },
  },
});

const { reducer } = forumSlice;
export default reducer;
