import { atom, selector, selectorFamily } from 'recoil';
import { Get } from '../utils/AxiosUtils';
import {
  getUserType,
  getPrefix,
  getUserPreferences,
  getAllCountries,
  getAllState,
  getlocationCityByState,
  getAllActiveOrganization,
  getDeptByOrgId,
  AllLocationList,
  AllCostCenterList,
  _permissions,
} from '../utils/EndPoints';

import { useAuth } from '../contexts/AuthContext';

export function _compId() {
  const { sidebarData } = useAuth();
  const userInfo = sidebarData.data;
  const _companyId = userInfo.companyId;
  return _companyId;
}

export const forceref = atom({
  key: 'forceref',
  default: '',
});

export const tnew = atom({
  key: 'tnew',
  default: '',
});
export const getOrgId = atom({
  key: 'getOrgId',
  default: '',
});

export const getcountryId = atom({
  key: 'getcountryId',
  default: '',
});
export const getStateId = atom({
  key: 'getStateId',
  default: '',
});

export const leftPanelData = atom({
  key: 'leftPanelData',
  default: [],
});
export const getcompanyId = atom({
  key: 'getcompanyId',
  default: '',
});
//Users dropdown

export const fetchAllCounties = selector({
  key: 'fetchAllCounties',
  get: async () => {
    try {
      const data = await Get(getAllCountries);
      return data.data.data;
    } catch (error) {
      throw error;
    }
  },
});

export const fetchState = selector({
  key: 'fetchState',
  get: async ({ get }) => {
    try {
      const countryId = get(getcountryId);
      // console.log(countryId);
      const data =
        countryId !== '' && countryId !== 'null' && countryId !== null
          ? await Get(getAllState(countryId))
          : { data: { data: [] } };
      return data.data.data;
    } catch (error) {
      throw error;
    }
  },
});

export const fetchCity = selector({
  key: 'fetchCity',
  get: async ({ get }) => {
    try {
      const countryId = get(getcountryId);
      const stateId = get(getStateId);
      const data =
        stateId !== '' &&
        stateId !== 'null' &&
        countryId !== '' &&
        countryId !== 'null' &&
        stateId
          ? await Get(getlocationCityByState(stateId))
          : { data: { data: [] } };
      return data.data.data;
    } catch (error) {
      throw error;
    }
  },
});

export const fetchActiveOrg = selector({
  key: 'fetchActiveOrg',
  get: async ({ get }) => {
    try {
      const compId = get(getcompanyId);

      const data =
        compId !== ''
          ? await Get(getAllActiveOrganization, compId)
          : { data: { data: [] } };
      return data.data.data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  // set: ({ set }, newValue) => set(getcompanyId, newValue),
});

export const fetchActiveDept = selector({
  key: 'fetchActiveDept',
  get: async ({ get }) => {
    try {
      const orgId = get(getOrgId);
      const compId = get(getcompanyId);

      const data =
        compId && orgId !== '' && orgId !== 'null' && orgId !== null
          ? await Get(getDeptByOrgId(orgId), compId)
          : { data: { data: [] } };
      return data.data.data;
    } catch (error) {
      throw error;
    }
  },
});

export const fetchActiveLocations = selector({
  key: 'fetchActiveLocations',
  get: async ({ get }) => {
    try {
      const compId = get(getcompanyId);

      const data =
        compId !== ''
          ? await Get(AllLocationList + '?status=1', compId)
          : { data: { data: [] } };
      return data.data.data;
    } catch (error) {
      throw error;
    }
  },
});
export const fetchActiveCostC = selector({
  key: 'fetchActiveCostC',
  get: async ({ get }) => {
    try {
      const compId = get(getcompanyId);

      const data =
        compId !== ''
          ? await Get(AllCostCenterList + '?status=1', compId)
          : { data: { data: [] } };
      return data.data.data;
    } catch (error) {
      throw error;
    }
  },
});

export const getPrefixData = selector({
  key: 'getPrefixData',
  get: async ({ get }) => {
    try {
      const compId = get(getcompanyId);
      const data = await Get(getPrefix, compId);
      return data.data.data;
    } catch (error) {
      throw error;
    }
  },
});

export const getUserTypeData = selector({
  key: 'getUserTypeData',
  get: async () => {
    try {
      const data = await Get(getUserType, _compId());
      return data.data.data;
    } catch (error) {
      throw error;
    }
  },
});

export const myDataQuery = selectorFamily({
  key: 'MyDataQuery',
  get:
    (queryParameters) =>
    async ({ get }) => {
      try {
        const pref = queryParameters.userPref;
        const compId = get(getcompanyId);
        if (pref !== '') {
          const data = await Get(getUserPreferences + '/' + pref, compId);
          return data.data.data;
        }
      } catch (error) {
        throw error;
      }
    },
});

//fntable tags

export const tagsQueryData = atom({
  key: 'tagsQueryData',
  default: [],
});

export const tablerowData = atom({
  key: 'tablerowData',
  default: [],
});

export const locTblData = atom({
  key: 'locTblData',
  default: {},
});
export const companyTblData = atom({
  key: 'companyTblData',
  default: {},
});
export const groupTblData = atom({
  key: 'groupTblData',
  default: {},
});
export const userTableRowData = atom({
  key: 'userTableRowData',
  default: [],
});

export const checkExpand = atom({
  key: 'checkExpand',
  default: false,
});

export const searchTextState = atom({
  key: 'searchTextState',
  default: '',
});
/*User Association*/

export const companiesAssociation = atom({
  key: 'companiesAssociation',
  default: [],
});

export const notCompaniesAssociation = atom({
  key: 'notCompaniesAssociation',
  default: [],
});

export const groupAssociation = atom({
  key: 'groupAssociation',
  default: [],
});

export const notGroupAssociation = atom({
  key: 'notGroupAssociation',
  default: [],
});

export const rolesAssociation = atom({
  key: 'rolesAssociation',
  default: [],
});

export const notRolesAssociation = atom({
  key: 'notRolesAssociation',
  default: [],
});

export const userInfoData = atom({
  key: 'userInfoData',
  default: {},
});
export const fnShowFilter = atom({
  key: 'fnShowFilter',
  default: false,
});
export const allFilterValues = atom({
  key: 'allFilterValues',
  default: {},
});
export const allFilterIds = atom({
  key: 'allFilterIds',
  default: {},
});

export const statusFilterIds = atom({
  key: 'statusFilterIds',
  default: {},
});

export const tblHeaders = atom({
  key: 'tblHeaders',
  default: [],
});
export const tblListData = atom({
  key: 'tblListData',
  default: [],
});

export const favAtom = atom({
  key: `favs`,
  default: [],
});

export const queryConfigFields = atom({
  key: `queryConfigFields`,
  default: [],
});
export const filteredConfig = selector({
  key: 'filteredConfig',
  get: ({ get }) => get(queryConfigFields),
});

export const miniOverlay = atom({
  key: 'miniOverlay',
  default: false,
});
export const fncDetails = atom({
  key: 'fncDetails',
  default: {
    name: '',
    id: '',
  },
});

const getPermissions = async (cid, fName, fId) => {
  const { data } = await Get(
    `${_permissions}?functionId=${fId}&functionName=${fName}`,
    cid
  );
  return data;
};
//const cid = sidebarData.data.companyId;

//function usePermissions() {
// return useQuery(
//   ["getPermissionsMatrix", fName, fId],
//   () => getPermissions(1, fName, fId),
//   {
//     enabled:
//       fName !== "" && fId !== "" && fName !== undefined && fId !== undefined,
//     refetchOnWindowFocus: false,
//     retry: false,
//   }
// );
// //}

export const permissionsD = selector({
  key: 'permissionsD',
  get: async ({ get }) => {
    try {
      const name = get(fncDetails)['name'];
      const id = get(fncDetails)['id'];
      if (name !== '' && id !== '') {
        const data = getPermissions(1, name, id);
        return data;
      } else {
        return [];
      }
    } catch (error) {
      throw error;
    }
  },
});
export const showHideSettings = atom({
  key: 'showHideSettings',
  default: false,
});
export const showHideTheme = atom({
  key: 'showHideTheme',
  default: false,
});
export const unifoThemes = atom({
  key: 'unifoThemes',
  default: '',
});
export const preferencesData = atom({
  key: 'preferencesData',
  default: {},
});
export const activeState = atom({
  key: 'activeState',
  default: false,
});
export const sysInfoCompany = atom({
  key: 'sysInfoCompany',
  default: {},
});
export const sysInfoGroup = atom({
  key: 'sysInfoGroup',
  default: {},
});
export const userThemeData = atom({
  key: 'userThemeData',
  default: {},
});
export const apiSuccessMsgData = atom({
  key: 'apiSuccessMsgData',
  default: {
    msg: '',
    visible: false,
    type: 'success',
  },
});
export const apiSuccessMsgDataSave = atom({
  key: 'apiSuccessMsgDataSave',
  default: {
    msg: '',
    visible: false,
    type: 'success',
  },
});
export const pdfSuccess = atom({
  key: 'pdfSuccess',
  default: false,
});
