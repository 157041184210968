import { createSlice } from "@reduxjs/toolkit";
import { Get, Post, Upload } from "../../utils/AxiosUtils";
import {
  worknote,
  saveworknote,
  save_worknote_attachment,
} from "../../utils/IncidentEndpoints";

export const initialState = {
  loading: false,
  hasErrors: false,
  worknotes: [],
  savenotsLoading: false,
  saveWorknotes: [],
  saveattachmentLoading: false,
};

const worknotesSlice = createSlice({
  name: "worknotes",
  initialState,
  reducers: {
    addComment: (state, action) => {
      state.savenotsLoading = true;
    },
    getWorkNotes: (state) => {
      state.loading = true;
    },
    getWorkNotesSuccess: (state, { payload }) => {
      state.worknotes = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    getWorkNotesFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    saveWorkNotesSuccess: (state, { payload }) => {
      state.saveWorknotes = payload;
      state.savenotsLoading = false;
      state.hasErrors = false;
    },
    saveAttachmentSuccess: (state, { payload }) => {
      state.saveattachmentLoading = false;
      state.hasErrors = false;
    },
  },
});
export const {
  getWorkNotes,
  addComment,
  getWorkNotesSuccess,
  saveWorkNotesSuccess,
  getWorkNotesFailure,
  saveAttachmentSuccess,
} = worknotesSlice.actions;

// A selector
export const worknotesSelector = (state) => state.worknotes;

// The reducer
export default worknotesSlice.reducer;

// Asynchronous thunk action
export function fetchWorknotes(id) {
  return async (dispatch) => {
    dispatch(getWorkNotes());
    try {
      await Get(
        `${worknote}?filter[where][taskCode]=${id}&filter[include][worknoteAttachment]&filter[order]=createdDate desc`
      ).then((res) => {
        dispatch(getWorkNotesSuccess(res["data"]));
      });
    } catch (error) {
      dispatch(getWorkNotesFailure());
    }
  };
}
// Asynchronous thunk action
export function postWorknotes(
  postData,
  hideNotes,
  setSubmitting,
  impCompID,
  formData,
  AttachmentData,
  taskCode
) {
  return async (dispatch) => {
    dispatch(addComment());
    try {
      const res = await Post(`${saveworknote}`, postData).then((response) => {
        if (AttachmentData) {
          AttachmentData = {
            ...AttachmentData,
            activityId: response["data"].id,
          };
          const Objdata = JSON.stringify(AttachmentData);
          formData.append("attachment", Objdata);
          dispatch(
            saveWNAttachment(
              formData,
              hideNotes,
              impCompID,
              setSubmitting,
              taskCode
            )
          );
        }
        dispatch(saveWorkNotesSuccess(response["data"]));
        return response;
      });
      if (
        res?.status === 200 &&
        Object.keys(res?.data).length &&
        !AttachmentData
      ) {
        hideNotes();
        dispatch(fetchWorknotes(res["data"].taskCode));
        setSubmitting();
      }
    } catch (error) {
      dispatch(getWorkNotesFailure());
    }
  };
}

export function saveWNAttachment(
  postData,
  hideNotes,
  impCompID,
  setSubmitting,
  taskCode
) {
  return async (dispatch) => {
    // dispatch(addComment());
    try {
      await Upload(
        save_worknote_attachment(taskCode),
        postData,
        impCompID
      ).then((response) => {
        // dispatch(saveAttachmentSuccess(response["data"]));
        hideNotes();
        dispatch(fetchWorknotes(taskCode));
        setSubmitting();
      });
    } catch (error) {
      dispatch(getWorkNotesFailure());
    }
  };
}
