/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Loader from 'react-loader-spinner';
import SimpleBar from 'simplebar-react';
import { NavLink, withRouter } from 'react-router-dom';
import { Collapse } from 'reactstrap';

import { setPermissionsList } from 'slices/common';

import { AuthContext } from '../../../contexts/AuthContext';

const SimpleBarWrapper = ({ children, isCompact }) => {
  return !isCompact ? (
    <SimpleBar style={{ height: '70vh' }} autoHide={false} className="simpl">
      {children}
    </SimpleBar>
  ) : (
    <>{children}</>
  );
};

export function AddtoFavWidget(props) {
  return (
    <AuthContext.Consumer>
      {(value) => {
        let favs =
          value?.sidebarNav?.[props.index]?.modules[props.subindex]?.children?.[
            props.childerIndex
          ]?.isFav;
        return (
          <span
            key={props.keys}
            onClick={props.toggleinFav}
            className={`favourite-icon mr-1 ${favs ? 'filled' : 'outline'}`}
          ></span>
        );
      }}
    </AuthContext.Consumer>
  );
}

export const SidebarItems = withRouter(
  ({
    value,
    expandMenu,
    fnClick,
    setIsLanding,
    isSidebarLoading,
    toggle,
    setOpen,
    open,
    location,
    toggleSub,
    openSub,
    userInfo,
    saveFav,
    isFvLoading,
    miniSidebar,
    modulename,
    isSearching,
  }) => {
    const dispatch = useDispatch();

    const getSidebar_ItemClass = (path) => {
      return location.pathname.indexOf(path) !== -1 ||
        (path.indexOf(modulename.name.replace(/\s+/g, '-')) !== 0 &&
          path.indexOf(modulename.name.replace(/\s+/g, '-')) !== -1) ||
        (location.pathname === '/' && path === '/dashboard')
        ? 'active'
        : '';
    };

    const [collapse, setCollapse] = useState(0);
    const [miniCollapse, setMiniCollapse] = useState(0);

    const miniToggle = (id) => {
      setMiniCollapse(miniCollapse === id ? 0 : id);
      // open1 === id ? setOpen1("") : setOpen1(id);
    };

    const toggle1 = (id) => {
      setCollapse(collapse === id ? 0 : id);
      // open1 === id ? setOpen1("") : setOpen1(id);
    };

    const [subCollapse, setSubCollapse] = useState(0);

    const toggleInner = (id) => {
      setSubCollapse(subCollapse === id ? '-10' : id);
    };

    const toggleinFav = (
      e,
      savefav,
      index,
      subindex,
      childerIndex,
      isfav,
      mid,
      fid,
      userId
    ) => {
      e.preventDefault();
      e.stopPropagation();
      const postData = [
        {
          userId: userId,
          functionId: fid,
          isFav: !isfav,
        },
      ];
      savefav({ postData });
      value[index].modules[subindex].children[childerIndex].isFav = !isfav;
    };

    const MiniSidebarWrapper = ({ children, index, id }) => {
      return miniSidebar ? (
        <Collapse
          className="accordion-body"
          isOpen={miniCollapse === `mini_cat_${index}`}
        >
          {children}
        </Collapse>
      ) : (
        <>{children}</>
      );
    };

    const isLandingPage = async () => {
      value.map((category, index) => {
        return category.groupName
          ? category.modules.map((subcategory, subindex) => {
              return (
                subcategory.children !== false &&
                subcategory.children.length !== 0 &&
                subcategory.children.map((childrenItems, childerIndex) => {
                  const pathName = `/${
                    category.groupName
                      ? category.groupName.replace(/\s+/g, '')
                      : ''
                  }/${subcategory.name?.replace(
                    /\s+/g,
                    ''
                  )}/${childrenItems.functionName.replace(/\s+/g, '-')}`;

                  if (
                    (childrenItems.isLanding === 'true' ||
                      childrenItems.isLanding === true) &&
                    location.pathname === pathName
                  ) {
                    expandMenu(subcategory.moduleId, index, category.groupName);
                    toggle1(`cat_${index}`);
                    miniToggle(`mini_cat_${index}`);
                    toggleInner(`sub_cat_${subindex}_${subcategory.moduleId}`);
                    fnClick(
                      childrenItems.functionId,
                      childrenItems.functionName,
                      pathName
                    );
                    dispatch(
                      setPermissionsList({
                        name: childrenItems.functionId,
                        id: childrenItems.functionName,
                      })
                    );
                  }
                })
              );
            })
          : category.modules.map((modulecategory, modindex) => {
              return (
                modulecategory.children !== false &&
                modulecategory.children.length !== 0 &&
                modulecategory.children.map((childrenItems, childerIndex) => {
                  const pathName = `/${modulecategory.name.replace(
                    /\s+/g,
                    ''
                  )}/${childrenItems.functionName.replace(/\s+/g, '-')}`;

                  if (
                    (childrenItems.isLanding === 'true' ||
                      childrenItems.isLanding === true) &&
                    location.pathname === pathName
                  ) {
                    expandMenu(
                      modulecategory.moduleId,
                      index,
                      category.groupName
                    );
                    toggle1(
                      `cat_${modulecategory.moduleId}_${Number(
                        modindex + 99990
                      )}`
                    );
                    fnClick(
                      childrenItems.functionId,
                      childrenItems.functionName,
                      pathName
                    );
                    dispatch(
                      setPermissionsList({
                        name: childrenItems.functionId,
                        id: childrenItems.functionName,
                      })
                    );
                  }
                })
              );
            });
      });
    };

    useEffect(() => {
      isLandingPage();
    }, [location.pathname]);

    return (
      <SimpleBarWrapper isCompact={miniSidebar}>
        <div className="accordion-group">
          {value.map((category, index) => {
            return category.groupName ? (
              <div className="position-relative" key={`key_${index}_cat`}>
                <a
                  id={`cat_${index}`}
                  className={
                    collapse === `cat_${index}`
                      ? 'accordion-header open'
                      : 'accordion-header'
                  }
                  onClick={(e) => {
                    toggle1(`cat_${index}`);
                  }}
                  onMouseEnter={(e) => {
                    if (miniSidebar) {
                      toggle1(`cat_${index}`);
                    }
                  }}
                >
                  <i
                    className={`fa mr-1 ${category.groupIcon}`}
                    aria-hidden="true"
                  ></i>
                  {category.groupName}
                </a>
                <Collapse
                  className="accordion-body"
                  isOpen={collapse === `cat_${index}`}
                >
                  {miniSidebar && (
                    <a
                      id={`mini_cat_${index}`}
                      className={
                        collapse === `cat_${index}`
                          ? 'accordion-header open'
                          : 'accordion-header'
                      }
                      onClick={(e) => {
                        miniToggle(`mini_cat_${index}`);
                      }}
                    >
                      <i
                        className={`fa mr-1 ${category.groupIcon}`}
                        aria-hidden="true"
                      ></i>
                      {category.groupName}
                    </a>
                  )}

                  {/* <Collapse
                    className="accordion-body"
                    isOpen={
                      miniCollapse === `mini_cat_${index}` &&
                      collapse === `cat_${index}`
                    }
                  > */}
                  <MiniSidebarWrapper index={index} id={`cat_${index}`}>
                    {category.modules.map((subcategory, subindex) => {
                      return (
                        <div
                          className="position-relative"
                          key={`key_div_${subindex}`}
                        >
                          <p
                            className={
                              subCollapse ===
                              `sub_cat_${subindex}_${subcategory.moduleId}`
                                ? 'accordion-header open'
                                : 'accordion-header'
                            }
                            id={`sub_cat_${subindex}_${subcategory.moduleId}`}
                            onClick={(e) => {
                              toggleInner(
                                `sub_cat_${subindex}_${subcategory.moduleId}`
                              );
                              expandMenu(
                                subcategory.moduleId,
                                index,
                                category.groupName
                              );
                            }}
                          >
                            <i
                              className={`fa mr-1 ${subcategory.moduleIcon}`}
                              aria-hidden="true"
                            ></i>
                            {subcategory.name}
                          </p>

                          <Collapse
                            className="accordion-body"
                            isOpen={
                              subCollapse ===
                              `sub_cat_${subindex}_${subcategory.moduleId}`
                            }
                          >
                            {isSidebarLoading &&
                              subCollapse ===
                                `sub_cat_${subindex}_${subcategory.moduleId}` && (
                                <ul className="last-level">
                                  <li className="text-center">
                                    <Loader
                                      type="ThreeDots"
                                      className="sidebar-loader"
                                      height={5}
                                    />
                                  </li>
                                </ul>
                              )}

                            <ul className="last-level">
                              {subcategory.children !== false &&
                                subcategory.children.length !== 0 &&
                                subcategory.children.map(
                                  (childrenItems, childerIndex) => {
                                    return (
                                      <li
                                        key={`key_li_${childrenItems.functionId}`}
                                        className={getSidebar_ItemClass(
                                          `/${
                                            category.groupName
                                              ? category.groupName.replace(
                                                  /\s+/g,
                                                  ''
                                                )
                                              : ''
                                          }/${subcategory.name.replace(
                                            /\s+/g,
                                            ''
                                          )}/${childrenItems.functionName.replace(
                                            /\s+/g,
                                            '-'
                                          )}`
                                        )}
                                      >
                                        <NavLink
                                          activeClassName="active"
                                          data-fid={childrenItems.functionId}
                                          data-fname={
                                            childrenItems.functionName
                                          }
                                          to={`/${
                                            category.groupName
                                              ? category.groupName.replace(
                                                  /\s+/g,
                                                  ''
                                                )
                                              : ''
                                          }/${subcategory.name.replace(
                                            /\s+/g,
                                            ''
                                          )}/${childrenItems.functionName.replace(
                                            /\s+/g,
                                            '-'
                                          )}`}
                                          onClick={() => {
                                            fnClick(
                                              childrenItems.functionId,
                                              childrenItems.functionName,
                                              `/${
                                                category.groupName
                                                  ? category.groupName.replace(
                                                      /\s+/g,
                                                      ''
                                                    )
                                                  : ''
                                              }/${subcategory.name.replace(
                                                /\s+/g,
                                                ''
                                              )}/${childrenItems.functionName.replace(
                                                /\s+/g,
                                                '-'
                                              )}`
                                            );
                                          }}
                                        >
                                          <i className="mini-icon mr-1 fa fa-dot-circle-o"></i>
                                          <span className="flex-grow-1">
                                            {childrenItems.functionName}
                                          </span>

                                          <AddtoFavWidget
                                            functionId={
                                              childrenItems.functionId
                                            }
                                            isfav={childrenItems.isFav}
                                            mid={subcategory.moduleId}
                                            keys={`all${childrenItems.functionId}`}
                                            userId={userInfo.user_id}
                                            saveFav={saveFav}
                                            isFvLoading={isFvLoading}
                                            index={index}
                                            subindex={subindex}
                                            childerIndex={childerIndex}
                                            tabId="all"
                                            toggleinFav={(e) =>
                                              toggleinFav(
                                                e,
                                                saveFav,
                                                index,
                                                subindex,
                                                childerIndex,
                                                childrenItems.isFav,
                                                subcategory.moduleId,
                                                childrenItems.functionId,
                                                userInfo.user_id
                                              )
                                            }
                                          />
                                        </NavLink>
                                      </li>
                                    );
                                  }
                                )}
                            </ul>
                          </Collapse>
                        </div>
                      );
                    })}
                  </MiniSidebarWrapper>
                </Collapse>
              </div>
            ) : (
              category.modules.map((modulecategory, modindex) => {
                return (
                  <div
                    className="position-relative"
                    key={`key_cat_${Number(modindex + 99990)}`}
                  >
                    <a
                      className={
                        collapse ===
                        `cat_${modulecategory.moduleId}_${Number(
                          modindex + 99990
                        )}`
                          ? 'accordion-header open'
                          : 'accordion-header'
                      }
                      id={`cat_${modulecategory.moduleId}_${Number(
                        modindex + 99990
                      )}`}
                      onClick={(e) => {
                        toggle1(
                          `cat_${modulecategory.moduleId}_${Number(
                            modindex + 99990
                          )}`
                        );
                        expandMenu(
                          modulecategory.moduleId,
                          index,
                          category.groupName
                        );
                      }}
                      onMouseEnter={(e) => {
                        if (miniSidebar) {
                          toggle1(
                            `cat_${modulecategory.moduleId}_${Number(
                              modindex + 99990
                            )}`
                          );
                          expandMenu(
                            modulecategory.moduleId,
                            index,
                            category.groupName
                          );
                        }
                      }}
                    >
                      <i
                        className={`fa mr-1 ${modulecategory.moduleIcon}`}
                        aria-hidden="true"
                      ></i>
                      {modulecategory.name}
                    </a>

                    <Collapse
                      className="accordion-body"
                      isOpen={
                        collapse ===
                        `cat_${modulecategory.moduleId}_${Number(
                          modindex + 99990
                        )}`
                      }
                    >
                      {miniSidebar && (
                        <a
                          id={`mini_cat_${index}`}
                          className={
                            collapse ===
                            `cat_${modulecategory.moduleId}_${Number(
                              modindex + 99990
                            )}`
                              ? 'accordion-header open'
                              : 'accordion-header'
                          }
                          onClick={(e) => {
                            miniToggle(`mini_cat_${index}`);
                          }}
                        >
                          <i
                            className={`fa mr-1 ${modulecategory.moduleIcon}`}
                            aria-hidden="true"
                          ></i>
                          {modulecategory.name}
                        </a>
                      )}

                      <MiniSidebarWrapper
                        index={index}
                        id={`mini_cat_${index}`}
                      >
                        {isSidebarLoading &&
                          collapse ===
                            `cat_${modulecategory.moduleId}_${Number(
                              modindex + 99990
                            )}` && (
                            <ul className="last-level">
                              <li className="text-center">
                                <Loader
                                  type="ThreeDots"
                                  className="sidebar-loader"
                                  height={5}
                                />
                              </li>
                            </ul>
                          )}
                        <ul className="last-level">
                          {modulecategory.children !== false &&
                            modulecategory.children.length !== 0 &&
                            modulecategory.children.map(
                              (childrenItems, childerIndex) => {
                                return (
                                  <li
                                    className={getSidebar_ItemClass(
                                      `/${modulecategory.name.replace(
                                        /\s+/g,
                                        ''
                                      )}/${childrenItems.functionName.replace(
                                        /\s+/g,
                                        '-'
                                      )}`
                                    )}
                                  >
                                    <NavLink
                                      className="d-flex"
                                      activeClassName="active"
                                      data-fid={childrenItems.functionId}
                                      data-fname={childrenItems.functionName}
                                      to={`/${modulecategory.name.replace(
                                        /\s+/g,
                                        ''
                                      )}/${childrenItems.functionName.replace(
                                        /\s+/g,
                                        '-'
                                      )}`}
                                      onClick={() =>
                                        fnClick(
                                          childrenItems.functionId,
                                          childrenItems.functionName,
                                          `/${modulecategory.name.replace(
                                            /\s+/g,
                                            ''
                                          )}/${childrenItems.functionName.replace(
                                            /\s+/g,
                                            '-'
                                          )}`
                                        )
                                      }
                                    >
                                      <i className="mini-icon mr-1 fa fa-dot-circle-o"></i>
                                      <span className="flex-grow-1">
                                        {childrenItems.functionName}
                                      </span>

                                      <AddtoFavWidget
                                        functionId={childrenItems.functionId}
                                        isfav={childrenItems.isFav}
                                        mid={modulecategory.moduleId}
                                        keys={`all${childrenItems.functionId}`}
                                        userId={userInfo.user_id}
                                        saveFav={saveFav}
                                        isFvLoading={isFvLoading}
                                        index={index}
                                        subindex={modindex}
                                        childerIndex={childerIndex}
                                        tabId="all"
                                        toggleinFav={(e) =>
                                          toggleinFav(
                                            e,
                                            saveFav,
                                            index,
                                            modindex,
                                            childerIndex,
                                            childrenItems.isFav,
                                            modulecategory.moduleId,
                                            childrenItems.functionId,
                                            userInfo.user_id
                                          )
                                        }
                                      />
                                    </NavLink>
                                  </li>
                                );
                              }
                            )}
                        </ul>
                      </MiniSidebarWrapper>
                    </Collapse>
                  </div>
                );
              })
            );
          })}
        </div>
      </SimpleBarWrapper>
    );
  }
);
