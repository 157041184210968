const _getServiceRequestById = (Id) => `sr/api/getServiceRequestById/${Id}`;
const _updateServiceRequestById = (Id) =>
  `sr/api/updateServiceRequestById/${Id}`;
const _getSRrelations = (modulename, serviceRequestId, serviceRequestNumber) =>
  `sr/api/srRelation/getSRRelations?module=${modulename}&serviceRequestId=${serviceRequestId}&serviceRequestNumber=${serviceRequestNumber}`;
const _getSrAttributes = (Id) => `/sr/api/attribute/getSRAttributes?srId=${Id}`;

module.exports = {
  getAllServiceRequest: "sr/api/getAllServiceRequest",
  saveServiceRequest: "sr/api/saveServiceRequest",
  downloadImage: "sr/api/attachment/downloadImage",
  getServiceRequest_ById: _getServiceRequestById,
  _updateServiceRequestById,
  getAudit: "sr/api/getAudit",
  getAuditlogs: "sr/api/getAudit",
  getworknotes: "sr/api/worknotes/getWorknotes",
  getAttributes: "sr/api/attribute/getAttributes",
  getSRRelations: _getSRrelations,
  getSrAttributes: _getSrAttributes,
  underFullFillment:'sr/api/dashboard/items-by-status?status=1,2',
  requestedMeanTimeToResolved:'sr/api/dashboard/mean-time',
  requestType:'sr/api/dashboard/item-count-by-name',
  ItemTrend:'sr/api/dashboard/item-trends',
  Overdueitems:'sr/api/dashboard/overdue-items',
  underFullFillmentList:'sr/api/dashboard/item-list-by-status?status=1,2,3',
  requestedMeanTimeToResolvedList:'sr/api/dashboard/mean-time-item-list',
  requestTypeList:'sr/api/dashboard/item-list-by-name',
  ItemTrendList:'sr/api/dashboard/item-trends-list',
  OverdueitemsList:'sr/api/dashboard/overdue-item-list',
  ItemsAllStatus:"sr/api/dashboard/all-item-count-by-status",
  ItemsAllStatusList:"sr/api/dashboard/all-item-list-by-status",
  getDueOnTaskTickets:"sr/api/getDueOnTaskTickets",
  getDueOnTaskTicketDetails:"sr/api/getDueOnTaskTicketDetails",
  getDueOnTaskTicketDetailsAndPriority:"sr/api/getDueOnTaskTicketDetailsAndPriority",
  getDueOnTaskTicketDetailsAndPriorityList:"sr/api/getDueOnTaskTicketDetailsAndPriorityList"
};
