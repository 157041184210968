import { useState } from 'react';
import FnModalStatic from '../Custom/FnModalStatic';
import { useRecoilValue } from 'recoil';
import { preferencesData } from '../../config/selector';

export default function Footer(params) {
  const [modal, setModal] = useState(false);
  const handleClose = () => setModal(false);
  const handleShow = () => setModal(true);
  const footerValue = useRecoilValue(preferencesData);

  const year = new Date().getFullYear();
  return (
    <div className="footer" style={{ textAlign: 'center' }}>
      <div className="pull-right"> {footerValue.footerMsg} </div>
      <div>
        <span className="pull-left">&copy; {year}, HCL</span>
        <a
          onClick={() => {
            handleShow();
          }}
          className="terms-service"
        >
          Terms of Use
        </a>
      </div>
      <FnModalStatic
        modal={modal}
        handleClose={handleClose}
        modalTitle="Terms of Use"
      />
    </div>
  );
}
