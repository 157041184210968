import { createSlice } from "@reduxjs/toolkit";
import { Get, Post, Put, Delete } from "../../utils/AxiosUtils";
import {
  taskoutput,
  savetaskoutput,
  updatevariable,
  input_variable,
  delete_Variable,
} from "../../utils/IncidentEndpoints";

export const initialState = {
  loading: false,
  hasErrors: false,
  variables: [],
  savevarloading: false,
  editloading: false,
  inputVariables: [],
  inputvarloading: false,
  deletevarloading: false,
};

// A slice for tasks with our three reducers
const variablesSlice = createSlice({
  name: "variables",
  initialState,
  reducers: {
    getVariables: (state) => {
      state.savevarloading = true;
    },
    getVariablesSuccess: (state, { payload }) => {
      state.variables = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    getVariablesFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    getInputVariables: (state) => {
      state.inputvarloading = true;
    },
    getInputVariablesSuccess: (state, { payload }) => {
      state.inputVariables = payload;
      state.inputvarloading = false;
      state.hasErrors = false;
    },
    addVariables: (state, action) => {
      state.savenotsLoading = true;
    },
    editVariables: (state, action) => {
      state.editloading = true;
    },
    editVariablesSuccess: (state, action) => {
      state.editloading = false;
      state.hasErrors = false;
    },
    saveVariablesSuccess: (state, { payload }) => {
      state.savevarloading = false;
      state.hasErrors = false;
    },
    deleteVariableSuccess: (state, { payload }) => {
      state.deletevarloading = false;
      state.hasErrors = false;
    },
  },
});
// Three actions generated from the slice
export const {
  getVariables,
  getVariablesSuccess,
  getVariablesFailure,
  addVariables,
  saveVariablesSuccess,
  editVariables,
  editVariablesSuccess,
  getInputVariables,
  getInputVariablesSuccess,
  deleteVariableSuccess,
} = variablesSlice.actions;

// A selector
export const variablesSelector = (state) => state.variables;

// The reducer
export default variablesSlice.reducer;

// Asynchronous thunk action
export function fetchInputVariables(PostData, compId, orderby) {
  return async (dispatch) => {
    dispatch(getInputVariables());
    try {
      const response = await Post(
        `${input_variable}?filter[order]=createdDate ${orderby}`,
        PostData,
        compId
      );
      dispatch(getInputVariablesSuccess(response["data"]));
    } catch (error) {
      dispatch(getVariablesFailure());
    }
  };
}
export function fetchVariables(id, orderby) {
  return async (dispatch) => {
    dispatch(getVariables());
    try {
      const response = await Get(`${taskoutput}?filter[where][taskCode]=${id}`);
      dispatch(getVariablesSuccess(response["data"]));
    } catch (error) {
      dispatch(getVariablesFailure());
    }
  };
}
// Asynchronous thunk action
export function saveVariables(postData, hideform, setSubmitting) {
  return async (dispatch) => {
    dispatch(addVariables());
    try {
      await Post(`${savetaskoutput}`, postData).then((response) => {
        dispatch(saveVariablesSuccess(response["data"]));
        hideform();
        setSubmitting();
        dispatch(fetchVariables(response["data"].taskCode));
      });
    } catch (error) {
      dispatch(getVariablesFailure());
    }
  };
}
export function updateVariables(id, postData, hideform, setSubmitting) {
  return async (dispatch) => {
    dispatch(editVariables());
    try {
      await Put(updatevariable(id), postData).then((response) => {
        dispatch(editVariablesSuccess(response["data"]));
        hideform();
        setSubmitting();
        dispatch(fetchVariables(response["data"].taskCode));
      });
    } catch (error) {
      dispatch(getVariablesFailure());
    }
  };
}
export function _delteVariable(id, taskcode, compId, hideform) {
  return async (dispatch) => {
    try {
      await Delete(delete_Variable(id), compId).then((res) => {
        dispatch(deleteVariableSuccess());
        hideform();
        dispatch(fetchVariables(taskcode));
      });
    } catch {
      dispatch(getVariablesFailure());
    }
  };
}
