/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useSetRecoilState, useRecoilState } from "recoil";
import { Tooltip } from "antd";
import Helpicon from "../../assets/images/help-icon.png";
import {
  showHideSettings,
  unifoThemes,
  preferencesData,
  activeState,
  fncDetails,
  userThemeData,
} from "../../config/selector";
import { useHistory, Link } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarBrand,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
} from "reactstrap";

import { useQuery } from "react-query";

import { getPreferences } from "../../utils/EndPoints";

import { User } from "react-feather";
import { useAuth } from "../../contexts/AuthContext";
import { _Logout } from "../../utils/EndPoints";
import { Get } from "../../utils/AxiosUtils";
import noimg from "../../assets/img/avatars/pro.jpg";

export default function _Navbar(props) {
  const setSettings = useSetRecoilState(showHideSettings);
  const setActState = useSetRecoilState(activeState);
  const [, setQueryParams] = useRecoilState(fncDetails);
  const [, setThemeValue] = useRecoilState(unifoThemes);
  const [preValues, setPrefValues] = useRecoilState(preferencesData);
  const [, setUsrValues] = useRecoilState(userThemeData);
  const { signOut, sidebarData } = useAuth();
  const history = useHistory();
  const userInfo = sidebarData.data;
  const dynCompanyId = sidebarData.data.companyId;

  async function handleLogout() {
    Get(_Logout(userInfo?.user_id));
    await signOut();
    window.localStorage.clear();    
    history.push("/login");
  }
  //GET PREFERENCES
  const twentyFourHoursInMs = 1000 * 60 * 60 * 24;

  const appliedDynamicStyle = (customSetting) => {
    const appDocument = document.querySelector(":root");
    Object.keys(customSetting).length &&
      Object.keys(customSetting).map((key) => {
        const variableName = `--${key}`;
        appDocument.style.setProperty(variableName, customSetting[key]);
      });
  };

  const setThemeVariales = (data) => {
    const jsonData = JSON.parse(data && data.replaceAll("[\n]", "")) || {};
    appliedDynamicStyle(jsonData);
  };

  useQuery(
    "getPreferencesQ",
    () => {
      return Get(getPreferences, dynCompanyId);
    },
    {
      onSuccess: (res) => {
       // console.log("==>",res)
        setThemeVariales(res.data["userPreferences "].themeJson);
        setThemeValue(res.data["userPreferences "].userTheme);
        setPrefValues(res.data["userPreferences "]);
        setUsrValues(
          JSON.parse(
            res.data["userPreferences "].themeJson.replaceAll("[\n]", "")
          )
        );
      },
      retry: false,
      refetchOnWindowFocus: false,
      // staleTime: twentyFourHoursInMs,
    }
  );
  const globalSearchHandler = () => {
    setQueryParams({ name: "Search", id: "" });
    history.push("/global-search");
  };
  return (
    <div>
      <Navbar light expand={"lg"} className="custom-navbar">
        {/* <Container fluid={true}> */}
        <NavbarBrand href="/" className="me-auto desktop-logo">
          {userInfo.companyLogo != null && (
            <img src={userInfo.companyLogo} height="33" alt="img" />
          )}
          <span
            className="welcomeText wel-txt"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            {userInfo.welcomeMessage}
          </span>
        </NavbarBrand>

        <Collapse navbar className="mobile-collapse">
          <Nav className="ms-auto ms-setting" navbar>
            <div className="setting ">
              <div
                className="right-side-bar setting-desktop"
                style={{ paddingTop: "16px", paddingRight: "10px" }}
              >
                <Tooltip
                  title={"Global Search"}
                  className="d-inline"
                  placement="left"
                >
                  <a onClick={globalSearchHandler}>
                    <i
                      className="fa fa-search"
                      aria-hidden="true"
                      style={{ fontSize: "18px" }}
                    ></i>
                  </a>
                </Tooltip>
              </div>
            </div>
            <UncontrolledDropdown
              nav
              inNavbar
              className="dropdown-toggle user-info"
            >
              <DropdownToggle nav tag="a">
                <span className="avatar avatar-sm">
                  {userInfo.userLogo == null ? (
                    <User />
                  ) : (
                    <img
                      alt="img"
                      className="img-circle"
                      src={
                        userInfo.userLogo !== "" && userInfo.userLogo !== null
                          ? userInfo.userLogo
                          : noimg
                      }
                    />
                  )}
                </span>
                <span className="avatar-last-login">
                  Welcome,
                  {/* {preValues.usrWelcomeMsg} */}
                  <span className="avatar-name">
                    {userInfo.fullName}
                    {/* Last logged On: {userInfo.loginTime} */}
                  </span>
                </span>
              </DropdownToggle>

              <DropdownMenu>
                <DropdownItem
                  tag="a"
                  onClick={() => history.push("/profile/my-profile")}
                >
                  <i className="fa fa-id-badge" />
                  My Profile
                </DropdownItem>
                <DropdownItem tag="a" onClick={handleLogout}>
                  <i className="fa fa-power-off" />
                  Log out
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
          <div className="setting ">
            <div className="right-side-bar setting-desktop">
              <a
                className="setting-icon"
                onClick={(e) => {
                  e.preventDefault();
                  setSettings(true);
                  setActState(false);
                }}
              >
                <Tooltip
                  title={"Settings"}
                  className="d-inline"
                  placement="left"
                >
                  <i className="fa fa-cog" aria-hidden="true"></i>
                </Tooltip>
              </a>
            </div>
          </div>
          <div>
            <Tooltip title={"Help"} className="d-inline" placement="bottom">
              {" "}
              <Link
                to={{ pathname: "http://docs-dryice-connect.com" }}
                target="_blank"
                className="helpiconhome"
              >
                {/* {" "}
                <img src={Helpicon} className="helpiconhome" alt="Help"/> */}
                <span className="fa-stack fa-2x">
                  <i className="fa fa-question-circle"></i>
                </span>
              </Link>
            </Tooltip>
          </div>
        </Collapse>
        {/* </Container> */}
      </Navbar>
    </div>
  );
}
