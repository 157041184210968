import React, { useState } from "react";
import { Spin } from "antd";
import Skeleton from "@material-ui/lab/Skeleton";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import { Select as MSelect } from "@material-ui/core";
//import "./loader.scss";
function FnLoader() {
  return (
    <div
      id="mainLoader"
      style={{
        position: "fixed",
        zIndex: "999",
        overflow: "show",
        margin: "auto",
        height: "3rem",
        top: "-20px",
        left: 0,
        bottom: 0,
        right: 0,
      }}
    >
      <div className="d-flex justify-content-center">
        <div style={{ width: "2.5rem", height: "2.5rem" }}>
          {/* <Loader type="ThreeDots" color="#2e75b6" height={20} /> */}
          <div className="sk-spinner sk-spinner-cube-grid">
            <div className="sk-cube"></div>
            <div className="sk-cube"></div>
            <div className="sk-cube"></div>
            <div className="sk-cube"></div>
            <div className="sk-cube"></div>
            <div className="sk-cube"></div>
            <div className="sk-cube"></div>
            <div className="sk-cube"></div>
            <div className="sk-cube"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default FnLoader;

export const SkeletonLoader = () => {
  return (
    <>
      <Skeleton animation={"wave"} />
      <Skeleton animation={"wave"} />
      <Skeleton animation="wave" />
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export const BlockUILoader = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Backdrop
      className={classes.backdrop}
      open={true}
      onClick={handleClose}
      invisible={props.module === "sidebar" ? true : false}
    >
      <div className="sk-spinner sk-spinner-cube-grid">
        <div className="sk-cube"></div>
        <div className="sk-cube"></div>
        <div className="sk-cube"></div>
        <div className="sk-cube"></div>
        <div className="sk-cube"></div>
        <div className="sk-cube"></div>
        <div className="sk-cube"></div>
        <div className="sk-cube"></div>
        <div className="sk-cube"></div>
      </div>
    </Backdrop>
  );
};
const ArrowIcon = () => {
  return (
    <span className="ant-select-arrow">
      <span
        role="img"
        aria-label="down"
        className="anticon anticon-down ant-select-suffix downarrow"
      >
        <svg
          viewBox="64 64 896 896"
          focusable="false"
          data-icon="down"
          width="1em"
          height="1em"
          fill="currentColor"
          aria-hidden="true"
        >
          <path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path>
        </svg>
      </span>
    </span>
  );
};
export function SelectLoading() {
  return (
    <div className="form-group form__group">
      <FormControl style={{ width: "100%" }}>
        <MSelect
          style={{ marginTop: 0, maxWidth: "100%" }}
          name="Loading"
          native
          value={"loading...."}
          variant="outlined"
          fullWidth
          IconComponent={ArrowIcon}
        >
          <option value={"Loading...."}>Loading....</option>
        </MSelect>
      </FormControl>
    </div>
  );
}

export const BusyLoader = ({ children, ...rest }) => (
  <Spin
    {...rest}
    indicator={
      <div className="sk-spinner sk-spinner-cube-grid">
        <div className="sk-cube"></div>
        <div className="sk-cube"></div>
        <div className="sk-cube"></div>
        <div className="sk-cube"></div>
        <div className="sk-cube"></div>
        <div className="sk-cube"></div>
        <div className="sk-cube"></div>
        <div className="sk-cube"></div>
        <div className="sk-cube"></div>
      </div>
    }
  >
    {children}
  </Spin>
);
