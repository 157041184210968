import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import calendarService from "components/GovernanceMeeting/console/meeting-calendar/http-services";

const initialState = [];

export const getAllEventsByDate = createAsyncThunk(
  "events/bydate",
  async (params) => {
    const res = await calendarService.getAll(params);
    return res.data;
  }
);

export const resetAllEventsByDate = createAsyncThunk(
  "events/resetbydate",
  async () => {
    return [];
  }
);

const eventsbyDateSlice = createSlice({
  name: "eventsbyDate",
  initialState,
  extraReducers: {
    [resetAllEventsByDate.fulfilled]: (state, action) => {
      return [...action.payload];
    },
    [getAllEventsByDate.fulfilled]: (state, action) => {
      return [...action.payload];
    },
  },
});

const { reducer } = eventsbyDateSlice;
export default reducer;
