import {
  getAllForums,
  saveForum,
  getForumById,
  updateForum,
} from "utils/EndPoints";
import { filterURL } from "components/GovernanceMeeting/Helper";
import { Get, Post, Put } from "utils/AxiosUtils";

const getAll = (params) => {
  const url = filterURL(getAllForums, params?.params);
  return Get(url, params?.params?.tenantId);
};

const create = (params) => {
  return Post(
    `${saveForum}?tenantId=${params?.params?.customerId}`,
    params?.params,
    params?.params?.customerId
  );
};

const getById = (params) => {
  return Get(
    `${getForumById}?tenantId=${params.companyId}&forumId=${params.forumId}`
  );
};

const update = (params) => {
  return Put(
    `${updateForum}?tenantId=${params?.params?.customerId}`,
    params?.params,
    params?.params?.customerId
  );
};

const ForumService = {
  getAll,
  create,
  getById,
  update,
};

export { getAll, create, getById };

export default ForumService;
