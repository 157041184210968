import { createSlice } from "@reduxjs/toolkit";
import { Get } from "../../utils/AxiosUtils";
import { getServiceRequest_ById } from "../../utils/srEndpoint";

export const initialState = {
  loading: false,
  hasErrors: false,
  requests: [],
};

const requestSlice = createSlice({
  name: "request",
  initialState,
  reducers: {
    getRequests: (state) => {
      state.loading = true;
    },
    getRequestsSuccess: (state, { payload }) => {
      state.requests = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    getRequestsFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
  },
});
export const { getRequests, getRequestsSuccess, getRequestsFailure } =
  requestSlice.actions;

// A selector
export const requestsSelector = (state) => state.requests;

// The reducer
export default requestSlice.reducer;

// Asynchronous thunk action
export function fetchRequestById(id, compId) {
  return async (dispatch) => {
    dispatch(getRequests());
    try {
      await Get(getServiceRequest_ById(id), compId).then((response) => {
        dispatch(getRequestsSuccess(response["data"].data));
      });
    } catch (error) {
      dispatch(getRequestsFailure());
    }
  };
}
