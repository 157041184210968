import React from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Provider } from "react-redux";
import { AuthProvider } from "../src/contexts/AuthContext";
import Routes from "./routes/Routes";
import { QueryClientProvider } from "react-query";
import { queryClient } from "../src/config/ReactQuery";
import store from "./store/store";
import "../src/assets/css/theme.scss";
import { useRecoilValue } from "recoil";
import { unifoThemes } from "./config/selector";

const theme = createTheme({
  typography: {
    fontFamily: ["Roboto Condensed", "sans-serif"].join(","),
    fontSize: 13,
  },
});
function App() {
  let themeName = "";
  // const appliedTheme = localStorage.getItem("appliedTheme");
  const appliedTheme = reactLocalStorage.get("appliedTheme", true);

  const selectedTheme = useRecoilValue(unifoThemes);

  !appliedTheme ? (themeName = selectedTheme) : (themeName = appliedTheme);

  if (window.performance) {
    if (performance.navigation.type === 1) {
      window.location.href = "/";
    } else {
    }
  }

  return (
    // <div className="unifo grey-theme">
    <div className={`unifo ${themeName === "blue-white" ? "" : themeName}`}>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <ThemeProvider theme={theme}>
              <Routes />
            </ThemeProvider>
          </AuthProvider>
        </QueryClientProvider>
      </Provider>
    </div>
  );
}

export default App;
