import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { makeStyles } from '@material-ui/core/styles';
import { Select, DatePicker } from 'antd';
import Loader from 'react-loader-spinner';
import FormControl from '@material-ui/core/FormControl';

import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import { Field, useField, ErrorMessage } from 'formik';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputLabel from '@material-ui/core/InputLabel';
import { FloatLabelStd } from '../FloatLabel/index';
import { InputNumber } from 'antd';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 0,
      padding: '3.5px !important',
    },
  },
  _label: {
    fontSize: '13px !important',
    // transform: "translate(14px, 8px) scale(1) !important",
  },
  outlinedhover: {
    '&:hover': {
      borderColor: '#3f51b5 !important',
    },
    notchedOutlineN: {
      borderColor: '#3f51b5 !important',
    },
  },
  focused: {
    // color: "rgb(33 150 243) !important",
    borderColor: 'rgb(33 150 243) !important',
  },

  pad: { paddingTop: '.375rem' },
  labelAsterisk: {
    color: '#f44336',
  },
  Muierror: {
    marginLeft: 0,
    color: '#f44336 !important',
    position: 'absolute',
    top: -20,
    right: 0,
    marginRight: 0,
    width: 'auto',
    fontSize: '0.6964285714285714rem',
  },
  MuierrorForDate: {
    marginLeft: 0,
    color: '#f44336 !important',
    float: 'right',
    top: -20,
    right: 0,
    marginRight: 0,
    width: 'auto',
    fontSize: '0.6964285714285714rem !important',
  },
  MuierrorStd: {
    marginLeft: 0,
    color: '#f44336 !important',
    position: 'absolute',
    bottom: -20,
    right: 0,
    marginRight: 0,
    width: 'auto',
    fontSize: '0.6964285714285714rem',
  },
  required: {
    marginLeft: 0,
  },
  lblshrink: {
    background: '#ffffff',
    transform: 'translate(14px, -2px) scale(0.75) !important',
  },
  lblshrinkStd: {
    background: '#ffffff',
  },
  focusedlbl: {
    background: 'transparent',
  },
  // formControl: {
  //   margin: theme.spacing(1),
  //   minWidth: 120,
  // },
}));

export const InputTextFieldForChips = ({ label, variant, ...props }) => {
  const classes = useStyles();

  return (
    <>
      <div className="form-group form__group">
        <Field
          {...props}
          as={TextField}
          label={label}
          FormHelperTextProps={{
            classes: {
              error: classes.MuierrorStd,
              required: classes.required,
            },
          }}
          InputLabelProps={{
            classes: {
              root: classes._label,
              asterisk: classes.labelAsterisk,
              shrink: classes.lblshrinkStd,
              focused: classes.focusedlbl,
              disabled: classes.focusedlbl,
            },
          }}
        />
      </div>
    </>
  );
};
export const InputTextField = ({
  label,
  endAdornment,
  EndAdornmentIcon,
  variant,
  ...props
}) => {
  const classes = useStyles();
  const [field, meta] = useField(props);
  const showError = meta.touched && !!meta.error;
  return (
    <>
      <div className="form-group form__group">
        <Field
          {...field}
          {...props}
          as={TextField}
          label={label}
          error={showError}
          helperText={showError && meta.error}
          FormHelperTextProps={{
            classes: {
              error: classes.MuierrorStd,
              required: classes.required,
            },
          }}
          InputLabelProps={{
            classes: {
              root: classes._label,
              asterisk: classes.labelAsterisk,
              shrink: classes.lblshrinkStd,
              focused: classes.focusedlbl,
              disabled: classes.focusedlbl,
            },
          }}
        />
      </div>
    </>
  );
};

export const InputTextFieldNumber = ({
  label,
  min,
  max,
  endAdornment,
  prefixClassName,
  required,
  prefix,
  variant,
  ...props
}) => {
  const classes = useStyles();
  const [field, meta] = useField(props);
  const showError = meta.touched && !!meta.error;

  const component = (props) => (
    <div
      className={`form-group form___group ${prefixClassName} `}
      style={{ paddingTop: prefixClassName ? '' : '10px' }}
    >
      <FloatLabelStd
        {...field}
        {...props}
        disabled={props.disabled}
        className={props?.className}
      >
        {showError && (
          <ErrorMessage {...field} component="span" className="sel-error-std" />
        )}
        <>
          <InputNumber
            {...props}
            bordered={false}
            className="inputNumber"
            style={{
              width: '100%',
            }}
            min={min}
            max={max}
          />
        </>
      </FloatLabelStd>
      {/* <InputNumber
        {...props}
        bordered={false}
        className="inputNumber"
        style={{
          width: "100%",
        }}
        min={min}
        max={max}
      />

      <label className="form__label" style={{ fontSize: "11px" }}>
        {label} {required && <span className="mandatory">&nbsp;*</span>}
      </label>
      <ErrorMessage
        {...field}
        component="span"
        className={classes.MuierrorForDate}
      /> */}
    </div>
  );
  return (
    <>
      <div className="form-group form__group">
        <Field
          {...field}
          {...props}
          as={component}
          label={label}
          prefix={prefix}
          error={showError}
          helperText={showError && meta.error}
          FormHelperTextProps={{
            classes: {
              error: classes.MuierrorStd,
              required: classes.required,
            },
          }}
          InputLabelProps={{
            classes: {
              root: classes._label,
              asterisk: classes.labelAsterisk,
              shrink: classes.lblshrinkStd,
              focused: classes.focusedlbl,
              disabled: classes.focusedlbl,
            },
          }}
        />
      </div>
    </>
  );
};

export function SelectFiled({
  label,
  required,
  mandatory,
  children,
  ...props
}) {
  const classes = useStyles();
  const [field, meta] = useField(props);
  const errClass =
    meta.touched && meta.error
      ? 'is-invalid'
      : meta.value === ''
      ? ' '
      : 'valid';
  return (
    <>
      <div className="form-group form__group">
        <ErrorMessage
          {...field}
          component="span"
          className={classes.Muierror}
        />
        <div className="select">
          <Field
            className={`select-text form-control ${errClass}`}
            {...field}
            {...props}
            as="select"
          >
            <option value=""></option>
            {children}
          </Field>
          <label
            className={
              meta.touched && meta.error
                ? 'select-label is-invalid'
                : 'select-label'
            }
          >
            {label} {required && <span>*</span>}
          </label>
        </div>
      </div>
    </>
  );
}
export function SearchList({ children, ...props }) {
  const [field, meta, className] = useField(props);
  const showError = meta.touched && !!meta.error;

  return (
    <FloatLabelStd
      {...field}
      {...props}
      disabled={props.disabled}
      className={className !== undefined ? className : ''}
      // value={props.value === "" ? "--Select--" : props.value}
    >
      {showError && (
        <ErrorMessage {...field} component="span" className="sel-error-std" />
      )}
      <>
        <Select
          defaultValue="a1"
          {...field}
          {...props}
          style={{ width: '100%' }}
          dropdownClassName="antdd"
          className={'customdd-std'}
          showSearch
          allowClear={props.value !== '' && props.value !== '--Select--'}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          // value={props.value === "" ? "--select--" : props.value}
        >
          {children}
        </Select>
      </>
    </FloatLabelStd>
  );
}

export function SearchListStd({ children, ...props }) {
  const [field, meta] = useField(props);
  const showError = meta.touched && !!meta.error;

  return (
    <FloatLabelStd
      {...field}
      {...props}
      disabled={props.disabled}
      value={
        props.value === '' || props.value === undefined || props.value === null
          ? '--Select--'
          : props.value
      }
    >
      {showError && (
        <ErrorMessage {...field} component="span" className="sel-error-std" />
      )}
      <>
        <Select
          {...field}
          {...props}
          style={{ width: '100%' }}
          dropdownClassName="antdd"
          className={`customdd-std ${props?.dropclass}`}
          // showSearch={props.showSearch}
          allowClear={
            props.value !== '' &&
            props.value !== '--Select--' &&
            props.value !== undefined &&
            props.value !== null
          }
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          value={
            props?.value === '' ||
            props.value === undefined ||
            props.value === null
              ? '--Select--'
              : props.value
          }
          getPopupContainer={() => document.querySelector('#content')}
          defaultValue={
            props.defaultValue !== undefined ? props.defaultValue : '--Select--'
          }
        >
          {children}
        </Select>
      </>
    </FloatLabelStd>
  );
}

export const Arrowdown = () => {
  return (
    <span className="ant-select-arrow">
      <span
        role="img"
        aria-label="down"
        className="anticon anticon-down ant-select-suffix downarrow"
      >
        <svg
          viewBox="64 64 896 896"
          focusable="false"
          data-icon="down"
          width="1em"
          height="1em"
          fill="currentColor"
          aria-hidden="true"
        >
          <path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path>
        </svg>
      </span>
    </span>
  );
};
export function SelectLoader() {
  return (
    <div className="form-group form__group">
      <FormControl style={{ width: '100%' }}>
        <Loader type="ThreeDots" color="#2e75b6" height={10} />
      </FormControl>
    </div>
  );
}
export const AutoSearchF = ({ options, ...props }) => {
  const { Option } = Select;
  return (
    <Field
      {...props}
      as={SearchList}
      getPopupContainer={(trigger) => trigger.parentNode}
    >
      {options.map((dd, i) => {
        return (
          <Option key={i} value={dd.value}>
            {dd.label}
          </Option>
        );
      })}
    </Field>
  );
};

export const DatePickerF = ({ ...props }) => {
  const [field, meta] = useField(props);
  const showError = meta.touched && !!meta.error;
  const classes = useStyles();
  return (
    // <div className="form-group form__group">
    <FloatLabelStd {...field} {...props} disabled={props.disabled}>
      <>
        <Field {...props} as={DatePicker}></Field>
      </>
      {showError && (
        <ErrorMessage
          {...field}
          component="span"
          className={classes.MuierrorForDate}
        />
      )}
    </FloatLabelStd>
    // </div>
  );
};

export const StandardSelect11 = ({
  label,
  children,
  variant,
  disabled,
  ...props
}) => {
  const classes = useStyles();
  const [field, meta] = useField(props);
  const [shrnk, setShrnk] = useState(false);
  const showError = meta.touched && !!meta.error;
  //console.log("field.name==>", field.name, field.value);
  return (
    <div className="form-group form__group">
      <FormControl
        className={classes.formControl}
        error={showError}
        disabled={disabled}
      >
        <ErrorMessage
          {...field}
          component="span"
          className={classes.Muierror}
        />
        <InputLabel
          shrink={field.value || field.value === 0 || shrnk ? true : false}
          value={field.value}
          {...props}
          {...field}
          error={showError}
          classes={{
            root: classes._label,
            asterisk: classes.labelAsterisk,
            shrink: variant ? classes.lblshrinkStd : classes.lblshrink,
            focused: classes.focusedlbl,
          }}
        >
          {label}
        </InputLabel>
        <Field
          {...field}
          {...props}
          select
          as={TextField}
          SelectProps={{
            native: true,
          }}
          inputProps={{
            size: 'small',
            name: field.name,
          }}
          value={field.value}
          IconComponent={Arrowdown}
          className="outlinedhover"
          onFocus={() => setShrnk(true)}
          onBlur={() => setShrnk(false)}
        >
          {children}
        </Field>
      </FormControl>
    </div>
  );
};

export const StandardSelect = ({ mrbtm, padbtm, children, ...props }) => {
  return (
    <div
      className="form-group form__group mt-20"
      style={{ marginBottom: mrbtm, paddingBottom: padbtm }}
    >
      <Field {...props} as={SearchListStd}>
        {children}
      </Field>
      {/* <FloatLabelStd
      {...field}
      {...props}
      disabled={props.disabled}
      value={props.value === "" ? "--Select--" : props.value}
    >
      {showError && (
        <ErrorMessage {...field} component="span" className="sel-error-std" />
      )}
      <>
        <Select
          {...field}
          {...props}
          style={{ width: "100%" }}
          dropdownClassName="antdd"
          className={"customdd-std"}          
          allowClear={
            props.value !== "" &&
            props.value !== "--Select--" &&
            props.value !== null
          }
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          value={props.value === "" ? "--Select--" : props.value}
          getPopupContainer={() => document.querySelector("#print-form")}
          defaultValue={props.defaultValue}
          
        >
          {children}
        </Select>
      </>
    </FloatLabelStd> */}
    </div>
  );
};

export const StandardSelect_old = ({
  label,
  children,
  variant,
  disabled,
  ...props
}) => {
  const classes = useStyles();
  const [field, meta] = useField(props);
  const [shrnk, setShrnk] = useState(false);
  const showError = meta.touched && !!meta.error;

  return (
    <div className="form-group form__group">
      <FormControl
        className={classes.formControl}
        error={showError}
        disabled={disabled}
      >
        <InputLabel
          shrink
          value={field.value}
          {...props}
          {...field}
          error={showError}
          classes={{
            root: classes._label,
            asterisk: classes.labelAsterisk,
            focused: classes.focusedlbl,
          }}
        >
          {label}
        </InputLabel>

        <Field
          {...field}
          {...props}
          as={NativeSelect}
          label={label}
          inputProps={{
            size: 'small',
            name: field.name,
          }}
          error={showError}
          value={field.value || ''}
          IconComponent={Arrowdown}
          className="outlinedhover"
          onFocus={() => setShrnk(true)}
          onBlur={() => setShrnk(false)}
          getPopupContainer={() => document.querySelector('#print-form')}
        >
          <option value="">--Select--</option>
          {children}
        </Field>

        <ErrorMessage
          {...field}
          component="span"
          className={classes.MuierrorStd}
        />
      </FormControl>
    </div>
  );
};

export function StandardTextAreaAuto(props) {
  const [field, meta] = useField(props);
  const showError = meta.touched && !!meta.error;
  const classes = useStyles();

  return (
    <FloatLabelStd {...field} {...props} disabled={props?.disabled}>
      <>
        <Field
          {...props}
          as={TextareaAutosize}
          className="text-area-outlined"
        ></Field>
      </>
      {showError && (
        <ErrorMessage
          {...field}
          component="span"
          className={classes.MuierrorForDate}
        />
      )}
    </FloatLabelStd>
  );
  // return (

  //   // <FloatLabelStd {...field} {...props} disabled={props.disabled}>
  //   //   {showError && (
  //   //     <ErrorMessage {...field} component="span" className="sel-error-std" />
  //   //   )}
  //   //   <>
  //   //     <TextareaAutosize {...field} {...props}>
  //   //       {children}
  //   //     </TextareaAutosize>
  //   //   </>
  //   // </FloatLabelStd>
  // );
}

export function StandardTextArea({
  children,
  mrtop,
  mrbtm,
  characterCount,
  characterLimit,
  ...props
}) {
  /*NOTE: Character Limit on description field  require two props 1.characterCount={true} 2. characterLimit */
  const [field, meta, helpers] = useField(props);
  const showError = meta.touched && !!meta.error;
  const classes = useStyles();
  const [cstate, setCstate] = useState(false);
  const characterLength = characterLimit ? characterLimit : 500;
  const { value } = meta;
  const { setValue } = helpers;
  const handleFocus = () => {
    if (value?.length >= 0) {
      setCstate(true);
    }
  };
  const handleBlur = () => {
    if (value?.length === 0) {
      setCstate(false);
    }
  };
  useEffect(() => {
    if (value?.length > 0) {
      setCstate(true);
    }
  }, [value]);

  return (
    <div
      className="mt-20 position-relative"
      style={{ marginTop: mrtop, marginBottom: mrbtm }}
    >
      {cstate && characterCount && value.length >= 0 && (
        <span className="descCount">
          ({characterLength - value.length} Characters left)
        </span>
      )}
      <TextField
        {...field}
        {...props}
        multiline
        fullWidth
        maxRows={10}
        minRows={1}
        variant="standard"
        error={showError}
        inputProps={{
          maxLength: characterCount ? characterLength : null,
        }}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        onFocus={handleFocus}
        onBlur={handleBlur}
        helperText={showError && meta.error}
        InputLabelProps={{
          classes: {
            root: classes._label,
            asterisk: classes.labelAsterisk,
          },
          shrink: !!value,
        }}
        FormHelperTextProps={{
          classes: {
            error: classes.MuierrorStd,
          },
        }}
      >
        {children}
      </TextField>
    </div>
  );
}

export function StandarInput({ children, ...props }) {
  const [field, meta] = useField(props);
  const showError = meta.touched && !!meta.error;
  const classes = useStyles();
  return (
    <div className="form-group form__group mb-1">
      <Field
        {...field}
        {...props}
        error={showError}
        helperText={showError && meta.error}
        as={TextField}
        autoComplete="off"
        InputLabelProps={{
          classes: {
            root: classes._label,
            asterisk: classes.labelAsterisk,
          },
        }}
        FormHelperTextProps={{
          classes: {
            error: classes.MuierrorStd,
          },
        }}
      >
        {children}
      </Field>
    </div>
  );
}

export function SimpleInput({ children, ...props }) {
  const classes = useStyles();
  return (
    <div className="form-group form__group mb-1">
      <TextField
        {...props}
        autoComplete="off"
        InputLabelProps={{
          classes: {
            root: classes._label,
            asterisk: classes.labelAsterisk,
          },
        }}
      >
        {children}
      </TextField>
    </div>
  );
}

export function NonEditableField({ label, value }) {
  return (
    <div className="edit-task">
      <div className="form-group form___group">
        <label className="form__label">{label}</label>
        <span className="text-break-all">{parse(value) || '--'}</span>
      </div>
    </div>
  );
}
