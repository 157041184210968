import {
  getAllCommitee,
  saveCommitee,
  getCommiteeById,
  catAllCategory,
  committeeStatus,
  updateCommitee,
} from "utils/EndPoints";
import { filterURL } from "components/GovernanceMeeting/Helper";
import { Get, Post, Put } from "utils/AxiosUtils";

const getAll = (params) => {
  const url = filterURL(getAllCommitee, params?.params);

  return Get(url, params?.params?.tenantId);
};

const create = (params) => {
  return Post(
    `${saveCommitee}?tenantId=${params?.params?.customerId}`,
    params?.params,
    params?.params?.customerId
  );
};

const getById = (params) => {
  return Get(
    `${getCommiteeById}?tenantId=${params.companyId}&committeeId=${params.committeeId}`,
    params.companyId
  );
};

const getAllCategroy = (params) => {
  if (params.companyId) {
    return Get(
      `${catAllCategory}?tenantId=${params.companyId}`,
      params.companyId
    );
  }
};

const getCommitteeStatus = (params) => {
  return Get(
    `${committeeStatus}?tenantId=${params.companyId}`,
    params.companyId
  );
};

const update = (params) => {
  return Put(
    `${updateCommitee}?tenantId=${params?.params?.customerId}`,
    params?.params,
    params?.params?.customerId
  );
};

const commiteeService = {
  getAll,
  create,
  getById,
  update,
  getAllCategroy,
  getCommitteeStatus,
};

export { getAll, create, getById, getAllCategroy, getCommitteeStatus };

export default commiteeService;
