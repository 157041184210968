import React, { useState } from "react";
import { InputLabel } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
//import "./index.scss";
const useStyles = makeStyles((theme) => ({
  _label: {
    fontSize: 13,

    // transform: "translate(14px, 8px) scale(1) !important",
  },
  _labelstd: {
    fontSize: 13,
    left: 0,
    // transform: "translate(14px, 8px) scale(1) !important",
  },
  labelAsterisk: {
    color: "#f44336",
  },
  muierror: {
    color: "#f44336",
  },
  lblshrink: {
    background: "#ffffff",
  },
  shrink: {
    left: 0,
    top: "-20px !important",
  },
  focusedlbl: {
    background: "transparent",
  },
}));
const FloatLabel = (props) => {
  const classes = useStyles();
  const [focus, setFocus] = useState(false);
  const { children, label, value, required, showError } = props;
  const error = showError && showError !== undefined;
  const labelClass =
    focus || (value && value.length !== 0) ? "flabel label-float" : "flabel";
  const shrink = focus || (value && value.length !== 0) ? true : false;
 
  return (
    <div
      className="float-label"
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      {children}
      <InputLabel
        className={labelClass}
        shrink={shrink}
        error={error}
        classes={{
          root: classes._label,
          asterisk: classes.labelAsterisk,
          focused: classes.focusedlbl,
          disabled: classes.focusedlbl,
          error: classes.muierror,
        }}
      >
        {label}
        {required !== undefined && required && <span className="mstar">*</span>}
      </InputLabel>
    </div>
  );
};

export const FloatLabelStd = (props) => {
  const classes = useStyles();
  const [focus, setFocus] = useState(false);
  const { children, label, value, required, showError, className } = props;
  const error = showError && showError !== undefined;
  const labelClass =
    focus || (value && value.length !== 0) ? "flabel label-float" : "flabel";
  const shrink = focus || (value && value.length !== 0) ? true : false;
  return (
    <div
      className={`float-label ${className !== undefined ? className : ""}`}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      {children}
      <InputLabel
        className={labelClass}
        shrink={shrink}
        error={error}
        classes={{
          root: classes._labelstd,
          asterisk: classes.labelAsterisk,
          focused: classes.focusedlbl,
          disabled: classes.focusedlbl,
          error: classes.muierror,
          shrink: classes.shrink,
        }}
      >
        {label}
        {required !== undefined && required && <span className="mstar">*</span>}
      </InputLabel>
    </div>
  );
};

export default FloatLabel;
