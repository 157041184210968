export const forumFilters = {
  tenantId: "",
  forumName: "",
  suppliersName: "",
  customerName: "",
  subTrackName: "",
  trackName: "",
  regionName: "",
  sortedBy: "",
  forumId: "",
};

export const committeeFilters = {
  tenantId: "",
  customerName: "",
  forumName: "",
  suppliersName: "",
  subTrackName: "",
  trackName: "",
  regionName: "",
  sortedBy: "",
};

export const eventsFilters = {
  title: "",
  tenantId: "",
  companyName: "",
  statusId: "",
  forumName: "",
  committeeName: "",
  supplierNames: "",
  subTrackName: "",
  trackName: "",
  regionName: "",
  sortedBy: "",
  month: "",
  year: "",
};
