import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { RecoilRoot } from "recoil";
//import HttpsRedirect from "./config/httpToHttps";

import "simplebar/dist/simplebar.min.css";
//import "bootstrap/dist/css/bootstrap.min.css";
import "assets/css/_bootstrap-settings.scss";
//import "antd/dist/antd.css";
// import "antd/dist/antd.min.css";
import "./assets/css/style.css";
import "./assets/css/default.scss";
import "./assets/css/hummingbird-treeview.css";
import "./assets/css/dropify.scss";
import "./assets/css/main.scss";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "./assets/css/media.scss";

ReactDOM.render(
  // <React.StrictMode>
  <RecoilRoot>
    <App />
  </RecoilRoot>,
  // </React.StrictMode>,
  document.getElementById("root")
);
