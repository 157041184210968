import React, { useState, useEffect } from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { SelectF } from "../Custom/Statusdd";
import { SelectLoader } from "../User/AddEditUser/functions";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { Get, Post } from "../../utils/AxiosUtils";
import {
  GetAllZones,
  saveUserSettingPreferences,
  GetDateFormat,
  GetAllThemes,
} from "../../utils/EndPoints";
import {
  unifoThemes,
  showHideSettings,
  preferencesData,
  activeState,
  showHideTheme,
} from "../../config/selector";
import { useRecoilState, useSetRecoilState, useRecoilValue } from "recoil";
import Loader from "react-loader-spinner";
import { useShowHide } from "../Custom/Hooks/useShowHide";
import Blue from "../../assets/images/theme/blue.png";
import Blueandwhite from "../../assets/images/theme/blueandwhite.png";
import Grey from "../../assets/images/theme/greyTheme.png";
import Greyandwhite from "../../assets/images/theme/greyandwhite.png";
import Gold from "../../assets/images/theme/gold.png";
import Goldandwhite from "../../assets/images/theme/goldandwhite.png";
import { useAuth } from "../../contexts/AuthContext";
import { Link } from "react-router-dom";
import { Drawer } from "antd";

const Settings = React.memo(() => {
  const { sidebarData: moduleData } = useAuth();
  const dynCompanyId = moduleData.data.companyId;
  const dynUserId = moduleData.data.user_id;
  const [settings, setSettings] = useRecoilState(showHideSettings);
  const setVisibleTheme = useSetRecoilState(showHideTheme);
  const setActState = useSetRecoilState(activeState);
  const queryClient = useQueryClient();

  const prefData = useRecoilValue(preferencesData);
  // console.log(dynCompanyId);
  const [isDateFormat, showDf, hideDf] = useShowHide(false);
  const [isTimeZone, showTz, hideTz] = useShowHide(false);
  const [isTheme, showTheme, hideTheme] = useShowHide(false);
  const [themeValue, setThemeValue] = useRecoilState(unifoThemes);
  const [themeName, setThemeName] = useState(themeValue);
  const [initVals, setInitVals] = useState({
    dateFormat: prefData && prefData.dateFormat,
    timeZone: prefData && prefData.timeZone,
  });
  const [themeObj, setThemeObj] = useState([]);
  // const [settings,setSettings]=useState(false)
  //console.log("theme",themeObj.sort())
  //console.log("themeValue",themeValue)
  // console.log("themename", themeName, isTheme, themeObj);

  //console.log("initVals===>", initVals);
  // const themeObj = [
  //   { id: 1, text: "Blue", src: Blue, theme: "blue", cname: "" },
  //   {
  //     id: 2,
  //     text: "Blue and White",
  //     src: Blueandwhite,
  //     theme: "blue-white",
  //     cname: "",
  //   },
  //   { id: 3, text: "Grey", src: Grey, theme: "grey", cname: "" },
  //   {
  //     id: 4,
  //     text: "Grey and White",
  //     src: Greyandwhite,
  //     theme: "grey-white",
  //     cname: "",
  //   },
  //   { id: 5, text: "Gold", src: Gold, theme: "gold", cname: "" },
  //   {
  //     id: 6,
  //     text: "Gold and White",
  //     src: Goldandwhite,
  //     theme: "gold-white",
  //     cname: "",
  //   },
  //   {
  //     id: 7,
  //     text: "Custom Theme",
  //     src: "",
  //     theme: "advanced-blue-white",
  //     cname: "advanced-blue-white",
  //   },
  // ];

  //GET ALL THEMES

  const { isLoading: isLoadingTh, data: dataTh } = useQuery(
    "getAllThemesQ",
    () => {
      return Get(GetAllThemes, dynCompanyId);
    },
    {
      onSuccess: (resp) => {
        //console.log(resp?.data?.themes?.map((th)=>th.themeName));
        setThemeObj(resp?.data?.themes?.map((th) => th.themeName));
        //    setAllCompanyList(resp.companyList);
        //    setDefaultCompany(resp.companyList.filter(el=>el.companyId==companyId).map(v=>v.companyId).toString());
        //   let defCompData=resp.companyList.filter(el=>el.companyId==companyId);
        //   let defCompObj={ ...defCompData};
        //  // console.log("default company data",defCompObj['0'].id)
        //    setCompany({
        //      id: defCompObj['0'].companyId,
        //      name: defCompObj['0'].companyName,
        //    });
      },

      retry: false,
      refetchOnWindowFocus: false,
      enabled: settings,
    }
  );

  //GET ALL ZONES
  const { isLoading: isLoadingGZ, data: dataGZ } = useQuery(
    "getZonesQ",
    () => {
      return Get(GetAllZones, dynCompanyId);
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: settings,
    }
  );

  //GET DATE FORMAT
  const { isLoading: isLoadingDF, data: dataDF } = useQuery(
    "getDateFormatQ",
    () => {
      return Get(GetDateFormat, dynCompanyId);
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: settings,
    }
  );

  // console.log(dataDF.data.data);

  const createPreferences = (values) => {
    const postData = {
      userId: dynUserId,
      dateFormat: values.dateFormat,
      timeZone: values.timeZone,
      userTheme: themeName,
    };
    return Post(saveUserSettingPreferences, postData, dynCompanyId);
  };

  const { mutate, isLoading: isLoadingM } = useMutation(createPreferences, {
    onSuccess: () => {
      reactLocalStorage.set("appliedTheme", themeName);
      //localStorage.setItem("appliedTheme", themeName);
      queryClient.invalidateQueries("getPreferencesQ");
      setActState(true);
      setSettings(false);
      // window.location.reload();
    },
    onError: (e) => {},
  });

  const initialValues = {
    ...initVals,
  };
  const schema = Yup.object({
    timeZone: Yup.string().required("Required"),
    dateFormat: Yup.string().required("Required"),
  });
  const onSubmit = (values, { resetForm }) => {
    console.log(values);
    resetForm();
    mutate(values);
    hideDf();
    hideTheme();
    hideTz();
  };

  const applyTheme = (theme) => {
    setThemeName(theme);
  };

  useEffect(() => {
    if (settings) {
      document.querySelector("body").style.overflow = "hidden";
    } else {
      document.querySelector("body").style = null;
    }
  }, [settings]);

  useEffect(() => {
    setInitVals((prevState) => {
      return {
        ...prevState,
        dateFormat: prefData && prefData.dateFormat,
        timeZone: prefData && prefData.timeZone,
      };
    });
  }, [prefData]);

  useEffect(() => {
    setThemeName(themeValue);
  }, [themeValue]);

  return (
    <div className="drawer-settings">
      <Drawer
        // title="System Properties"
        width={"22%"}
        maskClosable={false}
        placement="right"
        closable={false}
        // onClose={onClose}
        visible={settings}
        bodyStyle={{ padding: "0px" }}
        headerStyle={{ padding: "0px" }}
      >
        <nav id="setting-tabs">
          <div className="setting-tabs-slim-scroll">
            <div
              className="setts-close"
              onClick={() => {
                setSettings(false);
                hideTz();
                hideTheme();
                hideDf();
              }}
            >
              {" "}
              <i className="fa fa-times"></i>{" "}
            </div>
            <ul className="nav nav-pills " id="custom-pills-tab" role="tablist">
              <li className="nav-item" role="presentation">
                {" "}
                <a
                  className="nav-link active"
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  href="#pills-home"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                >
                  Settings
                </a>{" "}
              </li>
            </ul>
            <div className="tab-content setts" id="pills-tabContent">
              {/* {isMsg && (
            <div className="custom-message">
              <div className="row">
                <div className="col-12">
                  <p>
                    Change in Date Format and Time Zone will reflect after you
                    login again.
                  </p>
                </div>
                <div className="col-xs-12">
                  <div className="text-center">
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        applyPref();
                      }}
                    >
                      Ok
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}
          {isMsg && <div className="pref-overlay"></div>} */}
              <div className="pd-10">
                <Formik
                  initialValues={initialValues}
                  validationSchema={schema}
                  onSubmit={onSubmit}
                  enableReinitialize={true}
                >
                  {(props) => (
                    <Form noValidate>
                      <div
                        id="user-preferences"
                        className="preferences-content"
                      >
                        <div className="row">
                          <div className="col-sm-12">
                            <div
                              className="form-group position-relative box-col"
                              style={{ paddingBottom: `${isDateFormat}?5:10` }}
                            >
                              {isDateFormat ? (
                                isLoadingDF ? (
                                  <SelectLoader />
                                ) : (
                                  <SelectF
                                    name="dateFormat"
                                    label="Date & Time Format"
                                    style={{ fontSize: "12px" }}
                                  >
                                    {dataDF.data.data &&
                                      dataDF.data.data.map((z) => (
                                        <option key={z.id} value={z.dateFormat}>
                                          {z.dateFormat}
                                        </option>
                                      ))}
                                  </SelectF>
                                )
                              ) : null}

                              {!isDateFormat && (
                                <>
                                  <label>
                                    Date & Time Format
                                    <a
                                      className="edit-preferences"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        showDf();
                                      }}
                                    >
                                      <i
                                        className="fa fa-pencil-square-o"
                                        aria-hidden="true"
                                      ></i>
                                    </a>
                                  </label>
                                  <span>{prefData.dateFormat}</span>
                                </>
                              )}
                              {isDateFormat && (
                                <>
                                  <a
                                    className={`edit-preferences ${
                                      isDateFormat ? "active" : null
                                    }`}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      hideDf();
                                      props.setFieldValue(
                                        "dateFormat",
                                        prefData.dateFormat
                                      );
                                    }}
                                    style={{ top: 25 }}
                                  >
                                    <i
                                      className="fa fa-times"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </>
                              )}
                            </div>
                          </div>
                          <div className="col-sm-12">
                            <div
                              className="form-group position-relative box-col"
                              style={{ paddingBottom: `${isTimeZone}?5:10` }}
                            >
                              {isTimeZone ? (
                                isLoadingGZ ? (
                                  <SelectLoader />
                                ) : (
                                  <SelectF name="timeZone" label="Time Zone">
                                    {dataGZ.data.data &&
                                      dataGZ.data.data.map((z) => (
                                        <option key={z.id} value={z.timeZone3}>
                                          {z.timeZone3}
                                        </option>
                                      ))}
                                  </SelectF>
                                )
                              ) : null}
                              {!isTimeZone && (
                                <>
                                  <label>
                                    Time Zone{" "}
                                    <a
                                      className="edit-preferences"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        showTz();
                                      }}
                                    >
                                      <i
                                        className="fa fa-pencil-square-o"
                                        aria-hidden="true"
                                      ></i>
                                    </a>
                                  </label>{" "}
                                  <span>{prefData.timeZone}</span>{" "}
                                </>
                              )}
                              {isTimeZone && (
                                <>
                                  <a
                                    className={`edit-preferences ${
                                      isTimeZone ? "active" : null
                                    }`}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      hideTz();
                                      props.setFieldValue(
                                        "timeZone",
                                        prefData.timeZone
                                      );
                                    }}
                                    style={{ top: 25 }}
                                  >
                                    <i
                                      className="fa fa-times"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                </>
                              )}
                            </div>
                          </div>
                          <div className="col-sm-12">
                            <div
                              className={`form-group position-relative box-col ${
                                isTheme ? "brd-active" : null
                              }`}
                              style={{ paddingBottom: `${isTheme}?5:10` }}
                            >
                              <label>
                                Theme{" "}
                                {!isTheme && (
                                  <a
                                    className="edit-preferences"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      showTheme();
                                    }}
                                  >
                                    <i
                                      className="fa fa-pencil-square-o"
                                      aria-hidden="true"
                                    ></i>
                                  </a>
                                )}
                              </label>{" "}
                              {isTheme && (
                                <a
                                  className={`edit-preferences ${
                                    isTheme ? "active" : null
                                  }`}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    hideTheme();
                                    setThemeName(themeValue);
                                  }}
                                  style={{ top: 10 }}
                                >
                                  <i
                                    className="fa fa-times"
                                    aria-hidden="true"
                                  ></i>
                                </a>
                              )}
                              <span className={isTheme ? "active" : null}>
                                {themeName === "blue-white"
                                  ? "Pre-defined - Blue and White"
                                  : themeName === "blue"
                                  ? "Pre-defined - Blue"
                                  : themeName === "grey-white"
                                  ? "Pre-defined - Grey and White"
                                  : themeName === "grey"
                                  ? "Pre-defined - Grey"
                                  : themeName === "gold"
                                  ? "Pre-defined - Gold"
                                  : themeName === "gold-white"
                                  ? "Pre-defined - Gold and White"
                                  : themeName === "advanced-blue-white"
                                  ? "User set Theme"
                                  : null}
                              </span>{" "}
                            </div>
                            {isTheme && (
                              <div className="theme-icons">
                                <div className="row">
                                  {themeObj.length <= 6 && (
                                    <div className="col-sm-12 pl-0">
                                      <div className="form-group form___group">
                                        <Link
                                          to="/userthemesettings/configurator"
                                          className="userdefined"
                                          onClick={() => {
                                            setSettings(false);
                                            setVisibleTheme(true);
                                          }}
                                        >
                                          Set Your Own Theme
                                        </Link>
                                      </div>
                                    </div>
                                  )}
                                  {[...new Set(themeObj)]
                                    .sort()
                                    .map((t, index) => {
                                      if (t === "advanced-blue-white") {
                                        return (
                                          <div className="col-sm-12 pl-0">
                                            <div className="form-group form___group">
                                              <div className="row">
                                                <div className="col-sm-1">
                                                  <span
                                                    className={`thumbnail-theme ${t}`}
                                                  >
                                                    <input
                                                      type="radio"
                                                      name="radio"
                                                      id={index}
                                                      value={t}
                                                      checked={themeName === t}
                                                      onChange={() => {
                                                        applyTheme(t);
                                                      }}
                                                    />
                                                    <span className="checkmark"></span>
                                                  </span>
                                                </div>
                                                <div className="col-sm-10">
                                                  <label
                                                    className="userdefined"
                                                    htmlFor={index}
                                                  >
                                                    User set Theme
                                                  </label>
                                                </div>
                                                <div className="col-sm-1">
                                                  {themeName ===
                                                    "advanced-blue-white" && (
                                                    <Link
                                                      to="/userthemesettings/configurator"
                                                      className="edit-preferences"
                                                      onClick={() => {
                                                        setSettings(false);
                                                        setVisibleTheme(true);
                                                      }}
                                                      style={{ top: 23 }}
                                                    >
                                                      <i
                                                        className="fa fa-pencil-square-o"
                                                        aria-hidden="true"
                                                      ></i>
                                                    </Link>
                                                  )}
                                                </div>
                                              </div>

                                              {/* <Link
                                              to="/userthemesettings/configurator"
                                              className="userdefined"
                                              onClick={() => {
                                                setSettings(false);
                                                setVisibleTheme(true);
                                                // hideTz();
                                                // hideTheme();
                                                // hideDf();
                                              }}
                                            >
                                              {themeName === t ? (
                                                <>
                                                  <i className="fa fa-check-circle"></i>{" "}
                                                  Custom Theme
                                                </>
                                              ) : (
                                                <>
                                                  <i
                                                    className="fa fa-paint-brush"
                                                    aria-hidden="true"
                                                  ></i>
                                                  Set Your Own Theme
                                                </>
                                              )}
                                             
                                            </Link> */}
                                            </div>
                                          </div>
                                          // <div className="col-sm-12 pl-0" key={index}>
                                          //   <div className="form-group form___group">
                                          //     <span
                                          //       className={`thumbnail-theme ${t}`}
                                          //     >
                                          //       <input
                                          //         type="radio"
                                          //         name="radio"
                                          //         id={index}
                                          //         value={t}
                                          //         checked={themeName === t}
                                          //         onChange={() => {
                                          //           applyTheme(t);
                                          //         }}
                                          //       />
                                          //       <span className="checkmark"></span>
                                          //       <label

                                          //         className={`theme-img ${
                                          //           themeName === t ? "active" : ""
                                          //         }`}
                                          //       >
                                          //         <label  htmlFor={index}>{themeObj.length>6?"Custom Theme":"Set Your Own Theme"}</label>
                                          //         <div className="rad-design"></div>
                                          //         <img
                                          //           height="80"
                                          //           src={null}
                                          //           alt="img"
                                          //         />
                                          //       </label>
                                          //     </span>
                                          //   </div>
                                          // </div>
                                        );
                                      } else if (t === "blue-white") {
                                        return (
                                          <div
                                            className="col-sm-6 pl-0"
                                            key={index}
                                          >
                                            <div className="form-group form___group">
                                              <span
                                                className={`thumbnail-theme ${t}`}
                                              >
                                                <input
                                                  type="radio"
                                                  name="radio"
                                                  id={index}
                                                  value={t}
                                                  checked={themeName === t}
                                                  onChange={() => {
                                                    applyTheme(t);
                                                  }}
                                                />
                                                <span className="checkmark"></span>
                                                <label
                                                  htmlFor={index}
                                                  className={`theme-img ${
                                                    themeName === t
                                                      ? "active"
                                                      : ""
                                                  }`}
                                                >
                                                  <label>
                                                    {"Blue and White"}
                                                  </label>
                                                  <div className="rad-design"></div>
                                                  <img
                                                    height="80"
                                                    src={Blueandwhite}
                                                    alt="img"
                                                  />
                                                </label>
                                              </span>
                                            </div>
                                          </div>
                                        );
                                      } else if (t === "blue") {
                                        return (
                                          <div
                                            className="col-sm-6 pl-0"
                                            key={index}
                                          >
                                            <div className="form-group form___group">
                                              <span
                                                className={`thumbnail-theme ${t}`}
                                              >
                                                <input
                                                  type="radio"
                                                  name="radio"
                                                  id={index}
                                                  value={t}
                                                  checked={themeName === t}
                                                  onChange={() => {
                                                    applyTheme(t);
                                                  }}
                                                />
                                                <span className="checkmark"></span>
                                                <label
                                                  htmlFor={index}
                                                  className={`theme-img ${
                                                    themeName === t
                                                      ? "active"
                                                      : ""
                                                  }`}
                                                >
                                                  <label>{"Blue"}</label>
                                                  <div className="rad-design"></div>
                                                  <img
                                                    height="80"
                                                    src={Blue}
                                                    alt="img"
                                                  />
                                                </label>
                                              </span>
                                            </div>
                                          </div>
                                        );
                                      } else if (t === "grey") {
                                        return (
                                          <div
                                            className="col-sm-6 pl-0"
                                            key={index}
                                          >
                                            <div className="form-group form___group">
                                              <span
                                                className={`thumbnail-theme ${t}`}
                                              >
                                                <input
                                                  type="radio"
                                                  name="radio"
                                                  id={index}
                                                  value={t}
                                                  checked={themeName === t}
                                                  onChange={() => {
                                                    applyTheme(t);
                                                  }}
                                                />
                                                <span className="checkmark"></span>
                                                <label
                                                  htmlFor={index}
                                                  className={`theme-img ${
                                                    themeName === t
                                                      ? "active"
                                                      : ""
                                                  }`}
                                                >
                                                  <label>{"Grey"}</label>
                                                  <div className="rad-design"></div>
                                                  <img
                                                    height="80"
                                                    src={Grey}
                                                    alt="img"
                                                  />
                                                </label>
                                              </span>
                                            </div>
                                          </div>
                                        );
                                      } else if (t === "grey-white") {
                                        return (
                                          <div
                                            className="col-sm-6 pl-0"
                                            key={index}
                                          >
                                            <div className="form-group form___group">
                                              <span
                                                className={`thumbnail-theme ${t}`}
                                              >
                                                <input
                                                  type="radio"
                                                  name="radio"
                                                  id={index}
                                                  value={t}
                                                  checked={themeName === t}
                                                  onChange={() => {
                                                    applyTheme(t);
                                                  }}
                                                />
                                                <span className="checkmark"></span>
                                                <label
                                                  htmlFor={index}
                                                  className={`theme-img ${
                                                    themeName === t
                                                      ? "active"
                                                      : ""
                                                  }`}
                                                >
                                                  <label>
                                                    {"Grey and White"}
                                                  </label>
                                                  <div className="rad-design"></div>
                                                  <img
                                                    height="80"
                                                    src={Greyandwhite}
                                                    alt="img"
                                                  />
                                                </label>
                                              </span>
                                            </div>
                                          </div>
                                        );
                                      } else if (t === "gold") {
                                        return (
                                          <div
                                            className="col-sm-6 pl-0"
                                            key={index}
                                          >
                                            <div className="form-group form___group">
                                              <span
                                                className={`thumbnail-theme ${t}`}
                                              >
                                                <input
                                                  type="radio"
                                                  name="radio"
                                                  id={index}
                                                  value={t}
                                                  checked={themeName === t}
                                                  onChange={() => {
                                                    applyTheme(t);
                                                  }}
                                                />
                                                <span className="checkmark"></span>
                                                <label
                                                  htmlFor={index}
                                                  className={`theme-img ${
                                                    themeName === t
                                                      ? "active"
                                                      : ""
                                                  }`}
                                                >
                                                  <label>{"Gold"}</label>
                                                  <div className="rad-design"></div>
                                                  <img
                                                    height="80"
                                                    src={Gold}
                                                    alt="img"
                                                  />
                                                </label>
                                              </span>
                                            </div>
                                          </div>
                                        );
                                      } else if (t === "gold-white") {
                                        return (
                                          <div
                                            className="col-sm-6 pl-0"
                                            key={index}
                                          >
                                            <div className="form-group form___group">
                                              <span
                                                className={`thumbnail-theme ${t}`}
                                              >
                                                <input
                                                  type="radio"
                                                  name="radio"
                                                  id={index}
                                                  value={t}
                                                  checked={themeName === t}
                                                  onChange={() => {
                                                    applyTheme(t);
                                                  }}
                                                />
                                                <span className="checkmark"></span>
                                                <label
                                                  htmlFor={index}
                                                  className={`theme-img ${
                                                    themeName === t
                                                      ? "active"
                                                      : ""
                                                  }`}
                                                >
                                                  <label>
                                                    {"Gold and White"}
                                                  </label>
                                                  <div className="rad-design"></div>
                                                  <img
                                                    height="80"
                                                    src={Goldandwhite}
                                                    alt="img"
                                                  />
                                                </label>
                                              </span>
                                            </div>
                                          </div>
                                        );
                                      } else {
                                        return null;
                                      }
                                    })}
                                </div>
                              </div>
                            )}
                          </div>

                          <div className="setts-actionbtn">
                            <button
                              className="btn btn-primary"
                              type="submit"
                              disabled={
                                isLoadingM ||
                                (!isTheme && !isDateFormat && !isTimeZone)
                              }
                            >
                              {isLoadingM ? (
                                <Loader
                                  type="ThreeDots"
                                  color="#fff"
                                  height={10}
                                />
                              ) : (
                                "Apply"
                              )}
                            </button>
                            <a
                              className="btn btn-default"
                              style={{ marginLeft: 5 }}
                              onClick={() => {
                                props.resetForm();
                                hideDf();
                                hideTz();
                                hideTheme();
                                setThemeName(themeValue);
                              }}
                              disabled={
                                isLoadingM ||
                                (!isTheme && !isDateFormat && !isTimeZone)
                              }
                            >
                              Clear
                            </a>
                          </div>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </nav>
      </Drawer>
    </div>
  );
});
export default Settings;
