import { createSlice } from "@reduxjs/toolkit";
import { Get } from "../../utils/AxiosUtils";
import { taskauditlog } from "../../utils/IncidentEndpoints";

export const initialState = {
  loading: false,
  hasErrors: false,
  auditlogs: [],
};

// A slice for tasks with our three reducers
const auditlogsSlice = createSlice({
  name: "auditlogs",
  initialState,
  reducers: {
    getAuditlogs: (state) => {
      state.loading = true;
    },
    getAuditlogsSuccess: (state, { payload }) => {
      state.auditlogs = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    getAuditlogsFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
  },
});
// Three actions generated from the slice
export const { getAuditlogs, getAuditlogsSuccess, getAuditlogsFailure } =
  auditlogsSlice.actions;

// A selector
export const auditlogsSelector = (state) => state.auditlogs;

// The reducer
export default auditlogsSlice.reducer;

// Asynchronous thunk action
export function fetchAuditlog(id) {
  return async (dispatch) => {
    dispatch(getAuditlogs());
    try {
      const response = await Get(
        `${taskauditlog}?filter[where][keyValue]=${id}&filter[order]=updatedOn desc`
      );
      dispatch(getAuditlogsSuccess(response["data"]));
    } catch (error) {
      dispatch(getAuditlogsFailure());
    }
  };
}
