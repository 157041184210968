import React from "react";
//import ComingSoon from "../Custom/ComingSoon";
//import background from "../../assets/images/comingsoon-list-bg.png";

function TermService() {
  return (
    <div className="staticPage">
      <p>Terms of Use are being legally reviewed and will be published soon.</p>
      {/* <h2>License Grant</h2>
      <p>
        HCL grants Customer a non-exclusive, non-transferable, non-sub
        licensable license in the Territory to install, access, and use the
        Programs (i) up to the Licensed Capacity; (ii) for Customer and its
        Affiliates’ internal business use; and (iii) in accordance with the
        Documentation and the applicable Order (collectively, a “<b>License</b>
        ”). Affiliates may use and access the Programs and Support under the
        terms of this Agreement, and Customer is fully responsible for its
        Affiliates compliance with the terms of this Agreement and the Order.
        Licensee and its Affiliate may not use or access the Programs outside
        the Territory except as otherwise specified in an Order.
      </p>
      <h2>License Restrictions</h2>
      <p>
        The License grant mentioned above is subject to Licensee’s agreement and
        compliance with each of the following:{" "}
      </p>
      <ul className="hindiNum">
        <li>
          The Program contains certain materials and Third Party Software
          licensed from a third party, and Customer assumes responsibility for
          these materials and their use in the Program. Part of the Third Party
          Software and other software integrated with the Program is open source
          software whereby the source code thereof is available to the public
          for inspection and use by others (“<b>Open Source Software</b>”), and
          the terms and conditions of the applicable license agreement permit
          recipients of such Open Source Software to freely (and without the
          payment of any fee or royalty) copy, modify and distribute the Open
          Source Software’s source code. The Program is subject to the
          applicable open source license with respect to such Open Source
          Software;
        </li>
        <li>
          The Program, including the Third Party Software contained in the
          Program, is licensed, and not sold, and neither HCL nor any third
          party passes any title to the Program to Customer;
        </li>
        <li>
          Customer may not and will not copy, operate, or otherwise use the
          Program, including any Third Party Software contained in the Program,
          in excess of the Licensed Capacity or other than as set forth in the
          License above nor prepare any derivative work thereof, or otherwise
          use, copy, modify, distribute, assign, sublicense, lease, rent, or
          otherwise transfer the Program, including any Third Party Software
          contained in the Program, except as expressly permitted in this
          Agreement or except to the extent required by law;
        </li>
        <li>
          Customer will not use the Programs in an outsourcing or service bureau
          environment on its behalf and/or on behalf of non-affiliated third
          parties or allow the Programs to be used by an outsourcing or service
          bureau provider on behalf of the Customer;
        </li>
        <li>
          Customer will not distribute the Program, including any Third Party
          Software contained in the Program, to end-users as on-premises
          distributions and the Customer will not offer the Program or such
          Third Party Software as a cloud service or software-as-a-service to
          any end-users;
        </li>
        <li>
          Customer is prohibited from reverse engineering, reverse assembling,
          reverse compiling, translating, or otherwise trying to discover the
          source code form of any software provided in object code form of the
          Program, including any Third Party Software contained in the Program,
          except as permitted by the national or regional law of the places
          where the Customer does business (without the opportunity for
          contractual waiver), and then only with respect to the particular copy
          of object code incorporated into that particular Program or Third
          Party Software;
        </li>
        <li>
          Customer will not use any of the Program’s components, files, modules,
          audio-visual content, or related licensed materials separately from
          that Program;
        </li>
        <li>
          Customer will not attempt to disable or circumvent any of the
          licensing mechanisms within the Program;
        </li>
        <li>
          Warranties provided, including any implied warranties, are provided
          solely by the HCL entity licensing the Program under the terms of an
          Order and not by any third party or other HCL entities;
        </li>
        <li>
          Customer will reproduce all copyright notices and other legends of
          ownership on each copy, or partial copy, of the Program and will
          ensures that anyone who uses the Program (accessed either locally or
          remotely) does so only on Licensee’s behalf and complies with the
          terms of this Agreement;
        </li>
        <li>
          If the Program is replaced by a trade-up Program, the license for the
          Program getting replaced will be promptly terminated;
        </li>
        <li>
          When Licensee receives an update, fix, or patch to a Program, Licensee
          accepts any additional or different terms that are applicable to such
          update, fix, or patch that are specified in its Documentation. If no
          additional or different terms are provided, then the update, fix, or
          patch is subject solely to this Agreement. If the Program is replaced
          by an update, Licensee agrees to promptly discontinue use of the
          replaced Program;
        </li>
        <li>
          If HCL licenses the Program for a fixed term, Licensee’s License is
          automatically terminated at the end of the fixed term, unless Licensee
          and HCL otherwise agree in writing;
        </li>
        <li>
          The restrictions in this Section 4.0 apply to each copy of the
          Program, including any Third Party Software included in the Program,
          that Licensee makes.
        </li>
      </ul> */}
    </div>
  );
}

export default TermService;
