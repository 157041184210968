import moment from 'moment';
import {
  prefDateFormatAccordingUser,
  getDateWord,
  findTimeFormate,
} from '../../utils/CommonMethod';
export const Headers = [
  {
    columnName: 'meetingDefinationNumber',
    HeadingValue: 'Work Item Id',
    filterInputType: 'th',
    endPoint: '',
  },
  {
    columnName: 'title',
    HeadingValue: 'Title',
    filterInputType: 'th',
    endPoint: '',
  },
  {
    columnName: 'companyName',
    HeadingValue: 'CompanyName',
    filterInputType: 'dtp',
    endPoint: '',
  },
  {
    columnName: 'status',
    HeadingValue: 'Status',
    filterInputType: 'th',
    endPoint: '',
  },

  {
    columnName: 'startDateTime',
    HeadingValue: 'Start Date',
    filterInputType: 'ddp',
    endPoint: '',
  },
  {
    columnName: 'primaryChairPerson',
    HeadingValue: 'Chair Person',
    filterInputType: 'th',
    endPoint: '',
  },
  {
    columnName: 'recurranceFlag',
    HeadingValue: 'Recurrance',
    filterInputType: 'th',
    endPoint: 'NA\r  ',
  },
  {
    columnName: 'createdOn',
    HeadingValue: 'CreatedOn',
    filterInputType: 'th',
    endPoint: '',
  },
];
export const ending = [
  {
    key: 'After',
    value: 'Ends After',
  },
  { key: 'on Date', value: 'Ends By' },
  { key: 'Never', value: 'Naver Ends' },
];

export const utis = [
  {
    key: 'Day(s)',
    value: 'daily',
  },
  { key: 'Week(s)', value: 'weekly' },
  { key: 'Month(s)', value: 'monthly' },
  { key: 'Year(s)', value: 'yearly' },
];

export const yearMonthName = [
  {
    key: 'Jan',
    value: '1',
  },
  { key: 'Feb', value: '2' },
  { key: 'Mar', value: '3' },
  { key: 'Apr', value: '4' },
  { key: 'May', value: '5' },
  { key: 'Jun', value: '6' },
  { key: 'Jul', value: '7' },
  { key: 'Aug', value: '8' },
  { key: 'Sep', value: '9' },
  { key: 'Oct', value: '10' },
  { key: 'Nov', value: '11' },
  { key: 'Dec', value: '12' },
];

export const week = [
  { key: 'Sun', value: '7', name: 'Sunday' },
  { key: 'Mon', value: '1', name: 'Monday' },
  { key: 'Tue', value: '2', name: 'Tuesday' },
  { key: 'Wed', value: '3', name: 'Wednesday' },
  { key: 'Thu', value: '4', name: 'Thursday' },
  { key: 'Fri', value: '5', name: 'Friday' },
  { key: 'Sat', value: '6', name: 'Saturday' },
];
export const weekCount = [
  { key: 'First', value: '1' },
  { key: 'Second', value: '2' },
  { key: 'Third', value: '3' },
  { key: 'Fourth', value: '4' },
  { key: 'Last', value: '5' },
];

const findDurationAsMinute = (start, end) => {
  var diff = moment.duration(moment(end).diff(moment(start)));
  return parseInt(diff.asMinutes()); //122360 minutes,but it gives total minutes in given miliseconds which is not expacted.
};
export const findDuration = (start, end) => {
  if (start && end) {
    var diff = moment.duration(moment(end).second(58).diff(moment(start)));

    var days = parseInt(diff.asDays()); //84

    var hours = parseInt(diff.asHours()); //2039 hours, but it gives total hours in given miliseconds which is not expacted.

    hours = hours - days * 24; // 23 hours

    var minutes = parseInt(diff.asMinutes()); //122360 minutes,but it gives total minutes in given miliseconds which is not expacted.

    minutes = minutes - (days * 24 * 60 + hours * 60);

    let duration = '';
    if (days) {
      if (days > 1) {
        duration = days + 'days';
      } else {
        duration = days + 'day';
      }
    }
    if (hours) {
      duration = duration + ' ' + hours + 'hr';
    }
    if (minutes) {
      duration = duration + ' ' + minutes + 'm';
    }

    if (days == 0 && hours == 0 && minutes == 0) {
      duration = '0m';
    }
    if (duration.includes('-')) {
      return '';
    }
    return duration;
  }
  return '';
};

export const replaceKey = (
  replacekeyTo1,
  replaceKeyTo2,
  replacekeyFrom1,
  replacekeyFrom2,
  data,
  extraKey,
  extraKeyReplace,
  imgeUrl
) => {
  if (data) {
    const transformed = data.map((obj) => {
      let result = {};
      result[replacekeyFrom1] = obj[replacekeyTo1] || '';
      result[replacekeyFrom2] = obj[replaceKeyTo2] || '';
      if (extraKey && extraKeyReplace) {
        result[extraKeyReplace] = obj[extraKey] || imgeUrl;
      }
      return result;
    });

    return transformed;
  }
  return [];
};

export const convertDateForServer = (date) => {
  if (date) {
    return moment(date).format('DD/MM/YYYY HH:mm:ss');
  }
  return '';
};
export const convertToformateData = (date) => {
  if (date) {
    return moment(date).format('DD/MM/YYYY');
  }
  return '';
};
export const addZiroSecond = (date, prefData, userInfo) => {
  return moment(date)
    .second(10)
    .format(prefDateFormatAccordingUser(prefData, userInfo, false));
};
export const returnServerPayload = (serverObj, prefData, userInfo, type) => {
  let payload = {
    title: serverObj.title,
    companyId: serverObj.customer,
    companyName: serverObj.customerName,
    forumName: serverObj.forum,
    committeeName: serverObj.committee,
    trackName: serverObj.track,
    category: serverObj?.category,
    subTrackName: serverObj.subTrack,
    forumId: serverObj.forumId,
    committeeId: serverObj.committeeId,
    categoryId: serverObj.categoryId,
    regionId: serverObj.region,
    regionName: serverObj.regionName,
    meetingRecurrenceSummary: serverObj.meetingRecurrenceSummary,
    supplierId: serverObj.supplier?.map((val) => val && val.value).join(','),
    supplierNames: serverObj.supplier?.map((val) => val && val.label).join(','),
    primaryChairPerson: serverObj.chairPerson,
    primaryChairPersonName: serverObj.chairPersonName?.split('|')[0],
    externalEmailsId: serverObj.externalEmails?.map((val) => val).join(','),
    secondaryChairPersons: serverObj?.coordinator
      ?.map((val) => val && val.value)
      .join(','),

    secondaryChairPersonsName: serverObj?.coordinator
      ?.map((val) => val && val.label)
      .join(','),
    secondaryChairPersonsEmail: serverObj?.coordinator
      ?.map((val) => val && val.email)
      .join(','),
    secondaryChairPersonsCompany: serverObj?.coordinator
      ?.map((val) => val && val?.company?.companyName)
      .join(','),
    primaryChairPersonNameCompany: serverObj?.primaryChairPersonNameCompany,
    primaryChairPersonNameEmail: serverObj?.primaryChairPersonNameEmail,
    objectives: serverObj.objectives,
    agenda: serverObj.agenda,
    meetingLocation: serverObj.meetingLocation,
    meetingLocationDetails: serverObj.location,
    timeZone: serverObj.timeZone,
    status: serverObj.status,
    statusId: serverObj.statusId,
    usersId: serverObj?.persons?.map((val) => val && val.value).join(','),
    usersName: serverObj?.persons?.map((val) => val && val.label).join(','),
    usersEmail: serverObj?.persons?.map((val) => val && val.email).join(','),
    usersCompany: serverObj?.persons
      ?.map((val) => val && val?.company?.companyName)
      .join(','),
    groupsEmail: serverObj?.groups?.map((val) => val && val.email).join(','),
    groupsCompany: serverObj?.groups
      ?.map((val) => val && val?.company?.companyName)
      .join(','),
    groupsId: serverObj?.groups?.map((val) => val && val.value).join(','),
    groupsName: serverObj?.groups?.map((val) => val && val.label).join(','),
    startDateTime: addZiroSecond(serverObj.startOn, prefData, userInfo),
    endDateTime: addZiroSecond(serverObj.startEndOn, prefData, userInfo),
    durationInMiutes: findDurationAsMinute(
      serverObj.startOn,
      serverObj.startEndOn
    ),
    recurranceFlag: serverObj.recuring,
    recurrenceUnit: serverObj.recuring ? serverObj.units : '',
    recurrenceStartDateTime: '',
    recurrenceEndDateTime: '',
    recurrenceFrequency: '',
    meetingTypeId: serverObj.meetingTypeId,
  };

  if (type === 'details') {
    payload.recurrenceStartDateTime = addZiroSecond(
      serverObj?.recurrenceStartDateTime,
      prefData,
      userInfo
    );
    payload.recurrenceEndDateTime = addZiroSecond(
      serverObj?.recurrenceEndDateTime,
      prefData,
      userInfo
    );
  }

  if (serverObj.recuring) {
    payload.recurrenceStartDateTime = addZiroSecond(
      serverObj.startOn,
      prefData,
      userInfo
    );

    payload.recurrenceFrequency = serverObj.every;
    payload.noEndDate = false;
    payload.endAfterSpecifiedOccurances = false;
    payload.endBy = false;
    payload.noOfOccurances = '';
    if (serverObj.redioAccuranceSelection === 'Ends By') {
      payload.endBy = true;
      payload.recurrenceEndDateTime = `${getDateWord(
        addZiroSecond(serverObj.recuringEndOn, prefData, userInfo),
        false
      )}${findTimeFormate(
        addZiroSecond(serverObj.startOn, prefData, userInfo),
        false
      )}`;
    }
    if (serverObj.redioAccuranceSelection === 'Ends After') {
      payload.endAfterSpecifiedOccurances = true;
      payload.noOfOccurances = Number(
        serverObj.redioAccuranceSelectionEndAfterDaysNo
      );
    }
    if (serverObj.redioAccuranceSelection === 'Naver Ends') {
      payload.noEndDate = true;
    }

    if (serverObj.units === 'weekly') {
      payload.recurrenceUnit = serverObj.units;
      payload.recurrenceWeekday = serverObj?.weeks?.map((val) => val).join(',');
    }
    if (serverObj.units === 'monthly') {
      payload.recurrenceUnit = serverObj.units;
      if (serverObj.redioButtonSelection === 'On Day') {
        payload.singleDaySelection = true;
        payload.recurrenceMonthday = serverObj.monthDaysNo;
      } else {
        payload.recurrenceMonthWeekdayInterval = serverObj.weekNo;
        payload.intervalDaySelection = true;
        payload.recurrenceMonthWeekday = serverObj.weekDay;
      }
    }
    if (serverObj.units === 'yearly') {
      payload.recurrenceUnit = serverObj.units;
      if (serverObj.redioButtonSelection === 'On Day') {
        payload.singleDaySelection = true;
        payload.recurrenceYearMonth = serverObj.yearMonthNameForOnDay;
        payload.recurrenceYearDay = serverObj.monthDaysNo;
      } else {
        payload.intervalDaySelection = true;
        payload.recurrenceYearWeekdayInterval = serverObj.weekNo;
        payload.recurrenceYearWeekday = serverObj.weekDay;
        payload.recurrenceYearMonthDay = serverObj.yearMonthName;
      }
    }
  }
  return payload;
};

export const convertDateForUI = (date) => {
  if (date) {
    return moment(new Date(date), 'DD/MM/YYYY HH:mm:ss').toDate();
  }
  return undefined;
};
export const splitValue = (value, label) => {
  const lables = label?.split(',');
  const values = value?.split(',');
  let result = [];
  lables?.map((val, index) => {
    if (val && values[index]) {
      result.push({ label: val, value: values[index] });
    }
  });

  return result;
};
export const createUIPayload = (serverObj, prefData, userInfo) => {
  let payload = {
    meetingDefinationNumber: serverObj?.meetingDefinationNumber,
    meetingId: serverObj?.meetingDefinationId,
    title: serverObj.title,
    customer: serverObj.companyId?.toString(),
    customerName: serverObj.companyName,
    mop: serverObj.mop,
    forum: serverObj.forumName,
    committee: serverObj.committeeName,
    track: serverObj.trackName,
    subTrack: serverObj.subTrackName,
    forumId: serverObj.forumId,
    committeeId: serverObj.committeeId,
    categoryId: serverObj.categoryId,
    status: serverObj.status,
    statusId: serverObj.statusId,
    region: serverObj.regionId,
    regionName: serverObj.regionName,
    category: serverObj?.category,
    userAuthorized: serverObj?.userAuthorized,
    supplier: splitValue(serverObj.supplierId, serverObj.supplierNames),

    chairPerson: serverObj.primaryChairPerson,
    chairPersonName: serverObj.primaryChairPersonName,
    primaryChairPersonNameCompany:
      serverObj?.primaryChairPersonNameCompany || '',
    primaryChairPersonNameEmail: serverObj?.primaryChairPersonNameEmail || '',
    timeZone: serverObj.timeZone,
    objectives: serverObj.objectives,
    agenda: serverObj.agenda,
    meetingLocation: serverObj.meetingLocation,
    location: serverObj.meetingLocationDetails,
    externalEmails:
      (serverObj?.externalEmailsId && serverObj.externalEmailsId?.split(',')) ||
      [],
    startOn: moment(
      serverObj.startDateTime,
      prefDateFormatAccordingUser(prefData, userInfo, true)
    ),
    startEndOn: moment(
      serverObj.endDateTime,
      prefDateFormatAccordingUser(prefData, userInfo, true)
    ),

    duration: findDuration(
      convertDate(
        serverObj.startDateTime,
        prefDateFormatAccordingUser(prefData, userInfo, true)
      ),
      convertDate(
        serverObj.endDateTime,
        prefDateFormatAccordingUser(prefData, userInfo, true)
      )
    ),
    recuring: serverObj.recurranceFlag,
    units: serverObj.recurrenceUnit || 'daily',
  };

  if (serverObj.recurranceFlag) {
    payload.recuringStartOn = serverObj?.recurrenceStartDateTime
      ? serverObj.recurrenceStartDateTime
      : '';
    payload.recuringEndOn = serverObj.recurrenceEndDateTime
      ? moment(
          serverObj.recurrenceEndDateTime,
          prefDateFormatAccordingUser(prefData, userInfo, true)
        )
      : '';
    payload.every = serverObj.recurrenceFrequency;
  }

  if (serverObj.noEndDate) {
    payload.redioAccuranceSelection = 'Naver Ends';
  }
  if (serverObj.endAfterSpecifiedOccurances) {
    payload.redioAccuranceSelection = 'Ends After';
    payload.redioAccuranceSelectionEndAfterDaysNo = serverObj.noOfOccurances;
  }
  if (serverObj.endBy) {
    payload.redioAccuranceSelection = 'Ends By';
  }

  if (serverObj.recurrenceUnit === 'weekly') {
    payload.units = serverObj.recurrenceUnit;
    payload.weeks = serverObj.recurrenceWeekday?.split(',') || [];
  }
  if (serverObj.units === 'monthly') {
    payload.units = serverObj.recurrenceUnit;
    if (serverObj.singleDaySelection) {
      payload.redioButtonSelection = 'On Day';
      payload.monthDaysNo = serverObj.recurrenceMonthday;
    } else if (serverObj.intervalDaySelection) {
      payload.weekNo = serverObj.recurrenceMonthWeekdayInterval;
      payload.redioButtonSelection = 'On The';
      payload.weekDay = serverObj.recurrenceMonthWeekday;
    }
  }
  if (serverObj.units === 'yearly') {
    payload.units = serverObj.recurrenceUnit;
    if (serverObj.singleDaySelection) {
      payload.redioButtonSelection = 'On Day';
      payload.yearMonthNameForOnDay = serverObj.recurrenceYearMonth;
      payload.monthDaysNo = serverObj.recurrenceYearDay;
    } else if (payload.intervalDaySelection) {
      payload.redioButtonSelection = 'On The';
      payload.weekNo = serverObj.recurrenceYearWeekdayInterval;
      payload.weekDay = serverObj.recurrenceYearWeekday;
      payload.yearMonthName = serverObj.recurrenceYearMonthDay;
    }
  }

  return payload;
};

export function createPayloadForChips(...params) {
  let usersId = params[0]?.split(',') || [];
  let usersName = params[1]?.split(',') || [];
  let usersEmail = params[2]?.split(',') || [];
  let usersCompany = params[3]?.split(',') || [];
  const peopleObjects = usersId.filter(Boolean).map((value, index) => {
    let obj = {
      value: '',
      label: '',
      email: '',
      company: {
        companyName: '',
      },
    };
    obj.value = value;
    obj.label = usersName[index] || '';
    obj.email = usersEmail[index] || '';
    obj.company.companyName = usersCompany[index] || '';
    return obj;
  });
  return peopleObjects;
}
export const filterUser = (usersData, editUsersData) => {
  let users = editUsersData?.split(',') || [];
  console.log('users', users);
  if (users.length > 0) {
    let filterUser = usersData.filter(
      (user) =>
        users.includes(user.userName) || users.includes(user.userId?.toString())
    );
    return replaceKeyInObjectArray(filterUser, {
      fullName: 'label',
      userName: 'value',
      imgName: 'img',
      emailAddress: 'email',
    });
  }
  return users;
};
export const filterGroup = (usersData, editUsersData) => {
  let users = editUsersData?.split(',') || [];

  if (users.length > 0) {
    let filterUser = usersData.filter((user) =>
      users.includes(user.value?.toString())
    );
    return replaceKeyInObjectArray(filterUser, {
      actualName: 'label',
      groupId: 'value',
      imgName: 'img',
      emailAddress: 'email',
    });
  }

  return users;
};
export const filterGroupUser = (usersData, editUsersData) => {
  let users = editUsersData?.split(',') || [];

  if (users.length > 0) {
    let filterUser = usersData.filter((user) =>
      users.includes(user.groupId?.toString())
    );
    return replaceKeyInObjectArray(filterUser, {
      actualName: 'label',
      groupId: 'value',
      imgName: 'img',
      emailAddress: 'email',
    });
  }

  return users;
};

export const replaceKeyInObjectArray = (a, r) => {
  if (a && r) {
    return a.map((o) =>
      Object.keys(o)
        .map((key) => ({ [r[key] || key]: o[key] || '' }))
        .reduce((a, b) => Object.assign({}, a, b))
    );
  }
  return [];
};

export const convertDate = (value, _dateFormat) => {
  return moment(
    value,

    _dateFormat
  );
};

export const arrayToCommaSperated = (values, type) => {
  if (type === 'label') {
    return (
      values
        ?.map((val) => val?.label)
        .filter(Boolean)
        .join(',') || ''
    );
  }

  if (type === 'value') {
    return (
      values
        ?.map((val) => val?.value)
        .filter(Boolean)
        .join(',') || ''
    );
  }

  if (type === 'email') {
    return (
      values
        ?.map((val) => val?.email)
        .filter(Boolean)
        .join(',') || ''
    );
  }
  if (type === 'companyName') {
    return (
      values
        ?.map((val) => val?.company?.companyName)
        .filter(Boolean)
        .join(',') || ''
    );
  }
};

export const filterURL = (urlParam, params) => {
  let url = urlParam;
  const queryParams = Object.entries(params).reduce(
    (a, [k, v]) => (v ? ((a[k] = v), a) : a),
    {}
  );
  let qs = new URLSearchParams(queryParams);
  return url + `?${qs.toString()}`;
};
export const arraWithComaSepratedData = (arrData, allData) => {
  let findAll = arrData.some((obj) => obj.value == 'all');
  if (findAll) {
    return '';
  }
  let allArray = arrData.filter((obj) => obj.value != 'all');
  return allArray?.map((obj) => obj.label).join(',') || [];
};
export const handleAllCaseData = (arrData, type) => {
  let findAll = arrData.some((obj) => obj.value === 'all');
  if (findAll) {
    return [];
  }
  if (type === 'label') {
    return arrData?.map((val) => val && val.label).join(',') || '';
  }

  return arrData?.map((val) => val && val.value);
};

export const NewlineText = (props) => {
  const text = props.text;
  const newText = text.split('\n').map((str) => <div>{str}</div>);
  return newText;
};
export const checkDateWithIn24Hr = (start, end) => {
  if (start && end) {
    var diff = moment.duration(moment(end).second(50).diff(moment(start)));
    var days = parseInt(diff.asDays()); //84
    var hours = parseInt(diff.asHours()); //2039 hours, but it gives total hours in given miliseconds which is not expacted.
    hours = hours - days * 24; // 23 hours
    var minutes = parseInt(diff.asMinutes()); //122360 minutes,but it gives total minutes in given miliseconds which is not expacted.
    minutes = minutes - (days * 24 * 60 + hours * 60);
    if (days === 1 && hours === 0 && minutes === 0) {
      return true;
    }
    if (days === 0 && hours <= 24) {
      return true;
    }
    return false;
  }
};

export const getWeekDayAccordingDate = (date) => {
  return moment(date).isoWeekday().toString();
};
