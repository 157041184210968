import { getmeetingOccurrences } from "utils/EndPoints";
import { filterURL } from "components/GovernanceMeeting/Helper";
import { Get } from "utils/AxiosUtils";

const getAll = (params) => {
  const url = filterURL(getmeetingOccurrences, params?.params);

  return Get(url, params?.params?.tenantId);
};

const calendarService = {
  getAll,
};

export { getAll };

export default calendarService;
