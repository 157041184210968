import { createSlice } from "@reduxjs/toolkit";
import { Get, Put } from "../../utils/AxiosUtils";
import {
  taskapi,
  updateTask,
  statusandpriority,
} from "../../utils/IncidentEndpoints";
import { getTaskStatuses } from "../../utils/EndPoints";
import { fetchTasks } from "./tasks";
export const initialState = {
  loading: false,
  hasErrors: false,
  details: [],
  loadingedit: false,
  updatedetials: [],
  statusvals: [],
  priortyvals: [],
  stautsLoading: false,
  priortyLoading: false,
};

const detailsSlice = createSlice({
  name: "details",
  initialState,
  reducers: {
    getDetails: (state) => {
      state.loading = true;
    },
    getDetailsSuccess: (state, { payload }) => {
      state.details = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    getDetailsFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    getStatus: (state) => {
      state.stautsLoading = true;
    },
    getPriorty: (state) => {
      state.priortyLoading = true;
    },
    getStatusSuccess: (state, { payload }) => {
      state.statusvals = payload;
      state.stautsLoading = false;
      state.hasErrors = false;
    },
    getPrioritySuccess: (state, { payload }) => {
      state.priortyvals = payload;
      state.priortyLoading = false;
      state.hasErrors = false;
    },
    updateDetails: (state, action) => {
      state.loadingedit = true;
      // console.log("Action", state, action);
    },
    updateDetailsSuccess: (state, { payload }) => {
      state.updatedetials = payload;
      state.loadingedit = false;
      state.hasErrors = false;
    },
  },
});
export const {
  getDetails,
  getDetailsSuccess,
  getStatus,
  getStatusSuccess,
  getPriorty,
  getDetailsFailure,
  updateDetails,
  updateDetailsSuccess,
  getPrioritySuccess,
} = detailsSlice.actions;

// A selector
export const detailsSelector = (state) => state.details;

// The reducer
export default detailsSlice.reducer;

// Asynchronous thunk action
export function fetchDetails(id, impCompID) {
  return async (dispatch) => {
    dispatch(getDetails());
    try {
      await Get(
        `${taskapi}?filter[where][taskCode]=${id}&filter[include]=status&filter[include][]=priorityDetails&filter[include][]=urgencyDetails&filter[include][]=impactDetails&filter[include]=companyDetails&filter[include]=taskIntegration&filter[include]=taskAttributes`,
        impCompID
      ).then((response) => {
        dispatch(getDetailsSuccess(response["data"]));
        dispatch(
          fetchStatusandPriority(
            "statusDetails",
            "",
            response["data"][0].statusId
          )
        );
      });
    } catch (error) {
      dispatch(getDetailsFailure());
    }
  };
}
///foundation/api/getMenuListData?ueryString

export function fetchStatusandPriority(type, compid, currentId) {
  return async (dispatch) => {
    dispatch(getStatus());
    try {
      await Get(
        `${getTaskStatuses}?filter[where][id]=${currentId}&filter[include][statusDetails][status]`,
        compid
      ).then((response) => {
        dispatch(getStatusSuccess(response["data"]));
      });
    } catch (error) {
      dispatch(getDetailsFailure());
    }
  };
}

export function fetchPriority(type, compid) {
  return async (dispatch) => {
    dispatch(getPriorty());
    try {
      await Get(statusandpriority(type), compid).then((response) => {
        dispatch(getPrioritySuccess(response["data"]));
      });
    } catch (error) {
      dispatch(getDetailsFailure());
    }
  };
}

export function updateDeatils(
  id,
  postdata,
  setSubmitting,
  setAlert,
  impCompID
) {
  return async (dispatch) => {
    dispatch(updateDetails());
    try {
      await Put(updateTask(id), postdata, impCompID)
        .then((response) => {
          setSubmitting(false);
          dispatch(updateDetailsSuccess(response["data"]));
          dispatch(fetchDetails(response["data"]["taskCode"]));
          dispatch(
            fetchTasks(response["data"].parentWorkItemId, `createdDate desc`)
          );
          setAlert({
            severity: "success",
            open: true,
            msg: "Task updated successfully",
          });
        })
        .catch((e) => {
          let _msg = e.response.data.error;
          setAlert({
            severity: "error",
            open: true,
            msg:
              _msg !== undefined
                ? _msg.message !== undefined
                  ? _msg.message
                  : _msg
                : e.message,
          });
          setSubmitting(false);
          dispatch(getDetailsFailure());
        });
    } catch (error) {
      dispatch(getDetailsFailure());
    }
  };
}
