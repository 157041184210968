import React, { useState } from "react";
import { Field, useField, ErrorMessage } from "formik";
import { useQuery } from "react-query";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { status } from "../../utils/EndPoints";
import { Get } from "../../utils/AxiosUtils";
import FloatLabel from "../Custom/FloatLabel/index";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
  },
  root: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 0,
      padding: "3.5px !important",
    },
  },
  _label: {
    fontSize: 13,
  },
  labelAsterisk: {
    color: "#f44336",
  },
  lblshrink: {
    transform: "translate(14px, -2px) scale(0.75) !important",
    background: "#ffffff",
  },
  lblshrinkStd: {
    background: "#ffffff",
  },
  focusedlbl: {
    background: "transparent",
  },
  Muierror: {
    marginLeft: 0,
    color: "#f44336 !important",
    position: "absolute",
    bottom: -20,
    right: 0,
    marginRight: 0,
    width: "auto",
    fontSize: "0.6964285714285714rem",
  },
  margintop: { marginTop: "0px !important" },
}));
export const Arrowdown = () => {
  return (
    <span className="ant-select-arrow">
      <span
        role="img"
        aria-label="down"
        className="anticon anticon-down ant-select-suffix downarrow"
      >
        <svg
          viewBox="64 64 896 896"
          focusable="false"
          data-icon="down"
          width="1em"
          height="1em"
          fill="currentColor"
          aria-hidden="true"
        >
          <path d="M884 256h-75c-5.1 0-9.9 2.5-12.9 6.6L512 654.2 227.9 262.6c-3-4.1-7.8-6.6-12.9-6.6h-75c-6.5 0-10.3 7.4-6.5 12.7l352.6 486.1c12.8 17.6 39 17.6 51.7 0l352.6-486.1c3.9-5.3.1-12.7-6.4-12.7z"></path>
        </svg>
      </span>
    </span>
  );
};
const StatusField = ({ label, qparam, grpcompid,mrtop, ...props }) => {
  const classes = useStyles();
  const [field, meta] = useField(props);
  const showError = meta.touched && !!meta.error;
  const { isLoading: isLoadingS, data: dataS } = useQuery(
    "getStausData",
    () => {
      return Get(
        `${status}${qparam !== undefined ? qparam : ""}`,
        `${grpcompid !== undefined ? grpcompid : ""}`
      );
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  return isLoadingS ? (
    <div className="form-group form__group">
      <FormControl style={{ width: "100%" }} >
        <Select
          style={{ marginTop: 0, maxWidth: "100%" }}
          name="Loading"
          native
          value={"loading...."}
          // variant="outlined"
          fullWidth
          IconComponent={Arrowdown}
        >
          <option value={"Loading...."}>Loading....</option>
        </Select>
      </FormControl>
    </div>
  ) : (
    <div className="form-group form__group">
      <FormControl
        // variant="outlined"
        className={classes.formControl}
        error={showError}
      >
        <ErrorMessage
          {...field}
          component="span"
          className={classes.Muierror}
        />
        <InputLabel
          shrink
          htmlFor="outlined-status-native-simple"
          {...props}
          error={showError}
          classes={{
            root: classes._label,
            asterisk: classes.labelAsterisk,
            // shrink: classes.lblshrink,
            focused: classes.focusedlbl,
          }}
        >
          {label}
        </InputLabel>
        <Field
        style={{marginTop:mrtop!==0?mrtop+"px":null}}
          {...field}
          {...props}
          as={Select}
          native
          label="Status"
          inputProps={{
            name: "status",
            size: "small",
          }}
          className="outlinedhover"
          IconComponent={Arrowdown}
        >
          <option aria-label="None" value="">
            --Select--
          </option>
          {dataS.data.data &&
            dataS.data.data.map((v) => (
              <option key={v.fieldNameKey1} value={v.fieldNameKey1}>
                {v.fieldNameKey1}
              </option>
            ))}
        </Field>
      </FormControl>
    </div>
  );
};

export const SelectFN = ({ label, children, ...props }) => {
  const [field, meta] = useField(props);
  const showError = meta.touched && !!meta.error;
  return (
    <FloatLabel
      {...field}
      label="Company"
      name="companyName"
      disabled={props.disabled}
    >
      {showError && (
        <ErrorMessage {...field} component="span" className="sel-error" />
      )}

      <select
        {...props}
        {...field}
        className="form-select"
        aria-label="Default select example"
      >
        <option aria-label="None" value="" />
        {children}
      </select>
    </FloatLabel>
  );
};

export const SelectF = ({
  label,
  children,
  variant,
  disabled,
  cssClassName,
  activeTab,
  ...props
}) => {
  const classes = useStyles();
  const [field, meta] = useField(props);
  const [shrnk, setShrnk] = useState(false);
  const showError = meta.touched && !!meta.error;
  //console.log("field.name==>", field.name, field.value);
  return (
    <div
      className={`form-group form__group ${cssClassName ? cssClassName : ""}`}
    >
      <FormControl
        // variant={variant ? variant : "outlined"}
        className={classes.formControl}
        error={showError}
        disabled={disabled}
      >
        <ErrorMessage
          {...field}
          component="span"
          className={classes.Muierror}
        />
        <InputLabel
          //  shrink={field.value || field.value === 0 || shrnk ? true : false}
          // variant={variant ? variant : "outlined"}
          shrink
          value={field.value || ""}
          {...props}
          {...field}
          error={showError}
          classes={{
            root: classes._label,
            asterisk: classes.labelAsterisk,
            shrink: classes.lblshrinkStd,
            // shrink: variant ? classes.lblshrinkStd : classes.lblshrink,
            focused: classes.focusedlbl,
          }}
        >
          {label}
        </InputLabel>
        <Field
          {...field}
          {...props}
          as={Select}
          native
          label={label}
          inputProps={{
            size: "small",
            name: field.name,
          }}
          value={field.value || ""}
          // label={variant ? variant : "outlined"}
          IconComponent={Arrowdown}
          className="outlinedhover"
          onFocus={() => setShrnk(true)}
          onBlur={() => setShrnk(false)}
        >
          <option aria-label="None" value="">
            {!activeTab && activeTab !== undefined ? "--" : "--Select--"}
          </option>
          {children}
        </Field>
      </FormControl>
    </div>
  );
};

export default StatusField;
