import React, { useContext, Suspense, useEffect } from 'react';
import {
  Route,
  BrowserRouter as Router,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { unifoThemes } from '../config/selector';

import PrivateRoute from './PrivateRoutes';

import Main from '../components/Layout/Main';
import { AuthContext } from '../contexts/AuthContext';
const MeetingComponent = React.lazy(() =>
  import('../components/GovernanceMeeting/AddMeeting')
);

const ListingActionItems = React.lazy(() =>
  import('../components/GovernanceMeeting/ActionItems/ListingActionItems')
);
const AddEditActionItem = React.lazy(() =>
  import('../components/GovernanceMeeting/ActionItems/AddEditActionItem')
);
const ListingMeetingComponent = React.lazy(() =>
  import('../components/GovernanceMeeting/ListingGovernance')
);

const Login = React.lazy(() => import('../components/Login/Login'));

const ResetPassword = React.lazy(() => import('../components/Login/ResetPass'));

const GlobleSearch = React.lazy(() =>
  import('../components/globalSearch/Container')
);
const TermService = React.lazy(() =>
  import('../components/Footer/TermService')
);

const LogOut = React.lazy(() => import('../components/Login/LogOut'));

const AddEditOrg = React.lazy(() =>
  import('../components/Organization/AddEditOrg/AddEditOrg')
);

const AddCompany = React.lazy(() => import('../components/Company/AddCompany'));

const EditCompany = React.lazy(() =>
  import('../components/Company/EditCompany')
);

const AddLocation = React.lazy(() =>
  import('../components/Location/AddLocation')
);

const AddGroup = React.lazy(() => import('../components/Group/AddGroup'));

const EditGroup = React.lazy(() => import('../components/Group/EditGroup'));

const EditLocation = React.lazy(() =>
  import('../components/Location/EditLocation')
);

const AddEditDept = React.lazy(() =>
  import('../components/Department/AddEditDept/AddEditDept')
);

const AddEditUser = React.lazy(() =>
  import('../components/User/AddEditUser/AddEditUser')
);

const AddEditCC = React.lazy(() =>
  import('../components/CostCenter/AddEditCC/AddEditCC')
);

const MyProfile = React.lazy(() => import('../components/MyProfile/MyProfile'));

const LsTargetDefinition = React.lazy(() =>
  import('../components/TargetDefinition/LsTargetDefinition')
);

const LsHolidaySchedule = React.lazy(() =>
  import('../components/HolidaySchedule/LsHolidaySchedule')
);

const LsWorkSchedule = React.lazy(() =>
  import('../components/WorkSchedule/LsWorkSchedule')
);

const AddEditWs = React.lazy(() =>
  import('../components/WorkSchedule/AddEditWs/AddEditWs')
);

const AddHolidaySchedule = React.lazy(() =>
  import('../components/HolidaySchedule/AddHolidaySchedule')
);

const AddEditSLA = React.lazy(() =>
  import('../components/TargetDefinition/AddEdit/AddEditTd')
);

const LsIncident = React.lazy(() =>
  import('../components/Incident/LsIncident')
);

const LsIncidentTrackList = React.lazy(() =>
  import('../components/Incident/LsIncidentTrackList')
);
const AddIncidentN = React.lazy(() =>
  import('../components/Incident/AddEditIncidentNew')
);

const LsTask = React.lazy(() => import('../components/Task/LsTask'));

//const LsuserDashboard = React.lazy(() =>import( "../components/Dashboard/userGraphData/GraphContainer"));

const EditTask = React.lazy(() => import('../components/Task/EditTaskNew'));

const AddEditRequest = React.lazy(() =>
  import('../components/Request/AddEdit/addEditRequest')
);

const LsRequest = React.lazy(() => import('../components/Request/LsRequest'));

const LsServiceRequestItem = React.lazy(() =>
  import('../components/RequestItems/LsRequestItems')
);

const AddEditRequestItem = React.lazy(() =>
  import('../components/RequestItems/AddEdit/AddEditRequestItem')
);

const LsChange = React.lazy(() => import('../components/Change/LsChange'));

const LsuserDashboard = React.lazy(() =>
  import('../components/Dashboard/userGraphData/GraphContainer')
);
const VmoLsuserDashboard = React.lazy(() =>
  import('../components/Dashboard/userGraphData/VmoGraphContainer')
);
const LsGovernanceDashboard = React.lazy(() =>
  import(
    '../components/GovernanceMeeting/Dashboard/userGraphData/GraphContainer'
  )
);
const LsGovernanceLandScape = React.lazy(() =>
  import('../components/GovernanceMeeting/Dashboard/siam-landscape')
);

const LsAdministration = React.lazy(() =>
  import('../components/Administration/LsAdministration')
);

const LsProblemTask = React.lazy(() =>
  import('../components/Problems/LsProblemTask')
);

const LsProblems = React.lazy(() =>
  import('../components/Problems/LsProblems')
);

const LsChangeTasks = React.lazy(() =>
  import('../components/Change/LsChangeTasks')
);

const LsIncidentTask = React.lazy(() =>
  import('../components/Incident/LsIncidentTask')
);

const LsServiceRequestItemTask = React.lazy(() =>
  import('../components/RequestItems/LsRequestedItemTask')
);

const AddEditChange = React.lazy(() =>
  import('../components/Change/AddEdit/AddEditChange')
);

const LsUser = React.lazy(() => import('../components/User/LsUser'));

const LsCostCenter = React.lazy(() =>
  import('../components/CostCenter/LsCostCenter')
);

const LsGroup = React.lazy(() => import('../components/Group/LsGroup'));

const LsOrganization = React.lazy(() =>
  import('../components/Organization/NlsOrganization')
);

const LsCompany = React.lazy(() => import('../components/Company/LsCompany'));

const LsDepartment = React.lazy(() =>
  import('../components/Department/LsDepartment')
);

const LsLocation = React.lazy(() =>
  import('../components/Location/LsLocation')
);

const LsIncidentDashboard = React.lazy(() =>
  import('../components/IntegrationManagement/Dashboard/Dashboard')
);
const LsSummary = React.lazy(() =>
  import('../components/IntegrationManagement/ProcessingSummary')
);
const LsTrackTickets = React.lazy(() =>
  import('../components/IntegrationManagement/TrackTickets')
);
const LsColorPalettes = React.lazy(() =>
  import('../components/Settings/Configurator/UiConfigurator')
);
const LsColorPalettesUser = React.lazy(() =>
  import('../components/UserThemeSettings/Configurator/UiConfigurator')
);
const LsGovernanceConsole = React.lazy(() =>
  import('components/GovernanceMeeting/console')
);
const addEditForums = React.lazy(() =>
  import('components/GovernanceMeeting/console/forums/addEditForums')
);

const addEditCommitee = React.lazy(() =>
  import('components/GovernanceMeeting/console/committees/add-edit')
);

const LsForum = React.lazy(() =>
  import('components/GovernanceMeeting/console/forums/list')
);

const LsCommittee = React.lazy(() =>
  import('components/GovernanceMeeting/console/committees/list')
);

const LsProblemManagement = React.lazy(() =>
  import('components/Problem-managment/listing')
);

const AddEditProblemManagement = React.lazy(() =>
  import('components/Problem-managment/add-edit')
);

const LsPMTask = React.lazy(() =>
  import('components/Problem-managment/problem-task/listing')
);

const LsCabDashboard = React.lazy(() =>
  import('components/Change/cab-dashboard/listing')
);
const LsSupplierScorecard = React.lazy(() => import('components/ScoreCard'));
const SupplierScoreCard = React.lazy(() =>
  import('components/ScoreCard/consolidated-view')
);

const LsConsolidatedView = React.lazy(() =>
  import('components/ScoreCard/consolidated-view')
);

const LsPerspectiveView = React.lazy(() =>
  import('components/ScoreCard/perspective-view')
);
const UploadUsers = React.lazy(() => import('config/UploadUsers'));

function withLayout(WrappedComponent, themeName = 'blue-white') {
  // ...and returns another component...

  return class extends React.Component {
    render() {
      return <>{!themeName ? null : <WrappedComponent></WrappedComponent>}</>;
    }
  };
}

const NoMatch = () => {
  let location = useLocation();

  return (
    <div>
      <h3>
        No match for <code>{location.pathname}</code>
      </h3>
    </div>
  );
};

const components = {
  lsCompany: LsCompany,

  lsOrganization: LsOrganization,

  lsDepartment: LsDepartment,

  lsLocation: LsLocation,

  lsGroup: LsGroup,

  lsCostCenter: LsCostCenter,

  lsUser: LsUser,

  lsHolidaySchedule: LsHolidaySchedule,

  lsTargetDefinition: LsTargetDefinition,

  lsWorkSchedule: LsWorkSchedule,

  lsIncident: LsIncident,

  lsDashboard: LsuserDashboard,

  lsTask: LsTask,

  lsServiceRequest: LsRequest,

  lsServiceRequestItem: LsServiceRequestItem,

  lsChange: LsChange,

  lsChangeTask: LsChangeTasks,

  // lsProblem: LsProblems,
  lsAdministration: LsAdministration,

  lsServiceRequestItemTask: LsServiceRequestItemTask,

  lsIncidentTask: LsIncidentTask,
  lsIncidentTrackList: LsIncidentTrackList,
  lsSummary: LsSummary,
  lsIncidentTracking: LsTrackTickets,
  lsIncidentDashboard: LsIncidentDashboard,
  lsColorPalettes: LsColorPalettes,
  lsMeetingList: ListingMeetingComponent,
  lsActionItems: ListingActionItems,
  lsMeetingCalenderList: LsGovernanceConsole,
  lsGovernanceDashboard: LsGovernanceDashboard,
  lsGovernanceConsole: LsGovernanceConsole,
  lsSupplierPerformance: VmoLsuserDashboard,
  lsForumList: LsForum,
  lsCommittee: LsCommittee,
  lsLandscapeDashboard: LsGovernanceLandScape,
  lsProblem: LsProblemManagement,
  lsProblemTask: LsPMTask,
  lsCABDashboard: LsCabDashboard,
  lsSupplierScorecard: LsSupplierScorecard,
  lsConsolidatedView: LsConsolidatedView,
  lsPerspectiveView: LsPerspectiveView,

  null: NoMatch,

  '': NoMatch,
};

function MainRoutes(leftP, themeName) {
  return (
    <AuthContext.Consumer>
      {(value) =>
        leftP.length !== 0 &&
        leftP.data.panel.length !== 0 &&
        leftP.data.panel.map((route, idx) => {
          return route.modules.map((subroute, subindex) => {
            return subroute.children.length !== 0
              ? Array.from(new Set(subroute.children.map(JSON.stringify)))
                  .map(JSON.parse)
                  .map((subItem, key) => {
                    return (
                      <PrivateRoute
                        exact
                        path={`${
                          route.groupName !== 'null' && route.groupName !== null
                            ? '/' + route.groupName.replace(/\s+/g, '')
                            : ''
                        }/${subroute.name.replace(
                          /\s+/g,
                          ''
                        )}/${subItem.functionName.replace(/\s+/g, '-')}`}
                        component={withLayout(
                          components[subItem.functionComponent],
                          themeName
                        )}
                        key={key}
                      />
                    );
                  })
              : '';
          });
        })
      }
    </AuthContext.Consumer>
  );
}

// const Landing = (props) => {
//   const history = useHistory();
//   const { sidebarData } = useContext(AuthContext);

//   sidebarData.data.panel.map((route, idx) => {
//     return route.modules.map((subroute, subindex) => {
//       console.log(subroute);
//       return (
//         subroute?.children?.length !== 0 &&
//         subroute?.children?.map((subroute1, subindex1) => {
//           if (subroute1.isLanding === 'true' || subroute1.isLanding === true) {
//             return history.push(
//               `/${
//                 route.groupName !== 'null' && route.groupName !== null
//                   ? route.groupName?.replace(/\s+/g, '')
//                   : ''
//               }/${subroute?.name?.replace(
//                 /\s+/g,
//                 ''
//               )}/${subroute1?.functionName?.replace(/\s+/g, '-')}`
//             );
//           }
//         })
//       );
//     });
//   });
//   return null;
// };

const Routes = () => {
  const themeName = useRecoilValue(unifoThemes);

  return (
    <AuthContext.Consumer>
      {(value) => (
        <Router>
          <Main>
            <Suspense fallback={null}>
              <Switch>
                <Route exact path={'/login'} component={Login} />
                <Route exact path={'/logout'} component={LogOut} />
                <Route
                  exact
                  path={'/reset-pass/action'}
                  component={ResetPassword}
                />
                <PrivateRoute
                  exact
                  path={'/'}
                  component={withLayout(Landing)}
                />
                <PrivateRoute
                  exact
                  path={'/terms-of-service'}
                  component={withLayout(TermService)}
                />
                <PrivateRoute
                  exact
                  path={'/AddOrganization/Organization'}
                  component={withLayout(AddEditOrg)}
                />
                <PrivateRoute
                  exact
                  path={'/add-company/Company'}
                  component={withLayout(AddCompany)}
                />
                <PrivateRoute
                  exact
                  path={'/edit-company/:companyId'}
                  component={withLayout(EditCompany)}
                />
                <PrivateRoute
                  exact
                  path={'/add-location/Location'}
                  component={withLayout(AddLocation)}
                />
                <PrivateRoute
                  exact
                  path={'/edit-location/:locationId'}
                  component={withLayout(EditLocation)}
                />
                <PrivateRoute
                  exact
                  path={'/add-group/Group'}
                  component={withLayout(AddGroup)}
                />
                <PrivateRoute
                  exact
                  path={'/edit-group/:groupId'}
                  component={withLayout(EditGroup)}
                />
                <PrivateRoute
                  path={'/AddDepartment/Department'}
                  component={withLayout(AddEditDept)}
                />
                <PrivateRoute
                  path={'/edit-organization/:compId/:orgId'}
                  component={withLayout(AddEditOrg)}
                />
                <PrivateRoute
                  path={'/edit-department/:compId/:deptId'}
                  component={withLayout(AddEditDept)}
                />
                <PrivateRoute
                  path={'/add-user/User'}
                  component={withLayout(AddEditUser)}
                />
                <PrivateRoute
                  path={'/edit-user/:compId/:editUserId'}
                  component={withLayout(AddEditUser)}
                  name="User"
                />
                <PrivateRoute
                  path={'/add-cc/CostCenter'}
                  component={withLayout(AddEditCC)}
                />{' '}
                <PrivateRoute
                  path={'/edit-CostCenter/:compId/:costId'}
                  component={withLayout(AddEditCC)}
                />
                <PrivateRoute
                  path={'/profile/my-profile'}
                  component={withLayout(MyProfile)}
                />
                <PrivateRoute
                  path={'/addedit/workday-schedule'}
                  component={withLayout(AddEditWs)}
                />
                <PrivateRoute
                  path={'/edit-WorkSchedule/:compId/:scheduleId'}
                  component={withLayout(AddEditWs)}
                />
                <PrivateRoute
                  excat
                  path={'/addedit/Target-Definition'}
                  component={withLayout(AddEditSLA)}
                />
                <PrivateRoute
                  excat
                  path={'/edit/Target-Definition/:compId/:targetId'}
                  component={withLayout(AddEditSLA)}
                />
                <PrivateRoute
                  exact
                  path={'/HolidaySchedule/holiday-schedule'}
                  component={withLayout(AddHolidaySchedule)}
                />
                <PrivateRoute
                  exact
                  path={'/HolidaySchedule/holiday-schedule/:compId/:holidayId'}
                  component={withLayout(AddHolidaySchedule)}
                />
                <PrivateRoute
                  path={'/add-incident/Incident'}
                  component={withLayout(AddIncidentN)}
                />
                <PrivateRoute
                  exact
                  path={'/add-edit/Incident'}
                  component={withLayout(AddIncidentN)}
                />
                <PrivateRoute
                  exact
                  path={'/add-edit/Incident/:breakfixId'}
                  component={withLayout(AddIncidentN)}
                />
                <PrivateRoute
                  exact
                  path={'/edit-task/Task/:taskCode/:statusModuleName'}
                  component={withLayout(EditTask)}
                />
                <PrivateRoute
                  exact
                  path={'/add-edit/request/:id'}
                  component={withLayout(AddEditRequest)}
                />
                <PrivateRoute
                  exact
                  path={'/add-edit/request-item/:requestItemId/:compId'}
                  component={withLayout(AddEditRequestItem)}
                />
                <PrivateRoute
                  exact
                  path={'/add-edit/change/:changeId/:consumerCompanyId'}
                  component={withLayout(AddEditChange)}
                />
                {/* <PrivateRoute
                  exact
                  path={"/settings/configurator"}
                  component={withLayout(UiConfigurator)}
                /> */}
                <PrivateRoute
                  exact
                  path={'/global-search'}
                  component={withLayout(GlobleSearch)}
                />
                <PrivateRoute
                  exact
                  path={'/add-edit/meeting'}
                  component={withLayout(MeetingComponent)}
                />
                <PrivateRoute
                  exact
                  path={'/add-edit/meeting/:meetingId'}
                  component={withLayout(MeetingComponent)}
                />
                <PrivateRoute
                  exact
                  path={'/add-edit/action-item/:actionItemId'}
                  component={withLayout(AddEditActionItem)}
                />
                <PrivateRoute
                  exact
                  path={'/add-edit/action-item'}
                  component={withLayout(AddEditActionItem)}
                />
                <PrivateRoute
                  exact
                  path={'/SIAMGovernance/governance-console'}
                  component={withLayout(LsGovernanceConsole)}
                />
                {/* <PrivateRoute
                  exact
                  path={"/Administration/DataAdministration/Governance-Forum"}
                  component={withLayout(LsForum)}
                /> */}
                <PrivateRoute
                  exact
                  path={'/add-edit/forums'}
                  component={withLayout(addEditForums)}
                />
                <PrivateRoute
                  exact
                  path={'/add-edit/forums/:forumId'}
                  component={withLayout(addEditForums)}
                />
                <PrivateRoute
                  exact
                  path={'/add-edit/committee'}
                  component={withLayout(addEditCommitee)}
                />
                <PrivateRoute
                  exact
                  path={'/add-edit/committee/:commiteeId'}
                  component={withLayout(addEditCommitee)}
                />
                <PrivateRoute
                  exact
                  path={'/userthemesettings/configurator'}
                  component={withLayout(LsColorPalettesUser)}
                />
                <PrivateRoute
                  exact
                  path={'/SIAMGovernance/siam-landscape'}
                  component={withLayout(LsGovernanceLandScape)}
                />
                <PrivateRoute
                  exact
                  path={'/add-edit/problem'}
                  component={withLayout(AddEditProblemManagement)}
                />
                <PrivateRoute
                  exact
                  path={'/add-edit/problem/:problemId'}
                  component={withLayout(AddEditProblemManagement)}
                />
                <PrivateRoute
                  exact
                  path={'/firebase'}
                  component={withLayout(UploadUsers)}
                />
                <PrivateRoute
                  exact
                  path={'/scorecard'}
                  component={withLayout(SupplierScoreCard)}
                />
                <PrivateRoute
                  exact
                  path={'/scorecard1'}
                  component={withLayout(LsPerspectiveView)}
                />
                {MainRoutes(value.sidebarData, themeName)}
              </Switch>
            </Suspense>
          </Main>
        </Router>
      )}
    </AuthContext.Consumer>
  );
};

export default Routes;

const Landing = (props) => {
  const history = useHistory();
  const { sidebarData } = useContext(AuthContext);

  useEffect(() => {
    sidebarData.data.panel.forEach((route) => {
      route.modules.forEach((subroute) => {
        subroute?.children?.forEach((subroute1) => {
          if (subroute1.isLanding || subroute1.isLanding === 'true') {
            history.push(
              `${
                route.groupName !== 'null' && route?.groupName !== null
                  ? `/${route.groupName.replace(/\s+/g, '')}`
                  : ''
              }/${subroute.name.replace(
                /\s+/g,
                ''
              )}/${subroute1.functionName.replace(/\s+/g, '-')}`
            );
          }
        });
      });
    });
  }, [sidebarData.data.panel, history]);

  return null;
};
