import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import calendarService from "components/GovernanceMeeting/console/meeting-calendar/http-services";

const initialState = [];

export const getAllEvents = createAsyncThunk("events/all", async (params) => {
  const res = await calendarService.getAll(params);
  return res.data;
});

export const resetAllCalevents = createAsyncThunk(
  "events/resetallcalevents",
  async () => {
    return [];
  }
);

const eventsSlice = createSlice({
  name: "events",
  initialState,
  extraReducers: {
    [getAllEvents.fulfilled]: (state, action) => {
      return [...action.payload];
    },
    [resetAllCalevents.fulfilled]: (state, action) => {
      return [...action.payload];
    },
  },
});

const { reducer } = eventsSlice;
export default reducer;
