/* eslint-disable jsx-a11y/anchor-is-valid */
import { Collapse } from "reactstrap";
import { NavLink, withRouter } from "react-router-dom";
import Loader from "react-loader-spinner";

export const SearchItems = withRouter(({ value, fnClick, isSearching }) => {
  return isSearching ? (
    <div className="text-center">
      <Loader type="ThreeDots" className="sidebar-loader" height={5} />
    </div>
  ) : (
    <div className="accordion-group">
      {value.map((category, index) => {
        return category.groupName ? (
          <div className="position-relative">
            <a id={`cat_${index}`} className="accordion-header open">
              <i
                className={`fa mr-1 ${category.groupIcon}`}
                aria-hidden="true"
              ></i>
              {category.groupName}
            </a>
            <Collapse className="accordion-body" isOpen defaultOpen>
              {category.modules.map((subcategory, subindex) => {
                return (
                  <div
                    className="position-relative"
                    key={`key_div_${subindex}`}
                  >
                    <p
                      className="accordion-header open"
                      id={`sub_cat_${subindex}_${subcategory.moduleId}`}
                    >
                      <i
                        className={`fa mr-1 ${subcategory.moduleIcon}`}
                        aria-hidden="true"
                      ></i>
                      {subcategory.name}
                    </p>

                    <Collapse className="accordion-body" isOpen>
                      <ul className="last-level">
                        {subcategory.children !== false &&
                          subcategory.children.length !== 0 &&
                          subcategory.children.map(
                            (childrenItems, childerIndex) => {
                              return (
                                <li key={`key_li_${childrenItems.functionId}`}>
                                  <NavLink
                                    activeClassName="active"
                                    data-fid={childrenItems.functionId}
                                    data-fname={childrenItems.functionName}
                                    to={`/${
                                      category.groupName
                                        ? category.groupName.replace(/\s+/g, "")
                                        : ""
                                    }/${subcategory.name.replace(
                                      /\s+/g,
                                      ""
                                    )}/${childrenItems.functionName.replace(
                                      /\s+/g,
                                      "-"
                                    )}`}
                                    onClick={() => {
                                      fnClick(
                                        childrenItems.functionId,
                                        childrenItems.functionName
                                      );
                                    }}
                                  >
                                    <i className="mini-icon mr-1 fa fa-dot-circle-o"></i>
                                    <span className="flex-grow-1">
                                      {childrenItems.functionName}
                                    </span>
                                  </NavLink>
                                </li>
                              );
                            }
                          )}
                      </ul>
                    </Collapse>
                  </div>
                );
              })}
            </Collapse>
          </div>
        ) : (
          category.modules.map((modulecategory, modindex) => {
            return (
              <div className="position-relative">
                <a
                  className="accordion-header open"
                  id={`cat_${modulecategory.moduleId}_${Number(
                    modindex + 99990
                  )}`}
                >
                  <i
                    className={`fa mr-1 ${modulecategory.moduleIcon}`}
                    aria-hidden="true"
                  ></i>
                  {modulecategory.name}
                </a>
                <Collapse isOpen className="accordion-body">
                  <ul className="last-level">
                    {modulecategory.children !== false &&
                      modulecategory.children.length !== 0 &&
                      modulecategory.children.map(
                        (childrenItems, childerIndex) => {
                          return (
                            <li>
                              <NavLink
                                className="d-flex"
                                activeClassName="active"
                                data-fid={childrenItems.functionId}
                                data-fname={childrenItems.functionName}
                                to={`/${modulecategory.name.replace(
                                  /\s+/g,
                                  ""
                                )}/${childrenItems.functionName.replace(
                                  /\s+/g,
                                  "-"
                                )}`}
                                onClick={(e) => {
                                  fnClick(
                                    childrenItems.functionId,
                                    childrenItems.functionName
                                  );
                                }}
                              >
                                <i className="mini-icon mr-1 fa fa-dot-circle-o"></i>
                                <span className="flex-grow-1">
                                  {childrenItems.functionName}
                                </span>

                                {/* <AddtoFavWidget
                                      functionId={childrenItems.functionId}
                                      isfav={childrenItems.isFav}
                                      mid={modulecategory.moduleId}
                                      keys={`all${childrenItems.functionId}`}
                                      userId={userInfo.user_id}
                                      saveFav={saveFav}
                                      isFvLoading={isFvLoading}
                                      index={modindex}
                                      subindex={childerIndex}
                                      tabId="all"
                                    /> */}
                              </NavLink>
                            </li>
                          );
                        }
                      )}
                  </ul>
                </Collapse>
              </div>
            );
          })
        );
      })}
    </div>
  );
});
