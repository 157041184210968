const _menuActionItems = (compId, fieldName) =>
  `rest/menuitemAction?tenantId=${compId}&module=breakfix&field1Name=${fieldName}`;
const _saveActivity = (compId) => `rest/activityAction?tenantId=${compId}`;
const _ddMenus = (fName, compId) =>
  `rest/menuitemAction?field1Name=${fName}&module=Breakfix&tenantId=${compId}`;
const _statusProgress = (compId, itemId, order) =>
  `rest/genericTimelineAction?tenantId=${compId}&module=Breakfix&itemId=${itemId}&orderBy=createdOn&sortBy=${order}`;
const _cimgroupCIM = (roleId) =>
  `foundation/api/getGroupListFilter?role=${roleId}`;
const _getAttachmentById = (Id, compId, order) =>
  `rest/downloadImageWithTempURLAction?itemId=${Id}&tenantId=${compId}&order=${order}&module=breakfix`;
const _attachment = (moduleId, companyId, identificationTypeId) =>
  `api/common/uploadFile/${moduleId}/${companyId}/${identificationTypeId}`;
const _externalSys = (itemId, compId, moduleName) =>
  `rest/getincidentAtributeAction?itemId=${itemId}&module=${moduleName}&tenantId=${compId}`;
const _saveRelation = (tenantId) =>
  `rest/saveRelateAllAction?tenantId=${tenantId}`;
const _relatedconfigItems = (tenantId, moduleName, itemId) =>
  `rest/relationsAction?tenantId=${tenantId}&workModule=${moduleName}&itemId=${itemId}&orderBy=desc&sortBy=createdOn`;
const _menuAction_Item = (field1Name, module, tenantId) =>
  `rest/menuitemAction?field1Name=${field1Name}&module=${module}&tenantId=${tenantId}`;
const _searchPostDataWorkItem = (tenantId) =>
  `rest/breakfixQuickViewAction/search?tenantId=${tenantId}`;
const _delteRelationItem = (itemId, tenantId) =>
  `rest/relationsAction/${itemId}?tenantId=${tenantId}`;
const _deleteAttachment = (attachId, tenantId) =>
  `rest/attachmentStatusUpdateAction/${attachId}?status=D&tenantId=${tenantId} `;
const _assoCompanyList = (userId) =>
  `foundation/api/getUserAssociateCompany?userId=${userId}`;
//const getallgroups = () => "foundation/api/getAssociatedCompanyGroups";
// const break_fixPatch = (breakfixNumber, companyId) =>
//   `rest/breakfixAction/${breakfixNumber}?tenantId=${companyId}`;
const _downloadWorkattachment = (itemId, tenantId, activityId) =>
  `rest/getDownloadWorknoteAttachement/?itemId=${itemId}&tenantId=${tenantId}&module=Breakfix&activityId=${activityId}`;
const _getEmailOptons = (modulen, field1Name, tenantId) =>
  `rest/menuitemAction?module=${modulen}&field1Name=${field1Name}&tenantId=${tenantId}`;
const _getexternalInfo = (tenantId, itemId) =>
  `rest/getincidentAttributeList?module=Breakfix&tenantId=${tenantId}&isCore=y&itemId=${itemId}`;
const _getNotification = (itemNumber, compId, sortBy) =>
  `notification/api/getNotificationById/${itemNumber}?companyId=${compId}&eventType=Adhoc Notify&sortBy=${sortBy}`;
const _getNotificationNew = (itemId, tenantId) =>
  `rest/getNotByItemId/${itemId}?activityType=email&tenantId=${tenantId}`;
const _getBreakfixnolist = (searchBy, itemId, tenantId) =>
  `rest/getbreakfixNoList?searchBy=${searchBy}&itemId=${itemId}&limit=100&tenantId=${tenantId}`;
const _getnotificationbyId = (companyId, itemId, activityId) =>
  `notification/api/getNotificationActivity?companyId=${companyId}&itemId=${itemId}&activityId=${activityId}`;
const _config_cmdb_id = (Id) => `cmdb/api/config_itemsById/${Id}`;
const _tasklist = (incnumber) =>
  `task/api/getTasks?filter[where][parentWorkItemId]=${incnumber}`;
//const _updatetsk = (tskid) =>
//`task/api/updateTask?filter=[where][taskCode]=${tskid}`;
const _updatetsk = (tskid) =>
  `task/api/updateTask?filter={"where":{"taskCode":"${tskid}"}}`;

const _taskpristautus = (type) =>
  `foundation/api/getMenuListData?mod=Task&fld=${type}`;

const _menuactions = (type, companyId) =>
  `rest/menuitemAction?field1Name=${type}&module=Breakfix&tenantId=${companyId}`;
const _opcategory = (impCompID) =>
  `rest/get-op-catg-field/${impCompID}?tenantId=${impCompID}&moduleName=Breakfix&catStatus=1`;
const _taskAttachment = (taskNo) => `task/api/getTaskAttachment/${taskNo}`;
const _saveAttachment = () => `task/api/saveTaskAttachment`;
const _deletetaskattachment = (id) => `task/api/deleteTaskAttachment/${id}`;
const _updatevariable = (id) => `task/api/updateTaskOutput/${id}`;
const _deleteVariable = (id) => `task/api/taskOutputs/${id}`;
const _getOutputList = (taskCode) =>
  `task/api/getTaskOutput?filter={"where":{"taskCode":"${taskCode}"}}`;
const _getTaskStatusTimelines = (taskCode) =>
  `task/api/getTaskAuditLog?filter={"where":{"keyValue":"${taskCode}", "fieldName":"status"},"order":"updatedOn desc"}`;
const _getworknoteattachment = (taskCode, activityId) =>
  `task/api/workNoteStorage/attachments/${taskCode}/activityId/${activityId}`;
const _save_worknote_attachment = (taskCode) =>
  `task/api/saveWorknoteAttachment/${taskCode}`;

module.exports = {
  // breakfixAction: break_fixPatch,
  menuActionItems: _menuActionItems,
  saveActivity: _saveActivity,
  ddMenus: _ddMenus,
  statusProgress: _statusProgress,
  cimgroupCIM: _cimgroupCIM,
  getallgroups: 'foundation/api/getAssociatedCompanyGroups',
  getAttachmentsById: _getAttachmentById,
  uploadfile: _attachment,
  externalSys: _externalSys,
  postRelation: _saveRelation,
  getRelatedItems: _relatedconfigItems,
  menu_Action_Items: _menuAction_Item,
  serachWorkItems: _searchPostDataWorkItem,
  delteRelation: _delteRelationItem,
  deleteAttachment: _deleteAttachment,
  assoCompList: _assoCompanyList,
  downloadworknote: _downloadWorkattachment,
  getSendEmailOptions: _getEmailOptons,
  getallSelectedgroups: 'foundation/api/getAllGroups?status=1',
  getexternalInfo: _getexternalInfo,
  getNotificationById: _getNotification,
  getNotificationByIdNew: _getNotificationNew,
  getbreakfixnoList: _getBreakfixnolist,
  getnotificationbyId: _getnotificationbyId,
  cmdb_config: _config_cmdb_id,
  tasklist: _tasklist,
  taskapi: 'task/api/getTasks',
  worknote: 'task/api/getActivityLogs',
  saveworknote: 'task/api/saveActivityLogs',
  updateTask: _updatetsk,
  taskauditlog: 'task/api/getTaskAuditLog',
  taskoutput: 'task/api/getTaskOutput',
  savetaskoutput: 'task/api/saveTaskOutput',
  statusandpriority: _taskpristautus,
  ddmenuactions: _menuactions,
  opcategory: _opcategory,
  task_attachemtns: _taskAttachment,
  save_task_attachment: _saveAttachment,
  save_worknote_attachment: _save_worknote_attachment,
  delte_task_attachment: _deletetaskattachment,
  updatevariable: _updatevariable,
  createtasks: 'task/api/createtasks',
  input_variable: 'task/api/tasks/fetchOutputVariables',
  delete_Variable: _deleteVariable,
  getOutputList: _getOutputList,
  getTaskStatusTimelines: _getTaskStatusTimelines,
  getTaskStatus: 'task/api/getStatusRules',
  getworknoteattachment: _getworknoteattachment,
  tasksChangeDashboard: 'task/api/tasksChangeDashboard',
};
