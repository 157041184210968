import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  forumName: "",
  forumId: "",
  suppliersName: "",
  tenantId: "",
  customerName: "",
  companyName: "",
  regionName: "",
  sortedBy: "asc",
  month: "",
  year: "",
  date: "",
  category: "",
  statusId: "",
  committeeName: "",
  occuranceId: "",
};

const consoleFilterSlice = createSlice({
  name: "consolefilters",
  initialState,
  reducers: {
    filtersObj: (state, action) => {
      state = { ...state, ...action.payload };
    },
  },
});

export const { filtersObj } = consoleFilterSlice.actions;
export default consoleFilterSlice.reducer;
