import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Get } from 'utils/AxiosUtils';

import {
  GetCompanyByUserID,
  GetProviderList,
  _permissions,
  getMirQuestions,
  getPirQuestions,
  getQuestionAndAnswer,
} from 'utils/EndPoints';

const initialState = {
  companyList: [],
  vernderList: [],
  defaultCompany: [],
  providerList: [],
  permissions: [],
  permissionsList: [],
  mirQuesData: [],
  pirQuesData: [],
  pirLoading: true,
  pirAnsLoading: true,
  pirAnsData: [],
  sidebarLinkClicked: [],
  status: 'idle',
  error: null,
};

export const fetchPermissions = createAsyncThunk(
  'common/fetchPermissions',
  async ({ fName, fId, companyID }) => {
    const response = await Get(
      _permissions + `?functionId=${fId}&functionName=${fName}`,
      companyID
    );
    return response.data;
  }
);

export const fetchViewAssociatedCompanyToUser = createAsyncThunk(
  'common/fetchViewAssociatedCompanyToUser',
  async ({ userId, companyID }) => {
    const response = await Get(GetCompanyByUserID + `${userId}`, companyID);
    return response.data;
  }
);

export const fetchProviderList = createAsyncThunk(
  'common/fetchProviderList',
  async ({ compID }) => {
    const response = await Get(
      GetProviderList + `?companyId=${compID}`,
      compID
    );
    return response.data;
  }
);

export const fetchMirQuesData = createAsyncThunk(
  'common/fetchMirQuesData',
  async ({ impCompID, priorityId, brkFixNum }) => {
    const response = await Get(
      getMirQuestions +
        `?itemNumber=${brkFixNum}&tenantId=${impCompID}&module=Breakfix&typeId=${priorityId}`,
      impCompID
    );
    return response.data;
  }
);

export const fetchPirQuesData = createAsyncThunk(
  'common/fetchPirQuesData',
  async ({ impCompID, module, typeId }) => {
    const response = await Get(
      getPirQuestions +
        `?customer=${impCompID}&module=${module}&typeId=${typeId}`,
      impCompID
    );
    return response.data;
  }
);

export const fetchPirAnsData = createAsyncThunk(
  'common/fetchPirAnsData',
  async ({ impCompID, changeNumber }) => {
    const response = await Get(
      getQuestionAndAnswer + `?changeNumber=${changeNumber}`,
      impCompID
    );
    return response.data;
  }
);

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setVernderList: (state, action) => {
      state.vernderList = action.payload;
    },
    setSidebarClickedLink: (state, action) => {
      state.sidebarLinkClicked = action.payload;
    },
    setPermissionsList: (state, action) => {
      state.permissionsList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchViewAssociatedCompanyToUser.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.companyList = action.payload?.companyList;
        state.defaultCompany = action?.payload?.defaultCompany;
      })
      .addCase(fetchViewAssociatedCompanyToUser.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchPermissions.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.permissions = action?.payload?.data;
      })
      .addCase(fetchPermissions.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action?.error?.message;
      })
      .addCase(fetchProviderList.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.providerList = action.payload?.data;
      })
      .addCase(fetchProviderList.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchMirQuesData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.mirQuesData = action.payload;
      })
      .addCase(fetchMirQuesData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchPirQuesData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.pirLoading = false;
        state.pirQuesData = action.payload;
      })
      .addCase(fetchPirQuesData.rejected, (state, action) => {
        state.status = 'failed';
        state.pirLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchPirAnsData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.pirLoading = false;
        state.pirAnsData = action.payload;
      })
      .addCase(fetchPirAnsData.rejected, (state, action) => {
        state.status = 'failed';
        state.pirAnsLoading = false;
        state.error = action.error.message;
      });
  },
});

export const { setVernderList, setSidebarClickedLink, setPermissionsList } =
  commonSlice.actions;

export default commonSlice.reducer;
