import React, { useContext, useState, useCallback } from 'react';
import { useLocation, useParams, useHistory } from 'react-router-dom';
import { Layout } from 'antd';
import { useIdleTimer } from 'react-idle-timer';

import { useAuth, AuthContext } from 'contexts/AuthContext';

import Sidebar from 'components/Sidebar/nested-sidebar/Sidebar';
import NavbarApp from 'components/Navbar/MenuBar';
import FooterApp from 'components/Footer/Footer';
import { useRecoilValue } from 'recoil';
import { miniOverlay, fncDetails, showHideSettings } from 'config/selector';
import Settings from 'components/Settings/Settings';

function Main({ children }) {
  const { signOut, sidebarData } = useAuth();
  const { currentUser } = useContext(AuthContext);
  const history = useHistory();
  const overlayDiv = useRecoilValue(miniOverlay);
  const functionName = useRecoilValue(fncDetails);
  const settingsActive = useRecoilValue(showHideSettings);
  const location = useLocation();

  const [isIdle, setIsIdle] = useState(false);

  const userInfo = sidebarData.data;

  async function handleLogout() {
    //Get(_Logout(userInfo?.user_id));
    await signOut();
    history.push('/login');
  }

  useIdleTimer({
    timeout: 1000 * 60 * 30,
    onIdle: handleLogout,
  });

  const { Footer } = Layout;

  let params = useParams();
  const hasparams =
    Object.keys(params).length === 0 && params.constructor === Object;
  //  //route.split("-").map((word) => word[0].toUpperCase() + word.slice(1))
  const title =
    location.pathname === '/'
      ? '/DataAdministration1/Company'
      : location.pathname;

  const PageTitle = hasparams
    ? title
        .split('/')
        .slice(2)
        .map((route) =>
          route
            .split('-')
            .join(' ')
            .replace(/\w\S*/g, function (txt) {
              return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            })
        )
    : title
        .split('/')
        .slice(1, 2)
        .map((route) =>
          route
            .split('-')
            .slice(1, 2)
            .map((word) => word[0].toUpperCase() + word.slice(1))
        );

  const returnPageTitle = useCallback(
    (pageTitle) => {
      if (pageTitle?.includes('Incident')) {
        return 'Incidents';
      }
      if (pageTitle?.includes('Request')) {
        return 'Requests';
      }
      if (pageTitle?.includes('Request Item')) {
        return 'Requested Items';
      }
      if (pageTitle?.includes('Task')) {
        return 'Tasks';
      }
      if (pageTitle?.includes('Change')) {
        return 'Changes';
      }
      if (pageTitle?.includes('Problem')) {
        return 'Problems';
      }
      if (pageTitle?.includes('Meeting') || pageTitle?.includes('meeting')) {
        return 'Meetings';
      }
      if (
        pageTitle?.includes('action-item') ||
        pageTitle?.includes('Action Item')
      ) {
        return 'Action Items';
      }
      if (pageTitle?.includes('Consolidated')) {
        return 'Consolidated View';
      }

      return pageTitle;
    },
    [PageTitle]
  );

  return (
    <>
      {currentUser !== null ? (
        <React.Fragment>
          <NavbarApp />
          <div className="wrapper">
            <Sidebar />
            <div id="content">
              <div
                className="row wrapper  page-heading"
                id="pageheadingForcommon"
              >
                <div className="col-sm-7 ">
                  <span className="m-heading-top">
                    {PageTitle?.includes('My Profile')
                      ? 'My Profile'
                      : functionName['name'] !== ''
                      ? functionName['name']
                      : returnPageTitle(PageTitle)}
                  </span>
                </div>
              </div>
              {children}
              <Footer
                style={{ padding: 0, background: 'none', color: '#676a6c' }}
              >
                <FooterApp />
              </Footer>
            </div>
          </div>
          {overlayDiv && <div className="miniOvrlay"></div>}
          <div>
            <Settings />
          </div>
          <div className={settingsActive ? 'overlay active' : 'overlay'}></div>
        </React.Fragment>
      ) : (
        children
      )}
    </>
  );
}

export default Main;
