import { createSlice } from "@reduxjs/toolkit";
import {
  forumFilters,
  committeeFilters,
  eventsFilters,
} from "components/GovernanceMeeting/console/filterConstants";

const initialState1 = {
  forumName: "",
  forumId: "",
  suppliersName: "",
  tenantId: "",
  customerName: "",
  regionName: "",
  sortedBy: "asc",
  month: "",
  year: "",
  committeeName: "",
  occuranceId: "",
};

const initialState = {
  forumFilter: forumFilters,
  committeeFilter: committeeFilters,
  evetnsFilter: eventsFilters,
  allFilters: {
    forumName: "",
    forumId: "",
    suppliersName: "",
    tenantId: "",
    customerName: "",
    regionName: "",
    sortedBy: "asc",
    month: "",
    year: "",
    committeeName: "",
    occuranceId: "",
  },
};

const filterSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    getForumFilters: (state, action) => {
      state.forumFilter = { ...state.forumFilter, ...action.payload };
    },
    getCommiteeFilters: (state, action) => {
      state.committeeFilter = { ...state.committeeFilter, ...action.payload };
    },
    getEventsFilters: (state, action) => {
      state.evetnsFilter = { ...state.evetnsFilter, ...action.payload };
    },
    consoleFilters: (state, action) => {
      state.allFilters = { ...state.allFilters, ...action.payload };
    },
    getconsoleFilters: (state, action) => {
      return {
        ...state.allFilters,
      };
    },
  },
});

export const { consoleFilters, getconsoleFilters } = filterSlice.actions;
export default filterSlice.reducer;
