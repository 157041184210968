/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react';
import { Collapse } from 'reactstrap';
import Loader from 'react-loader-spinner';
import { NavLink, withRouter } from 'react-router-dom';

export const FavouritesData = withRouter((props) => {
  const {
    value,
    // collapse,
    expandMenu,
    index,
    isSidebarLoading,
    isloadingFilterFav,
    fnClick,
  } = props;

  const [collapse, setCollapse] = useState(0);

  const toggle1 = (id) => {
    setCollapse(collapse === id ? 0 : id);
    // open1 === id ? setOpen1("") : setOpen1(id);
  };

  return !isloadingFilterFav && value
    ? value?.data?.Favorites?.map((modulecategory, modindex) => {
        return (
          <div
            className="position-relative"
            key={`key_cat_${Number(modindex + 99990)}`}
          >
            <a
              className={
                collapse === `cat_${Number(modindex + 99990)}`
                  ? 'accordion-header open'
                  : 'accordion-header'
              }
              id={`cat_${Number(modindex + 99990)}`}
              onClick={(_e) => {
                _e.preventDefault();
                toggle1(`cat_${Number(modindex + 99990)}`);
                expandMenu(
                  modulecategory?.moduleId,
                  index,
                  modulecategory?.groupName
                );
              }}
            >
              <i
                className={`fa mr-1 ${modulecategory.moduleIcon}`}
                aria-hidden="true"
              ></i>
              {modulecategory.name}
            </a>

            <Collapse
              className="accordion-body"
              isOpen={collapse === `cat_${Number(modindex + 99990)}`}
            >
              {isSidebarLoading &&
                collapse === `cat_${Number(modindex + 99990)}` && (
                  <ul className="last-level">
                    <li className="text-center">
                      <Loader
                        type="ThreeDots"
                        className="sidebar-loader"
                        height={5}
                      />
                    </li>
                  </ul>
                )}
              <ul className="last-level">
                {modulecategory.children &&
                  modulecategory.children.length !== 0 &&
                  modulecategory.children.map((subroute, subindex) => {
                    return (
                      <li key={`key_fav_${subindex}_123`}>
                        <NavLink
                          className="d-flex"
                          activeClassName="active"
                          data-fid={subroute.functionId}
                          data-fname={subroute.functionName}
                          to={`${
                            modulecategory.groupName !== 'null' &&
                            modulecategory.groupName
                              ? '/' +
                                modulecategory.groupName.replace(/\s+/g, '')
                              : ''
                          }/${modulecategory?.name?.replace(
                            /\s+/g,
                            ''
                          )}/${subroute?.functionName.replace(/\s+/g, '-')}`}
                          onClick={() => {
                            fnClick(
                              subroute?.functionId,
                              subroute?.functionName
                            );
                          }}
                        >
                          <i className="mini-icon mr-1 fa fa-dot-circle-o"></i>
                          <span className="flex-grow-1">
                            {subroute.functionName}
                          </span>
                          <span
                            key={props.keys}
                            onClick={props.toggleinFav}
                            className="favourite-icon mr-1 filled"
                          ></span>
                          {/* <AddtoFavWidget
                            functionId={subroute.functionId}
                            isfav={subroute.isFav}
                            mid={modulecategory.moduleId}
                            keys={`all${subroute.functionId}`}
                            userId={userInfo.user_id}
                            saveFav={saveFav}
                            isFvLoading={isFvLoading}
                            index={modindex}
                            subindex={subindex}
                            tabId="all"
                          /> */}
                        </NavLink>
                      </li>
                    );
                  })}
              </ul>
            </Collapse>
          </div>
        );
      })
    : '';
});
