import { createSlice } from "@reduxjs/toolkit";
import { Get, Post } from "../../utils/AxiosUtils";
import { tasklist, createtasks } from "../../utils/IncidentEndpoints";

export const initialState = {
  loading: false,
  hasErrors: false,
  tasks: [],
  createtaskloading: false,
};

// A slice for tasks with our three reducers
const tasksSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    getTasks: (state) => {
      state.loading = true;
    },
    getTasksSuccess: (state, { payload }) => {
      state.tasks = payload;
      state.loading = false;
      state.hasErrors = false;
    },
    getTasksFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    addTasks: (state) => {
      state.createtaskloading = true;
    },
    addTasksSuccess: (state, { payload }) => {
      state.createtaskloading = false;
      state.hasErrors = false;
    },
  },
});
// Three actions generated from the slice
export const {
  getTasks,
  getTasksSuccess,
  getTasksFailure,
  addTasks,
  addTasksSuccess,
} = tasksSlice.actions;

// A selector
export const tasksSelector = (state) => state.tasks;

// The reducer
export default tasksSlice.reducer;

// Asynchronous thunk action
export function fetchTasks(id, sorttype, companyId) {
  return async (dispatch) => {
    dispatch(getTasks());
    try {
      const response = await Get(
        `${tasklist(
          id
        )}&filter[order]=${sorttype}&filter[include][status]&filter[include][priorityDetails]&filter[include][impactDetails]&filter[include][urgencyDetails]&filter[include][companyDetails]`,
        companyId
      );
      dispatch(getTasksSuccess(response["data"]));
    } catch (error) {
      dispatch(getTasksFailure());
    }
  };
}

export function createTasks(
  postData,
  compId,
  hideTaskForm,
  setSubmitting,
  taskId
) {
  console.log(taskId);
  return async (dispatch) => {
    dispatch(addTasks());
    try {
      await Post(createtasks, postData, compId).then((response) => {
        dispatch(addTasksSuccess(response["data"]));
        hideTaskForm();
        setSubmitting();
        dispatch(fetchTasks(taskId, `createdDate desc`));
      });
    } catch (error) {
      dispatch(getTasksFailure());
    }
  };
}
