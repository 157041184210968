const _getUserByCompany = (companyId, userName) =>
  `foundation/api/getUserOnCompanyBasis?userName=${encodeURIComponent(
    userName
  )}&companyId=${companyId}`;
const _getUserByCompanyforUser = (companyId, userName, userId) =>
  `foundation/api/getUserOnCompanyBasis?userName=${encodeURIComponent(
    userName
  )}&companyId=${companyId}&userId=${userId}`;
const _geOrgbyId = (orgId) => `foundation/api/getOrganizationById/${orgId}`;
const _geDeptbyId = (deptId) => `foundation/api/getDepartmentById/${deptId}`;
const _getLocationStateByCountryId = (contryId) =>
  `foundation/api/getLocationStateByCountryId/${contryId}`;

const _getLocationCityByStateId = (stateId) =>
  `foundation/api/getLocationCityByStateId/${stateId}`;

const _getDeptByOrgId = (orgId) =>
  `foundation/api/getDepartmentByOrgId/${orgId}`;

const _getUserById = (userId) => `foundation/api/getUser/${userId}`;
const _getCostCById = (costCenterId) =>
  `foundation/api/getCostCenterById/${costCenterId}`;
const _updloadProfilepic = (userId) =>
  `foundation/api/UploadUserFile/${userId}`;
const _auditHistory = (mod, sourceId, compId) =>
  `foundation/api/getAuditLog?mod=${mod}&sourceId=${sourceId}&companyId=${compId}`;
const _updateWs = (wsId) => `sla/api/updateWorkdaySchedule/${wsId}`;
const break_fixPatch = (breakfixNumber, companyId) =>
  `rest/breakfixAction/${breakfixNumber}?tenantId=${companyId}`;

const _assoCompany_List = (userId) =>
  `foundation/api/getUserAssociateCompany?userId=${userId}`;
const _updateTask = (tskid) =>
  `task/api/updateTask?filter={"where":{"taskCode":"${tskid}"}}`;
const _integrationProcessingSummary = (companyName, fromDate, toDate) =>
  `im/getProcessingSummary/${companyName}/${fromDate}/${toDate}`;
const _integrationProcessingFailures = (companyName, fromDate, toDate) =>
  `im/getProcessingFailures/${companyName}/${fromDate}/${toDate}`;
const _integrationMonthlyTrends = (companyName) =>
  `im/getIncBymonth/${companyName}`;
const navSearch = (srchParam) =>
  `foundation/api/module/searchNavigation/${srchParam}`;

///occurananceMeetingDetailsByDefinationId/:definationId/:pageNumber/:pageSize?queryString

const occuranceList = (definationId, pageNumber, pageSize, queryString) =>
  `occurananceMeetingDetailsByDefinationId/${definationId}/${pageNumber}/${pageSize}?${queryString}`;

const documentList = (id, meetingType, tenantId, orderby) =>
  `governance/rest/attachments?id=${id}&meetingType=${meetingType}&tenantId=${tenantId}&sortOrder=${orderby}`;

const addMeetingAttachment = (moduleId, tenantId) =>
  `governance/attachment/${moduleId}?tenantId=${tenantId}`;

const occuranceDetails = (occurananceId, tenantId) =>
  `governance/rest/occurananceMeetingDetailsByOccurananceId/${occurananceId}?tenantId=${tenantId}`;

const _actionItemList = (workItemType, workItemId, orderby, impCompID) =>
  `actionitem/api/getActionItems?filter[where][workItemType]=${workItemType}&filter[where][workItemId]=${workItemId}&filter[where][companyId]=${impCompID}&filter[include][]=performers&filter[order]=createdDate ${orderby}`;
const _actionItemListOccurance = (
  workItemType,
  workItemId,
  orderby,
  impCompID
) =>
  `actionitem/api/getActionItems?filter{where: {or: [${workItemType}, Minutes]}}&filter[where][workItemId]=${workItemId}&filter[where][companyId]=${impCompID}&filter[include][]=performers&filter[include][]=priorityDetails&filter[order]=createdDate ${orderby}`;
const _getActionDetails = (query) => `actionitem/api/getActionItems?${query}`;

const _saveAttachmentOccurance = (moduleId, actionitemCode) =>
  `actionitem/api/occurance/saveAttachment/${moduleId}/${actionitemCode}`;

const _saveAttachmentActionItemDef = (moduleId, actionitemCode) =>
  `actionitem/api/defination/saveAttachment/${moduleId}/${actionitemCode}`;

const _getActionItemsAttachments = (actionitemCode, companyId, orderby) =>
  `actionitem/occurance/Attachments/${actionitemCode}/${companyId}?sortField=createdOn&sortDirection=${orderby}`;

const _getOccurrencesActionItemNotes = (actionitemCode, companyId) =>
  `actionitem/occurance/worknoteAttachments/${actionitemCode}/${companyId}`;

const _saveWorkNotesOccurrenceActionItem = (moduleId, actionitemCode) =>
  `actionitem/api/occurance/saveWorknoteAttachment/${moduleId}/${actionitemCode}`;

const _getActionItemsDefAttachments = (actionitemCode, companyId) =>
  `actionitem/defination/Attachments/${actionitemCode}/${companyId}`;

const _getTracking = (occurananceId, queryString) =>
  `governance/rest/trackingDetails/${occurananceId}${queryString}`;

const _getActionStatus = (statusId) =>
  `actionitem/action/status-rules/${statusId}`;

const getassoCompanyLandScape = (serviceProviderIds, companyId, sortedBy) =>
  `foundation/api/landscape/getCompanyAndAssociatedActiveCompany?serviceProviderIds=${serviceProviderIds}&companyId=${companyId}&order=${sortedBy}`;

const _getMinutes = (occurrenceId, tenantId, order, sortedBy) =>
  `governance/rest/getMinutesDetails?occurrenceId=${occurrenceId}&tenantId=${tenantId}&sortOrder=${order}&sortedByParameter=${sortedBy}`;
const _deleteMenutes = (query) =>
  `governance/rest/deleteMinutesDetails${query}`;

const _getLocationByCId = (Id) =>
  `foundation/api/location/locationByCompanyId?companyId=${Id}`;

const _getLandscapeDocuments = (companyId, serviceProviderIds) => {
  const queryParams = serviceProviderIds
    ? `&serviceProviderIds=${serviceProviderIds}`
    : '';
  return `foundation/api/landscape/getLandscapeDocuments?companyId=${companyId}${queryParams}`;
};

const _getSlaByServiceProviderIds = (companyId, serviceProviderIds) => {
  return `sla/api/getSlaByServiceProviderIds?customer=${companyId}&serviceProviderIds=${serviceProviderIds}`;
};

const getOpCatfiledpm = (catId) => `rest/pm/ui/get-op-catg-field/${catId}`;

const getPMAction = (problemId) => `rest/pm/ui/getPMAction/${problemId}`;
const getStatusTimeline = (problemId) =>
  `rest/pm/ui/getStatusTimeline/${problemId}`;

module.exports = {
  _Logout: (id) => `logout?userId=${id}`,
  //AllCompanyList: "foundation/api/getAllCompanies",
  AllCompanyList: 'foundation/api/getCompaniesWithPagination',
  CreateCompany: 'foundation/api/CreateCompany',
  EditCompanyApi: 'foundation/api/EditCompany',
  GetCompanyById: 'foundation/api/getCompanyById',
  SaveCompanyLogo: 'foundation/api/UploadFile',
  GetCompanyName: 'foundation/api/getCompanyByName',
  CompanyAssociationApi: 'foundation/api/createCompanyAssociation',
  CompanyDisassociationApi: 'foundation/api/createCompanyDissociation',
  // AllOrganizationList: "foundation/api/getAllOrganizations",
  AllOrganizationList: 'foundation/api/getOrgFilter',
  AllLocationListAndFilter: 'foundation/api/getLocationListFilter',
  AllLocationList: 'foundation/api/getActiveLocations',
  //AllDepartmentList: "foundation/api/getAllDepartments",
  AllDepartmentList: 'foundation/api/getDeptListFilter',
  AllGroupsList: 'foundation/api/getGroupListFilter',
  SaveGroup: 'foundation/api/saveGroupDetails',
  UpdateGroup: 'foundation/api/updateGroup',
  GetGroups: 'foundation/api/getGroupById',
  SaveUsersToGroup: 'foundation/api/saveUsersToGroupAssociation',
  SaveGroupRoles: 'foundation/api/saveGroupRoles',
  DeleteRoleAssociation: 'foundation/api/deleteGroupRoleAssociation',
  DeleteUsersFromGroup: 'foundation/api/deleteUsersFromGroup',
  SaveEsclations: 'foundation/api/saveEscalationLevelsForGroup',
  DeleteEsclations: 'foundation/api/deleteEscLevelFromGroup',
  AllCostCenterListAndFilter: 'foundation/api/getCostCentreFilter',
  AllCostCenterList: 'foundation/api/getAllActiveCostCenter',
  AllUserList: 'foundation/api/getUserListFilter',
  GetActiveCompanies: 'foundation/api/getActiveCompanies',
  SaveOrg: 'foundation/api/CreateOrganization',
  SaveDept: 'foundation/api/createDepartment',
  getAllOrgByCompany: 'foundation/api/getAllOrgByCompanyId/',
  getAllActiveDept: 'foundation/api/getAllActiveDepartments?status=1',
  getUserByCompany: _getUserByCompany,
  getUserByCompanyInUser: _getUserByCompanyforUser,
  getOrgById: _geOrgbyId,
  _updateOrg: 'foundation/api/EditOrganization',
  getDeptById: _geDeptbyId,
  _updateDept: 'foundation/api/editDepartment',
  getUserType: 'foundation/api/getUserType',
  getPrefix: 'foundation/api/getPrefix',
  getDateFormat: 'foundation/api/getDateFormat',
  getCurrency: 'foundation/api/getCurrency',
  getLanguageList: 'foundation/api/getLanguageList',
  getUserPreferences: 'foundation/api/getUserPreferences',
  adduser: 'foundation/api/createUser',
  getAllCountries: 'foundation/api/getAllCountries',
  getAllState: _getLocationStateByCountryId,
  getlocationCityByState: _getLocationCityByStateId,
  getAllActiveOrganization: 'foundation/api/getAllActiveOrganization',
  getDeptByOrgId: _getDeptByOrgId,
  saveUser: 'foundation/api/createUser',
  upload_User_Pic: 'foundation/api/companyImage',
  getUserById: _getUserById,
  GetLocationType: 'foundation/api/getLocationType',
  GetAllZones: 'foundation/api/getAllZones',
  GetDateFormat: 'foundation/api/getUserPreferences/dateFormat',
  GetAllRegions: 'foundation/api/getAllRegions',
  GetAllCountries: 'foundation/api/getAllCountries',
  GetState: 'foundation/api/getLocationStateByCountryId',
  GetCity: 'foundation/api/getLocationCityByStateId',
  CreateLocation: 'foundation/api/createLocation',
  UpdateLocation: 'foundation/api/editLocation',
  GetLocationById: 'foundation/api/getLocationById',
  GetGroupTypeApi: 'foundation/api/getGroupType',
  _updateUser: 'foundation/api/updateUser',
  saveUserCompanyAsso: 'foundation/api/saveUserCompanyAssociation',
  deleteUserCompanyAssociation: 'foundation/deleteUserCompanyAssociation',
  saveUserGroupsAssociation: 'foundation/api/saveUserGroupsAssociation',
  deleteUserGroupAssociation: 'foundation/api/deleteUserGroupAssociation',
  saveUserRoles: 'foundation/api/saveUserRoles',
  deleteUserRoleAssociation: 'foundation/deleteUserRoleAssociation',
  savecc: 'foundation/api/createCostCenter',
  status: 'foundation/api/getCompanyStatus',
  getCcBYId: _getCostCById,
  editCC: 'foundation/api/editCostCenter',
  _permissions: 'foundation/api/getfunctionsandPermissions',
  _module: 'module',
  _userdefaultpref: 'foundation/api/getUserFormPrefrences',
  _moduleChildrens: 'module/moduleChildren',
  _isFav: 'foundation/api/createUserPreference',
  _myProfile: 'foundation/api/myprofile',
  _updateProfile: 'foundation/api/updateUserProfile',
  _profilePic: _updloadProfilepic,
  audit_History: _auditHistory,
  _isAllFav: 'module/modulechildren?allFavourites=true',
  ListTargetDefinition: 'sla/api/getAllSladefinitions',
  SlaFilterMenu: 'sla/api/slaDefMenu',
  ListHolidaySchedule: 'sla/api/getHolidayScheduleData',
  ListWorkSchedule: 'sla/api/getWorkdayScheduleData',
  CreateWorkSchedule: 'sla/api/createWorkdaySchedule',
  SlaStatusField: 'sla/api/slaDefMenu?module=sladef&field=Status',
  getSlabyId: 'sla/api/getWorkDayScheduleById',
  updateWs: _updateWs,
  SaveHolidaySchedule: 'sla/api/holiday',
  SaveHolidayEntry: 'sla/api/holidayEntry',
  GetHolidaysEntries: 'sla/api/holidayEntry',
  GetActiveGroups: 'foundation/api/getAllGroups',
  GetViewAssociatedUserToGroup: 'foundation/api/viewAssociatedUserToGroup',
  AllMenuItemActions: 'rest/menuitemAction',
  GetPriority: 'rest/priority',
  AllOpcategory: 'rest/get-op-catg-field',
  Imp_Service: 'cmdb/api/getSearchFilterTypeAhead',
  CreateIncidentApi: 'rest/breakfixAction',
  AllIncidentList: 'rest/getglobalbreakfixSearch',
  AllIncidentListNew: 'rest/getGlobalBrekfixView',
  CustomerNumberList: 'rest/getGlobalSrcBreakfixNoList',
  VendorNumberList: 'rest/getGlobalVndBreakfixNoList',
  GetQueuedRecords: 'im/tracker/queuedRecords',
  GetRecordsPt: 'im/tracker/queuedTicketTime',
  GetWorknotesPt: 'im/tracker/queuedWiTime',
  GetAttachmentPt: 'im/tracker/queuedAttTime',
  ReOpenIncidentList: 'rest/getReOpenedIncidentList',
  GetBrkfixById: 'rest/breakfixAction',
  HambugerData: 'rest/StatusActions',
  SlaTargetsData: 'sla/api/getSlaInfo',
  AuditHistoryIncident: 'rest/auditAction',
  ActivityNotes: 'rest/activityAction',
  GetRelatedCI: 'cmdb/api/getCI_IncidentRelationship',
  DeleteRelatedCI: 'cmdb/api/deleteCI_IncidentRelationship',
  GetRelateCiDD: 'cmdb/api/getBillingCategory',
  SearchCI: 'cmdb/api/config_items',
  SaveRelation: 'cmdb/api/saveCI_IncidentRelationship',
  GetWorkItem: 'rest/breakfixRelationsAction',
  FilterBrkFixNumber: 'rest/getBreakfixNumberList',
  GraphOpenCount: 'rest/getOpenIncidents',
  GraphIncidentBacklog: 'rest/getIncidentBacklog',
  GraphHopCount: 'rest/getHopCount',
  GraphAgeingIncident: 'rest/ageing-incident',
  GraphReopenCount: 'rest/getReOpenCount',
  GraphMonthlyTrend: 'rest/getMonthlyIncidentTrend',
  GraphMonthlyCriticalTrend: 'rest/monthlyCriticalIncidentTrend',
  GraphDailyTrend: 'rest/getDailyIncidentTrend',
  GraphSlaResponse: 'sla/api/getSLADashboardInfo',
  GraphSla: 'sla/api/getSLACustomerDashboardInfo',
  GraphSlaServiceTargets: 'sla/api/getTicketsAboutToBreach',
  GraphSlaDetailed: 'sla/api/getSLAcustomerDashboardDetailedInfo',
  IncidentCount: 'rest/count-by-status',
  GetCompanyByUserID: 'foundation/api/viewAssociatedCompanyToUser/',
  GetThemeByCompanyID: 'foundation/api/theme/get-theme-by-company-id/',
  GetProviderList: 'foundation/api/getAssociatedCompany',
  FcrIncident: 'rest/incident-fcr-count',
  AgeingAnalysisApi: 'rest/getOpenIncidentsAge',
  AgeingAnalysisPriorityWiseApi: 'rest/getAgeingAnalysisPriorityWise',
  MeanTimeToResolveApi: 'rest/getMeanTime',
  breakfixAction: break_fixPatch,
  asso_company_list: _assoCompany_List,
  CommonModuleHeader: 'foundation/api/getHeaders',
  TaskList: 'task/api/getTasks',
  ApplyTaskFilter: 'task/api/tasks/applySearch',
  ApplySearchClosedOn: 'task/api/tasks/applySearchClosedOn',
  GetGroupAssociations: 'foundation/api/getGroupAssociations',
  GetSlaGraphList: 'sla/api/getDashboardList',
  GetTicketsAboutBreachList: 'sla/api/getTicketsAboutToBreachList',
  updateTask: _updateTask,
  saveTaskVariablesOutput: 'task/api/saveTaskOutput',
  updateTaskOutput: 'task/api/updateTaskOutput/',
  deleteVariableOutput: 'task/api/taskOutputs/',
  getVariableInput: 'task/api/tasks/fetchOutputVariables',
  fetchReasonDetails: 'task/api/region-details',
  getTaskStatuses: 'task/api/getTaskStatuses',
  //getPreferences: "foundation/api/getUserSettingPreferences",
  //GetAllThemes: "foundation/api/theme/get-themes",
  //saveUserSettingPreferences: "foundation/api/saveUserSettingPreferences",
  /*User Themes Endpoints added 14-12-2022*/
  getPreferences: 'foundation/api/getUserPreferences',
  GetAllThemes: 'foundation/api/user-theme/get-themes',
  saveUserSettingPreferences: 'foundation/api/saveUserSettingPreferences',
  taskgetIncidentBacklock: 'task/api/tasks/fetchTaskBacklog',
  taskAgeinggetIncidentBacklock: 'task/api/tasks/fetchAgeingDetails',
  taskGetMonthlyData: 'task/api/tasks/fetchMonthlyTrends ',
  taskGetDailyData: 'task/api/tasks/fetchDailyTrends ',
  getTaskListing_API: 'task/api/getTasks',
  getRequestItems: 'sr/api/getAllServiceRequestItems',
  getRequestItemById: 'sr/api/getServiceRequestItemById',
  updateRequestItemById: 'sr/api/updateServiceRequestItemById',
  downloadRequestAttachment: 'sr/api/attachment/downloadImage',
  uploadImage: 'sr/api/common/uploadImage',
  getRequestWorknotes: 'sr/api/worknotes/getWorknotes',
  uploadRequestWorknotes: 'sr/api/common/worknotes',
  getRequiestItem: 'sr/api/getAudit',
  getRequestItemRelationship: 'sr/api/srItemRelation/getSRItemRelations',
  saveRequestItemRelationship: 'sr/api/srItemRelation/addSRItemRelation',
  getExternalAttributes: 'sr/api/attribute/getSRItemAttributes',
  getTimelineStatus: 'sr/api/getStatusTimeline',
  getModuleDetails: 'sr/api/menulist/getModuleDetails',
  TaskMeanTimeToResolved: 'task/api/tasks/meanTime',
  //getMenuList: 'sr/api/menulist/menu',
  getMenuList: 'api/actionitems/menulist/menu',
  getReqItemVariables: 'sr/api/variable/getVariables',
  getChangeList: 'cm/api/changeManagements',
  getMenuItem: 'cm/api/menu',
  saveChangeData: 'cm/api/saveChangeManagement',
  getChangeById: 'cm/api/changeManagements',
  updateChangeById: 'cm/api/changeManagementUpdate',
  getChangeAuditLog: 'cm/api/getAudit',
  uploadChangeAttachment: 'cm/api/attachment/uploadImage',
  downloadChangeAttachment: 'cm/api/attachment/downloadImage',
  getTaskListForAgeining: 'task/api/tasks/fetchAgeingTickets',
  fetchDailyTrendsData: 'task/api/tasks/fetchDailyTrendsData',
  fetchMonthlyTrendsData: 'task/api/tasks/fetchMonthlyTrendsData',
  meanTimeData: 'task/api/tasks/meanTimeData',
  fetchTaskBacklogData: 'task/api/tasks/fetchTaskBacklogData',
  taskOverDueItemData: 'task/api/tasks/fetchOverdueDetails',
  taskFetchOverdueList: 'task/api/tasks/fetchOverdueList',
  addRequestItemWorkNotes: 'sr/api/worknotes/addNote',
  getTaskStatusCount: 'task/api/Tasks/taskCount',
  getTaskCountListing: 'task/api/Tasks',
  getChangeWorknotes: 'cm/api/worknotes/getWorknotes',
  addChangeWorknote: 'cm/api/worknotes/addNote',
  uploadChangeWorknotes: 'cm/api/common/worknotes', //TODO:check for change upload worknotes.
  changeStatusAction: 'cm/api/action',
  getChangeTimelineStatus: 'cm/api/getStatusTimeline',

  changesByTypeAndStatus: 'cm/api/dashboard/changes-by-type-and-status',
  changeCountByTypeAndCategory:
    'cm/api/dashboard/change-count-byType-and-category',
  newPlannedChanges: 'cm/api/dashboard/new-planned-changes',
  unSuccessfulChanges: 'cm/api/dashboard/un-successful-changes',
  changeSuccessRate: 'cm/api/dashboard/change-success-rate',
  changeByStatus: 'cm/api/dashboard/change-by-status',
  changeTrend: 'cm/api/dashboard/change-trend',
  allChangeCount: 'cm/api/dashboard/all-change-count',
  top10ServiceChange: 'cm/api/dashboard/top-10-service-change',
  ChangeByTypeList: 'cm/api/dashboard/changesByTypeList',
  changesByTypeInPercentage: 'cm/api/dashboard/changesByTypeInPercentage',
  top10CIChange: 'cm/api/dashboard/top-10-ci-change',
  overdueChanges: 'cm/api/dashboard/overdue-changes',
  globalSearch: 'v1/globalsearch',
  majorIncidentDailyTrendSeriesView: 'rest/majorIncidentDailyTrendSeriesView?',
  majorIncidentDailyTrendView: 'rest/majorIncidentDailyTrendView?',

  changesByTypeAndStatusList: 'cm/api/dashboard/change-list-by-type-and-status',
  changeCountByTypeAndCategoryList:
    'cm/api/dashboard/change-list-byType-and-category',
  newPlannedChangesList: 'cm/api/dashboard/new-planned-changes-list',
  unSuccessfulChangesList: 'cm/api/dashboard/un-successful-changes-list',
  changeSuccessRateList: 'cm/api/dashboard/change-list-with-success-rate',
  changeTrendList: 'cm/api/dashboard/change-trend-list',
  allChangeCountList: 'cm/api/dashboard/all-change-list',
  top10ServiceChangeList: 'cm/api/dashboard/top-ten-service-change-list',
  top10CIChangeList: 'cm/api/dashboard/top-ten-ci-change-list',
  latestChangesList: 'cm/api/dashboard/latest-changes-list',
  overdueChangeList: 'cm/api/dashboard/overdue-change-list',
  newTaskListing: 'task/api/tasks/taskCountData',
  getProcessingSummary: _integrationProcessingSummary,
  getProcessingFailures: _integrationProcessingFailures,
  getIncBymonth: _integrationMonthlyTrends,
  getNavSearch: navSearch,
  getChangeExternalAttributes: 'cm/api/attribute/getCmAttributes',
  addChangeNotification: 'cm/api/notification/addNotification',
  getChangeNotification: 'cm/api/notification/getNotifications',
  addRequestNotification: 'sr/api/notification/addNotification',
  getRequestNotification: 'sr/api/notification/getNotifications',
  //addCustomizeTheme: "foundation/api/theme/customize-theme",
  addCustomizeTheme: 'foundation/api/customize-theme',
  getCustomizeTheme: 'foundation/api/theme/get-themes',
  getVendorCompanyUsers: 'foundation/api/getVendorCompanyUsersEndpoint',
  getTrackDetails: 'governance/rest/trackDetails',
  getSubTrackDetails: 'governance/rest/subTrackDetailsByTrackName/',
  getForumDetails: 'governance/rest/forumDetails',
  getCommitteeDetails: 'governance/rest/committeDetailsByForumName/',
  saveMeetingInviteDetails: 'governance/rest/saveMeetingDefinationDetails',
  getOccurrencesList: occuranceList,
  getDocumentDefList: documentList,
  getMeetingDefinationDetails: 'governance/rest/meetingDefinationDetails/',
  getLocation: 'governance/rest/locationDetails',
  updateMeetingDefination: 'governance/rest/updateMeetingDefinationDetails',
  getDefinationListing: 'governance/rest/meetingDefinations',
  attachmentType: 'governance/rest/attachmentTypeDetails',
  addDocAttachment: addMeetingAttachment,
  getOccurrencesDetals: occuranceDetails,
  getGovernanceAction: 'governance/rest/action',
  saveNotifyAction: 'governance/rest/notifyAction',
  updateOccurrenceDetails: 'governance/rest/updateMeetingOccurrananceDetails',
  saveActionPerformed: 'governance/rest/actionPerformed',
  meetingTimeLine: 'governance/rest/getStatusTimeline',
  meetingAuditLogs: 'governance/rest/auditLogs',
  documentDelete: 'governance/deleteAttachment',

  cancelMeetingDefination: 'governance/rest/getCancelMeetingDefination',
  meetingGetWorkNotes: 'governance/rest/getWorkNotesDetails',
  saveMeetingWorkNotes: 'governance/worknote',
  saveActionsItem: 'actionitem/createActionItem',
  updateActionItem: 'actionitem/updateActionItemForDefinationAndOccrance',
  getListOfActionItem: 'actionitem/api/getActionItems',
  getActionsItemDetails: 'actionitem/api/getActionItems',
  getActionItemAuditLog: 'actionitem/getAuditLogs',
  // getDocumentsListForActionItems: "actionitem/occurance/Attachments/",
  // saveDocumentsForActionItem: "actionitem/api/occurance/saveAttachment/",
  // getWorkNoteAttachmentsForActionItem:
  //   "actionitem/defination/worknoteAttachments/",
  // saveWorkNotesForActionItemDefination:
  //   "actionitem/api/occurance/saveWorknoteAttachment/",
  getDocumentsListForActionItems: 'actionitem/occurance/Attachments/',
  saveDocumentsForActionItem: 'actionitem/api/occurance/saveAttachment/',
  getWorkNoteAttachmentsForActionItem:
    'actionitem/occurance/worknoteAttachments/',
  saveWorkNotesForActionItemDefination:
    'actionitem/api/occurance/saveWorknoteAttachment/',
  actionItemList: _actionItemList,
  actionItemListOccurance: _actionItemListOccurance,
  getStatusForActionItems: 'actionitem/getStatusRules',
  getAtionItemDetails: _getActionDetails,
  saveWorkNoteActionItem: 'actionitem/defination/worknote',
  saveWorkNotesActionItemOccur: 'actionitem/occurance/worknote',
  saveAttachmentOccurance: _saveAttachmentOccurance,
  getActionItemsAttachments: _getActionItemsAttachments,
  getOccurrencesActionItemNotes: _getOccurrencesActionItemNotes,
  saveWorkNotesOccurrenceActionItem: _saveWorkNotesOccurrenceActionItem,
  saveAttachmentActionItemDef: _saveAttachmentActionItemDef,
  getActionItemsDefAttachments: _getActionItemsDefAttachments,
  getTracking: _getTracking,
  actionITemSearch: 'actionitem/saveApplySearch',
  updateActionStatus: 'actionitems/action/status',
  getNextStatusActionItem: 'actionitem/action/status-rules/',
  getActionStatus: _getActionStatus,
  saveTracking: 'governance/rest/saveOccurranceTrackingDetails',
  getMinutes: _getMinutes,
  deleteMinutes: _deleteMenutes,
  saveMinutes: 'governance/rest/saveMinutesDetails',
  updateMintues: 'governance/rest/updateMinutesDetails',
  getmintueAttachmentType: 'governance/rest/getMinutesAttachmentTypes',
  cancleOccurrence: 'governance/rest/cancelMeetingOccurrence',
  sendMinutes: 'governance/rest/sendMinutesDetails',
  minuteAttachment: 'governance/minutes/1',
  taskNotification: 'task/api/activityLogs/notification',
  tackActivitLogs: 'task/api/getActivityLogs',
  getAllForums: 'governance/rest/getAllActiveInActiveForums',
  getCategoryDetails: 'governance/rest/categoryDetails',
  getAllActiveInActiveCommittees:
    'governance/rest/getAllActiveInActiveCommittees',
  fetchCountForDashboardMeetingModule: 'api/actionitems/count',
  fetechMeetingCount: 'governance/rest/dashboard/allScheduledMeetings',
  saveForum: 'governance/rest/saveforumDetails',
  updateForum: 'governance/rest/updateforumDetails',
  getForumById: 'governance/rest/getFourmDetailById',
  getAllCommitee: 'governance/rest/getAllActiveInActiveCommittees',
  saveCommitee: 'governance/rest/saveCommiteeDetails',
  getCommiteeById: 'governance/rest/getCommitteeDetailsById',
  updateCommitee: 'governance/rest/updateCommiteeDetails',
  catAllCategory: 'governance/rest/categoryDetails',
  forumStatus: 'governance/rest/getforumStatus',
  committeeStatus: 'governance/rest/getCommitteeStatus',
  forumType: 'governance/rest/getForumType',
  uploadForumImage: 'governance/saveforumDetailsEndpoint/1',
  uploadCommiteeImage: 'governance/saveCommiteeDetailsEndpoint/1',
  getAllActiveCommitee: 'governance/rest/getAllActiveInActiveCommittees',
  getDataByForumName: 'governance/rest/dashboard/allScheduleMeetingsByForum',
  allScheduleMeetingsByForumByCommittee:
    'governance/rest/dashboard/allScheduleMeetingsByForumByCommittee',
  OverdueActionItemsByForum: 'api/actionitems/forum/overdue/count',
  ageingOverdueActionItemsByForum: 'api/actionitems/forum/overdue/ageing',
  actionItemsByForumCommittee: 'api/actionitems/committee/overdue/count',
  ageingOverdueActionItemsCommittee: 'api/actionitems/committee/overdue/ageing',
  ageingOverdueActionItemsSupplier: 'api/actionitems/supplier/overdue/ageing',
  overdueActionItemsSupplier: 'api/actionitems/supplier/overdue/count',
  allScheduledMeetingsDetails:
    'governance/rest/dashboard/allScheduledMeetingsDetails',
  getAllCategory: 'governance/rest/categoryDetails',
  getmeetingOccurrences: 'governance/rest/getMeetingOccurrencesByMonth',
  meetingStatus: 'governance/rest/occurrenceSts',
  allScheduleMeetingsByForumDetails:
    'governance/rest/dashboard/allScheduleMeetingsByForumDetails',
  allScheduleMeetingsByForumByCommitteeDetails:
    'governance/rest/dashboard/allScheduleMeetingsByForumByCommitteeDetails',
  getAllForumnList: 'governance/rest/getAllForumsDetails',
  getAllCommitteeList: 'governance/rest/assoCompActiveInActiveCommittees',
  tasksChangeDashboardCount: 'task/api/tasksChangeDashboardCount',
  actionTemplate: 'api/actionitems/action-template',
  getPirQuestions: 'pir/api/getQuestions',
  getYesNoOption: 'pir/api/getYesNoOption',
  addAnswerPir: 'pir/api/addAnswer',
  getQuestionAndAnswer: 'pir/api/getQuestionAndAnswer',
  getMirQuestions: 'rest/getQuestionAndAnswer',
  addAnswerMir: 'rest/addAnswer',
  getAssoActiveCompanyLandScape: getassoCompanyLandScape,
  getLocationByCId: _getLocationByCId,
  getLandscapeDocuments: _getLandscapeDocuments,
  getSlaByServiceProviderIds: _getSlaByServiceProviderIds,
  savePmRecord: 'rest/pm/ui/savePMAction',
  updatePmRecord: 'rest/pm/ui/updatePMAction',
  getMenuActions: 'rest/pm/ui/menuitemAction',
  allProblemsList: 'rest/pm/ui/listPMAction',
  getPMpriority: 'rest/pm/ui/priority',
  getOpCatfiledpm: getOpCatfiledpm,
  getPMAction: getPMAction,
  getPmstatusActions: 'rest/pm/ui/statusActions',
  getStatusTimeline: getStatusTimeline,
  postActivityAction: 'rest/pm/ui/activityAction',
  savePMTeamAction: 'rest/pm/ui/savePMTeamAction',
  updatePMTeamAction: 'rest/pm/ui/updatePMTeamAction',
  getCabDashboardList: 'cm/api/dashboard/getCabDashboardList',
  uploadFirebaseUsers: 'api/firebase/import-users',
  getCabChangeRejectionRate: 'cm/api/dashboard/getCabChangeRejectionRate',
  getCabChangeRejectionRateList:
    'cm/api/dashboard/getCabChangeRejectionRateList',

  /**************ScoreCard******************/
  getAllActiveQuadrantsId: 'scorecard/rest/getActiveQuadrantsId',
  getAllActiveQuadrants: 'scorecard/rest/getAllActiveQuadrants',
  saveSuppliersScorecard: 'scorecard/rest/saveSuppliersScorecard',
  getAllActiveQuadrantsPerspectiveView:
    'scorecard/rest/getAllActiveQuadrantsPerspectiveView',
  submitSuppliersScorecard: 'scorecard/rest/submitSuppliersScorecard',
  /*********PM Dashboard Endpoints********/
  GraphAgeingProblem: 'rest/pm/ageing-problem',
  GraphIncidentBacklogProblem: 'rest/pm/problem-backlog',
  GraphProblemCount: 'rest/pm/count-by-status',
  AllProblemList: 'rest/pm/ui/listPMAction',
  GraphTaskCount: 'task/api/fetchTaskCount',
  AllTaskCountList: 'task/api/fetchTaskCountList',
  GraphTaskRootCause: 'task/api/fetchTaskRootCause',
  AllTaskRootCauseList: 'task/api/fetchTaskRootCauseList',
  /*********PM Dashboard Endpoints********/
};
