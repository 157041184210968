import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import commiteeService from "components/GovernanceMeeting/console/committees/http-services";

const initialState = [];
const initialCatState = [];

export const getAllCommitees = createAsyncThunk(
  "commitees/retrieve",
  async (params) => {
    const res = await commiteeService.getAll(params);
    return res.data;
  }
);

export const createCommitee = createAsyncThunk(
  "commitee/create",
  async (params, { rejectWithValue }) => {
    try {
      const res = await commiteeService.create(params);
      return res.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getCommiteeById = createAsyncThunk(
  "commitee/getById",
  async (params) => {
    const res = await commiteeService.getById(params);
    return res.data;
  }
);

export const getAllCategory = createAsyncThunk(
  "commitee/getAllCategroy",
  async (params) => {
    const res = await commiteeService.getAllCategroy(params);
    return res.data;
  }
);

export const updateCommitee = createAsyncThunk(
  "commitee/update",
  async (params) => {
    const res = await commiteeService.update(params);
    return res.data;
  }
);

export const resetAllCommittees = createAsyncThunk(
  "commitee/resetallcommittees",
  async () => {
    return [];
  }
);

const commiteeSlice = createSlice({
  name: "commitee",
  initialState,
  extraReducers: {
    [createCommitee.fulfilled]: (state, action) => {
      return action.payload;
    },
    [resetAllCommittees.fulfilled]: (state, action) => {
      return [...action.payload];
    },
    [getAllCommitees.fulfilled]: (state, action) => {
      return [...action.payload];
    },
    [getCommiteeById.fulfilled]: (state, action) => {
      return action.payload;
    },
    [updateCommitee.fulfilled]: (state, action) => {
      return action.payload;
    },
  },
});

const categorySlice = createSlice({
  name: "category",
  initialState: initialCatState,
  extraReducers: {
    [getAllCategory.fulfilled]: (state, action) => {
      return [...action.payload];
    },
  },
});

const { reducer } = commiteeSlice;
export default reducer;

export const categoryReducer = categorySlice.reducer;
