import React, { useState } from "react";
import { Field, useField } from "formik";
import { useQuery } from "react-query";
import FormControl from "@material-ui/core/FormControl";
import Loader from "react-loader-spinner";
import { Select as AntSelect } from "antd";
import { useRecoilValue } from "recoil";
import { SearchList } from "../../Custom/Form/FormFields";
import {
  fetchState,
  fetchCity,
  getPrefixData,
  getUserTypeData,
  myDataQuery,
} from "../../../config/selector";

import {
  getAllCountries,
  getAllActiveOrganization,
  getDeptByOrgId,
  AllCostCenterList,
  AllLocationList,
  getAllActiveDept,
} from "../../../utils/EndPoints";
import { Get } from "../../../utils/AxiosUtils";
export function GetPrefixOptions() {
  const prefix = useRecoilValue(getPrefixData);
  return (
    prefix &&
    prefix.map((p, i) => {
      return (
        <option key={i} value={p.fieldNameKey1}>
          {p.fieldNameKey1}
        </option>
      );
    })
  );
}

export function GetDateFormatOptions(props) {
  const df = useRecoilValue(myDataQuery({ userPref: props.preference }));
  return (
    df &&
    df.map((d, i) => {
      return (
        <option key={i} value={d.dateFormat}>
          {d.dateFormat}
        </option>
      );
    })
  );
}
export function GetCurrencyOptions(props) {
  const curr = useRecoilValue(myDataQuery({ userPref: props.preference }));
  return (
    curr &&
    curr.map((c, i) => {
      return (
        <option key={i} value={c.code}>
          {c.code}
        </option>
      );
    })
  );
}

export function GetLanguageOptions(props) {
  const lng = useRecoilValue(myDataQuery({ userPref: props.preference }));
  return (
    lng &&
    lng.map((ln, i) => {
      return (
        <option key={i} value={ln.fieldNameKey1}>
          {ln.fieldName2}
        </option>
      );
    })
  );
}
export function GetDataSourceOptions(props) {
  const datasrc = useRecoilValue(myDataQuery({ userPref: props.preference }));
  return (
    datasrc &&
    datasrc.map((ln, i) => {
      return (
        <option key={i} value={ln.fieldNameKey1}>
          {ln.fieldNameKey1}
        </option>
      );
    })
  );
}

export function AllOrgList({ ...props }) {
  const [helpers] = useField("deptId");

  const { selectedcompid } = props;
  const getOrgList = async () => {
    return await Get(getAllActiveOrganization, selectedcompid);
  };
  const { data: dataOrg } = useQuery(
    ["fetchActiveOrg", selectedcompid],
    getOrgList,
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: selectedcompid !== undefined && selectedcompid !== "",
    }
  );
  const orgList = dataOrg ? dataOrg.data["data"] : [];

  const { Option } = AntSelect;
  return (
    <Field
      {...props}
      name="orgId"
      id="orgId"
      label="Organization"
      required={false}
      as={SearchList}
      onClear={() => helpers.setValue("")}
      getPopupContainer={(trigger) => trigger.parentNode}
    >
      {orgList.length !== 0 &&
        orgList.map((v, i) => {
          return (
            <Option key={i} value={v.orgId}>
              {v.orgName}
            </Option>
          );
        })}
    </Field>
  );
}
export function AllDeptList({ ...props }) {
  const [field] = useField("orgId");
  const { value: orgId } = field;
  const [deptList, setDeptList] = useState([]);
  const { selectedcompid } = props;
  let getDeptList = "";

  if (orgId !== "" && orgId !== undefined) {
    getDeptList = async () => {
      return await Get(getDeptByOrgId(orgId), selectedcompid);
    };
  } else {
    getDeptList = async () => {
      return await Get(getAllActiveDept, selectedcompid);
    };
  }
  const { Option } = AntSelect;
  useQuery(["fetchActiveDept", orgId, selectedcompid], getDeptList, {
    onSuccess: (res) => {
      setDeptList(res.data["data"]);
    },
    retry: true,
    refetchOnWindowFocus: false,
    enabled:
      (orgId !== undefined && orgId !== "") ||
      (selectedcompid !== "" && selectedcompid !== undefined),
  });
  //console.log(selectedcompid);
  //console.log(IsFetchingDept ? [] : dataDept.data);

  // const deptList = IsFetchingDept
  //   ? []
  //   : dataDept && dataDept.data.length != 0 && dataDept.data.length != undefined
  //   ? dataDept.data["data"]
  //   : [];

  return (
    <Field
      {...props}
      required={false}
      as={SearchList}
      getPopupContainer={(trigger) => trigger.parentNode}
    >
      {deptList.length !== 0 &&
        deptList.map((v, i) => {
          return (
            <Option key={i} value={v.deptId}>
              {v.deptName}
            </Option>
          );
        })}
    </Field>
  );
}

export function AllCostCList({ ...props }) {
  // const costList = useRecoilValue(fetchActiveCostC);
  const [field] = useField("selectedCompId");
  const { value } = field;
  const getCostList = async () => {
    return await Get(AllCostCenterList + "?status=1", value);
  };
  const { data: dataCC } = useQuery(["fetchActiveCostC", value], getCostList, {
    retry: false,
    refetchOnWindowFocus: false,
    enabled: value !== undefined && value !== "",
  });
  const costList = dataCC ? dataCC.data["data"] : [];

  const { Option } = AntSelect;
  return (
    <Field
      {...props}
      required={false}
      as={SearchList}
      getPopupContainer={(trigger) => trigger.parentNode}
    >
      {costList.length !== 0 &&
        costList.map((v, i) => {
          return (
            <Option key={i} value={v.costCenterId}>
              {v.costCenterName}
            </Option>
          );
        })}
    </Field>
  );
}

export function AllLocList({ ...props }) {
  //const locList = useRecoilValue(fetchActiveLocations);
  const [field] = useField("selectedCompId");
  const { value } = field;
  const getLocList = async () => {
    return await Get(AllLocationList + "?status=1", value);
  };
  const { data: dataloc } = useQuery(
    ["fetchActiveLocations", value],
    getLocList,
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: value !== undefined && value !== "",
    }
  );
  const locList = dataloc ? dataloc.data["data"] : [];
  const { Option } = AntSelect;
  return (
    <Field
      {...props}
      required={false}
      as={SearchList}
      getPopupContainer={(trigger) => trigger.parentNode}
    >
      {locList.length !== 0 &&
        locList.map((ln, i) => {
          return (
            <Option key={i} value={ln.locationId}>
              {ln.locationName}
            </Option>
          );
        })}
    </Field>
  );
  // return (
  //   locList &&
  //   locList.map((ln, i) => {
  //     return (
  //       <option key={i} value={ln.locationId}>
  //         {ln.locationName}
  //       </option>
  //     );
  //   })
  // );
}
export function GetUserTypeOptions() {
  const utype = useRecoilValue(getUserTypeData);
  return (
    utype &&
    utype.map((v, i) => {
      return (
        <option key={i} value={v.menuId}>
          {v.fieldNameKey1}
        </option>
      );
    })
  );
}

// const getState = async () => {
//   return await Get(getAllState + `/${countryId}`);
// };
// const getCity = async () => {
//   return await Get(getlocationCityByState + `/${stateId}`);
// };

export function GetAllCountries() {
  const { isLoading: isLoadingGc, data: cntryData } = useQuery(
    "_AllCountries",
    () => {
      return Get(getAllCountries);
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  // const cntry = useRecoilValue(fetchAllCounties);
  const cntry = isLoadingGc ? [] : cntryData.data.data;
  return (
    cntry &&
    cntry.map((ln, i) => {
      return (
        <option key={i} value={ln.countryId}>
          {ln.countryName}
        </option>
      );
    })
  );
}

export function GetAllState() {
  //GET ALL STATE

  // const { isLoading: isLoadingGs, data: dataGs } = useQuery(
  //   ["states", countryId],
  //   getState,
  //   {
  //     retry: false,
  //     refetchOnWindowFocus: false,
  //   }
  // );

  const state = useRecoilValue(fetchState);
  return (
    state.length !== 0 &&
    state.map((st, i) => {
      return (
        <option key={i} value={st.stateId}>
          {st.stateName}
        </option>
      );
    })
  );
}
export function GetAllCity() {
  const city = useRecoilValue(fetchCity);

  return (
    city &&
    city.map((ln, i) => {
      return (
        <option key={i} value={ln.cityId}>
          {ln.cityName}
        </option>
      );
    })
  );
}

export function SelectLoader() {
  return (
    <div className="form-group form__group mt-3">
      <FormControl style={{ width: "100%" }}>
        <Loader type="ThreeDots" color="#2e75b6" height={10} />
        {/* <MSelect
          style={{ marginTop: 0, maxWidth: "100%" }}
          name="Loading"
          native
          value={"loading...."}
          fullWidth
          IconComponent={Arrowdown}
        >
          <option>
            <Loader type="ThreeDots" color="#2e75b6" height={10} />
          </option>
        </MSelect> */}
      </FormControl>
    </div>
  );
}

export const ErrorFallback = ({ error, resetErrorBoundary }) => {
  return (
    <div>{`Error:${error.message}" "${error.response.data.api_error}`}</div>
  );
};
