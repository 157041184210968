import React from "react";
import { Modal } from "react-bootstrap";
import { Button } from "reactstrap";
import SimpleBar from "simplebar-react";
import TermService from "../Footer/TermService";
import { useRecoilValue } from "recoil";
import { unifoThemes } from "../../config/selector";
function FnModal({ modal, handleClose, modalTitle }) {
  const themeName = useRecoilValue(unifoThemes);
  // console.log("@@", themeName);
  return (
    <div>
      <Modal
        centered
        show={modal}
        onHide={handleClose}
        id="exampleModal"
        size="lg"
        className={`unifo ${themeName === "blue-white" ? "" : themeName}`}
        scrollable={false}
      >
        <>
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              {modalTitle}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
            >
              <i className="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>

          <Modal.Body className="staticBody">
            <SimpleBar
              style={{ height: "400px", overflowX: "hidden" }}
              autoHide={false}
            >
              <div className="row">
                <div className="col-sm-12">
                  <TermService />
                </div>
              </div>
            </SimpleBar>
          </Modal.Body>
        </>

        <Modal.Footer className="text-left">
          {/* <Button
            className="action-primary"
            onClick={handleClose}
            style={{
              padding: "5px 12px",
            }}
          >
            Ok
          </Button> */}
          <a className="modelbtn" onClick={handleClose}>
            Ok
          </a>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default FnModal;
