import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Get, Post, Patch } from 'utils/AxiosUtils';
import {
  getAllActiveQuadrantsId,
  getAllActiveQuadrants,
  saveSuppliersScorecard,
  getAllActiveQuadrantsPerspectiveView,
  submitSuppliersScorecard,
} from 'utils/EndPoints';

const initialState = {
  activeQuadrantsId: [],
  allActiveQuadrants: [],
  allActivePerspective: [],
  status: 'idle',
  error: null,
};

export const getActiveQuadrantsId = createAsyncThunk(
  'scorecard/getActiveQuadrantsId',
  async ({ companyID }) => {
    const response = await Get(
      getAllActiveQuadrantsId + `?tenantId=${companyID}`,
      companyID
    );
    return response.data;
  }
);

export const getAllActiveQuadrantsData = createAsyncThunk(
  'scorecard/getAllActiveQuadrants',
  async ({ companyID, quadrantId }) => {
    //&quadrantId=${quadrantId}
    const quadrantStr = quadrantId ? `&quadrantId=${quadrantId}` : '';
    const response = await Get(
      getAllActiveQuadrants + `?tenantId=${companyID}`,
      companyID
    );
    return response.data;
  }
);

export const getAllActivePerspectiveView = createAsyncThunk(
  'scorecard/getAllActiveQuadrantsPerspectiveView',
  async ({ companyID, quadrantId }) => {
    const response = await Get(
      getAllActiveQuadrantsPerspectiveView + `?tenantId=${companyID}`,
      companyID
    );
    return response.data;
  }
);

export const createScoreCard = createAsyncThunk(
  'scorecard/saveSuppliersScorecard',
  async (params, { compID }) => {
    const response = await Post(
      saveSuppliersScorecard + `?tenantId=${params[0]?.companyId}`,
      params,
      params[0]?.companyId
    );

    return response.data;
  }
);

export const submitScoreCard = createAsyncThunk(
  'scorecard/submitSuppliersScorecard',
  async ({ companyID, quadrantId }) => {
    const response = await Patch(
      submitSuppliersScorecard +
        `?tenantId=${companyID}&quadrantId=${quadrantId}`,
      companyID
    );
    return response.data;
  }
);
export const scoreCardSlice = createSlice({
  name: 'scorecard',
  initialState,
  reducers: {
    setInitialValues: (state, action) => {
      state.initialValues = { ...state.initialValues, ...action.payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getActiveQuadrantsId.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.activeQuadrantsId = action.payload;
      })
      .addCase(getActiveQuadrantsId.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(getAllActiveQuadrantsData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.allActiveQuadrants = action.payload;
        // state.allActiveQuadrants = state.allActiveQuadrants.concat(
        //   action.payload
        // );
        // const newData = action.payload;
        // state.allActiveQuadrants = [...state.allActiveQuadrants, ...newData];
      })
      .addCase(getAllActiveQuadrantsData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(createScoreCard.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(createScoreCard.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(getAllActivePerspectiveView.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.allActivePerspective = action.payload;
      })
      .addCase(getAllActivePerspectiveView.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(submitScoreCard.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(submitScoreCard.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { setInitialValues } = scoreCardSlice.actions;
export default scoreCardSlice.reducer;
