import { createSlice } from "@reduxjs/toolkit";
import { Get, Put, Upload } from "../../utils/AxiosUtils";
import {
  task_attachemtns,
  save_task_attachment,
  delte_task_attachment,
} from "../../utils/IncidentEndpoints";

export const initialState = {
  loading: false,
  hasErrors: false,
  attachments: [],
  saveattachmentLoading: false,
  delteattachmentLoading: false,
};

const attachmentsSlice = createSlice({
  name: "attachments",
  initialState,
  reducers: {
    getAttachments: (state) => {
      state.loading = true;
    },
    delAttachments: (state) => {
      state.delteattachmentLoading = true;
    },
    getAttachmentsSuccess: (state, { payload }) => {
      state.attachments = payload;
      state.loading = false;
      state.hasErrors = false;
    
    },
    getAttachmentsFailure: (state) => {
      state.loading = false;
      state.hasErrors = true;
    },
    saveAttachmentSuccess: (state, { payload }) => {
      state.saveattachmentLoading = false;
      state.hasErrors = false;
    },
    deleteAttachmentSuccess: (state, { payload }) => {
      state.delteattachmentLoading = false;
      state.hasErrors = false;
    },
  },
});
export const {
  getAttachments,
  getAttachmentsSuccess,
  getAttachmentsFailure,
  saveAttachmentSuccess,
  delAttachments,
  deleteAttachmentSuccess,
} = attachmentsSlice.actions;

// A selector
export const attachmentsSelector = (state) => state.attachments;

// The reducer
export default attachmentsSlice.reducer;

// Asynchronous thunk action
export function fetchAttachments(id, impCompID, order) {
  return async (dispatch) => {
    dispatch(getAttachments());
    try {      
      const response = await Get(
        `${task_attachemtns(id)}?filter[order]=createdDate ${order}`,
        impCompID
      );     
      dispatch(getAttachmentsSuccess(response["data"]));
    } catch (error) {      
      dispatch(getAttachmentsFailure());
    }
  };
}

export function saveAttachment(
  postData,
  hideList,
  impCompID,
  setSubmitting,
  resetForm
) {

  return async (dispatch) => {
    // dispatch(addComment());
    try {
      await Upload(save_task_attachment(), postData, impCompID).then(
        (response) => {        
          dispatch(saveAttachmentSuccess(response["data"]));
          hideList();
          setSubmitting();
          dispatch(
            fetchAttachments(response.data["taskCode"], impCompID, "desc")
          );
        
        }
      );
    } catch (error) {
      dispatch(getAttachmentsFailure());
    }
  };
}

export function _delteAttachment(id, taskcode, compId, hideList) {
  return async (dispatch) => {
    try {
      await Put(delte_task_attachment(id), compId).then((res) => {
        dispatch(deleteAttachmentSuccess());
        hideList();
        dispatch(fetchAttachments(taskcode));
      });
    } catch {
      dispatch(getAttachmentsFailure());
    }
  };
}
