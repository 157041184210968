import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Get } from 'utils/AxiosUtils';

import {
  getAssoActiveCompanyLandScape,
  getLocationByCId,
  getLandscapeDocuments,
  getSlaByServiceProviderIds,
} from 'utils/EndPoints';

const initialState = {
  assoCompList: [],
  vernderList: [],
  locationList: [],
  filtersList: [],
  landScapeDocumentList: [],
  landScapeDocumentListSPI: [],
  slaList: [],
  status: 'idle',
  error: null,
};

export const fetchLandScapeDocuments = createAsyncThunk(
  'landscape/fetchLandScapeDocuments',
  async ({ companyID, providerIds }, thunkAPI) => {
    try {
      const { data } = await Get(
        getLandscapeDocuments(companyID, providerIds),
        companyID
      );
      return data?.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchSlaByServiceProviderIds = createAsyncThunk(
  'landscape/fetchSlaByServiceProviderIds',
  async ({ companyId, providerIds }, thunkAPI) => {
    try {
      const { data } = await Get(
        getSlaByServiceProviderIds(companyId, providerIds),
        companyId
      );
      return data?.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchLandScapeDocumentsSPI = createAsyncThunk(
  'landscape/fetchLandScapeDocumentsSPI',
  async ({ companyID, providerIds }, thunkAPI) => {
    try {
      const { data } = await Get(
        getLandscapeDocuments(companyID, providerIds),
        companyID
      );
      return data?.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

export const fetchAssociatedActiveCompany = createAsyncThunk(
  'landscape/fetchAssociatedActiveCompany',
  async ({ providerIds, companyId, sortedBy }) => {
    const response = await Get(
      getAssoActiveCompanyLandScape(providerIds, companyId, sortedBy),
      companyId
    );
    return response.data;
  }
);

export const fetchLocationsByCompanyId = createAsyncThunk(
  'landscape/fetchLocationsByCompanyId',
  async ({ companyId, providerIds }) => {
    const response = await Get(getLocationByCId(companyId), companyId);
    return response.data;
  }
);

const landScapeSlice = createSlice({
  name: 'landscape',
  initialState,
  reducers: {
    setVernderList: (state, action) => {
      state.vernderList = action.payload;
    },
    setFiltersList: (state, action) => {
      state.filtersList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAssociatedActiveCompany.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.companyList = action.payload;
      })
      .addCase(fetchAssociatedActiveCompany.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchLocationsByCompanyId.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.locationList = action.payload;
      })
      .addCase(fetchLocationsByCompanyId.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchLandScapeDocuments.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.landScapeDocumentList = action?.payload;
      })
      .addCase(fetchLandScapeDocuments.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchLandScapeDocumentsSPI.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.landScapeDocumentListSPI = action?.payload;
      })
      .addCase(fetchLandScapeDocumentsSPI.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(fetchSlaByServiceProviderIds.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.slaList = action?.payload;
      })
      .addCase(fetchSlaByServiceProviderIds.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { setVernderList, setFiltersList } = landScapeSlice.actions;

export default landScapeSlice.reducer;
