import { combineReducers } from 'redux';
import tasksReducer from './Incident/tasks';
import detailsReducer from './Incident/details';
import worknotesReducer from './Incident/worknotes';
import auditlogsReducer from './Incident/auditlog';
import variablesReducer from './Incident/variables';
import attachmentsReducer from './Incident/attachments';
import requestsReucer from './request/getserviceRequest';
import forumReducer from './governance/forum';
import commiteeReducer, { categoryReducer } from './governance/commitee';
import eventsSlice from './governance/calendar';
import filterSlice from './governance/filters';
import eventsbyDateSlice from './governance/eventsbyDate';
import consoleFilterSlice from './governance/console-filters';
import commonSlice from './common';
import landScapeSlice from './governance/landscape';
import problemSlice from './problem-management';
import scoreCardSlice from './score-card';

const rootReducer = combineReducers({
  tasks: tasksReducer,
  details: detailsReducer,
  worknotes: worknotesReducer,
  auditlogs: auditlogsReducer,
  variables: variablesReducer,
  attachments: attachmentsReducer,
  requests: requestsReucer,
  forums: forumReducer,
  commitee: commiteeReducer,
  category: categoryReducer,
  events: eventsSlice,
  filters: filterSlice,
  eventsbydate: eventsbyDateSlice,
  filtersVal: consoleFilterSlice,
  common: commonSlice,
  landScape: landScapeSlice,
  problem: problemSlice,
  scoreCard: scoreCardSlice,
});

export default rootReducer;
