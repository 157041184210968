import React, { useContext, useState, useEffect, createContext } from 'react';
import { useHistory } from 'react-router-dom';
import { reactLocalStorage } from 'reactjs-localstorage';
import {
  signInWithEmailAndPassword,
  setPersistence,
  browserSessionPersistence,
  onAuthStateChanged,
  signOut as logOut,
} from 'firebase/auth';
import { auth } from '../config/Firebase';
import axios from 'axios';
import Loader from '../components/Layout/Loader';
import { _module } from '../utils/EndPoints';
import { GetModules } from '../utils/AxiosUtils';

export const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const history = useHistory();
  const [sidebarData, setSidebarData] = useState([]);
  const [sidebarNav, setSidebarNav] = useState([]);
  const [favsChild, setFavsChild] = useState({});
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(true);
  const [authtoken, setAuthToken] = useState(null);

  async function signIn(email, password) {
    await setPersistence(auth, browserSessionPersistence);
    return await signInWithEmailAndPassword(auth, email, password);
  }

  function signOut() {
    return logOut(auth);
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setLoading(true);
      setCurrentUser(user);
      if (user) {
        user
          .getIdToken()
          .then(function (idToken) {
            setAuthToken(idToken);
            const requestOptions = {
              headers: {
                'Content-Type': 'application/json',
                'company-id': 10,
                authorization: `${idToken}`,
              },
            };
            let _routes = {};

            GetModules(_module, requestOptions).then((res) => {
              res.data.panel.map((route, idx) => {
                route.modules.map((subroute, subindex) => {
                  const childrens =
                    subroute.children.length !== 0 ? subroute.children : [];
                  _routes = Object.assign({}, _routes, {
                    [subroute.moduleId]: childrens,
                  });
                });
              });

              setFavsChild(_routes);
              setSidebarData((prevState) => {
                reactLocalStorage.set('appliedTheme', res?.data?.theme);
                // localStorage.setItem("appliedTheme", res?.data?.theme);
                return {
                  ...prevState,
                  ...res,
                };
              });
              setSidebarNav(res.data.panel);
              setLoading(false);
            });
          })
          .catch((error) => {
            setLoading(false);
          });
      } else {
        setLoading(false);
        signOut();
      }
    });

    return unsubscribe;
  }, []);

  const value = React.useMemo(
    () => ({
      currentUser,
      authtoken,
      signIn,
      signOut,
      sidebarData,
      sidebarNav,
      loading,
      favsChild,
      setFavsChild,
      setSidebarData,
      // setSidebarData: () => {},
    }),
    [currentUser, authtoken, sidebarData, loading, favsChild, sidebarNav]
  );

  axios.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      // We don't need a user get logout if any api is giving 401 in response
      // if (error.response !== undefined && error.response.status === 401) {
      //   Get(_Logout(sidebarData?.data?.user_id));
      //   signOut();
      //   //history.push("/logout");
      //   window.location.href = "/logout";
      // }
      if (
        error?.response?.status === 401 &&
        error?.response?.data.toLowerCase() ===
          'Session Time Out Please Login Again!'.toLowerCase()
      ) {
        signOut();
        history.push('/login');
      } else if (
        error?.response?.status === 401 &&
        error?.response?.data.toLowerCase() ===
          'Firebase Token Expired'.toLowerCase()
      ) {
        window.location.reload();
      } else {
        return Promise.reject(error);
      }
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      // return Promise.reject(error);
    }
  );

  if (loading) {
    axios.defaults.headers.common['authorization'] = `${authtoken}`;
    axios.defaults.withCredentials = true;
    axios.defaults.headers.common['Cache-Control'] = 'no-cache';
    axios.defaults.headers.common['Pragma'] = 'no-cache';
    axios.defaults.headers.common['X-XSS-Protection'] = 1;
    axios.defaults.headers.common['Content-Security-Policy'] =
      "default-src 'none'; script-src 'self'; connect-src 'self'; img-src 'self'; style-src 'self'; frame-ancestors 'self'; form-action 'self';";
    return <Loader />;
  }
  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
