/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation, useQuery } from 'react-query';
import {
  List,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink as RNavLink,
} from 'reactstrap';
import { withRouter, useHistory } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { miniOverlay, fncDetails } from '../../../config/selector';

import { Get, Post } from '../../../utils/AxiosUtils';
import {
  _moduleChildrens,
  _isFav,
  _isAllFav,
  getNavSearch,
} from '../../../utils/EndPoints';
import { AuthContext } from '../../../contexts/AuthContext';

// import PerfectScrollbar from "react-perfect-scrollbar";
import SimpleBar from 'simplebar-react';
import classnames from 'classnames';

import { SidebarItems } from './SidebarItems';
import { SearchItems } from './SearchItems';
import { FavouritesData } from './FavsList';

import { setPermissionsList } from 'slices/common';
import useDebounce from '../../Custom/Hooks/useDebounce';

import MobileLogo from '../../../assets/img/DRYiCE-mobile-logo.png';

function Sidebar({ location }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const { sidebarData } = useContext(AuthContext);
  const userInfo = sidebarData.data;

  const [isOpen, setIsOpen] = useState(false);
  const [isSearch, setIsSearch] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [overlayState, setOverlayState] = useRecoilState(miniOverlay);
  const [fnDState, setFnDState] = useRecoilState(fncDetails);

  const [filterNav, setFilterNav] = useState([]);
  const [, setIsLanding] = useState(0);
  const [isSidebarLoading, setIsSidebarLoading] = useState(true);

  let serachfilter = [];

  const [activeTab, setActiveTab] = useState('1');
  const [miniSidebar, setMiniSidebar] = useState(false);

  const [open, setOpen] = useState('');

  const toggle = (id) => {
    open === id ? setOpen('') : setOpen(id);
  };

  const [openSub, setOpenSub] = useState('');

  const toggleSub = (id) => {
    openSub === id ? setOpenSub('') : setOpenSub(id);
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const toggleMiniSide = () => {
    setIsSearch(false);
    setSearchTerm('');
    setMiniSidebar(!miniSidebar);
    setOverlayState(!overlayState);
  };

  const _fnClick = async (_fId, _fName, url) => {
    await dispatch(setPermissionsList({ name: _fName, id: _fId }));
    setFnDState((val) => ({ ...val, name: _fName, id: _fId }));
  };

  function findModuleIndex(moduleId) {
    for (let i = 0; i < sidebarData?.data?.panel.length; i++) {
      const module = sidebarData?.data?.panel[i];
      if (module.modules) {
        const index = module.modules.findIndex((m) => m.moduleId === moduleId);
        if (index !== -1) {
          return { moduleIndex: i, submoduleIndex: index };
        }
      }
    }
    return null;
  }

  function findModuleAndUpdateChildren(data, moduleIdToFind, newChildrenArray) {
    for (const group of data) {
      for (const module of group.modules) {
        if (module.moduleId === moduleIdToFind) {
          module.children = newChildrenArray;
          return data;
        }
      }
    }
    return data;
  }

  const expandMenu = (Mid, index, grpname) => {
    setIsSidebarLoading(true);

    Get(`${_moduleChildrens}/${Mid}`, 1).then((response) => {
      findModuleAndUpdateChildren(
        sidebarData?.data?.panel,
        Mid,
        response.data.panel.children
      );

      setIsSidebarLoading(false);
    });
  };

  const expandMenu_old = (Mid, index, grpname) => {
    setIsSidebarLoading(true);

    Get(`${_moduleChildrens}/${Mid}`, 1).then((response) => {
      const moduleIndex = sidebarData?.data?.panel?.[index]?.modules?.findIndex(
        (obj) => obj?.moduleId === Mid
      );

      const favindex = sidebarData?.data?.panel.findIndex(
        (item) => item.groupName === grpname
      );

      const favmoduleIndex = sidebarData?.data?.panel?.[
        favindex
      ]?.modules?.findIndex((obj) => obj?.moduleId === Mid);

      const favNullindex = findModuleIndex(Mid);
      const favmoduleIndexNullGroup = sidebarData?.data?.panel?.[
        favNullindex?.moduleIndex
      ]?.modules?.findIndex((obj) => obj?.moduleId === Mid);

      if (
        response?.length !== 0 &&
        response?.data?.panel?.moduleId ===
          sidebarData?.data?.panel?.[index]?.modules?.[moduleIndex]?.moduleId
      ) {
        sidebarData.data.panel[index].modules[moduleIndex].children =
          response.data.panel.children;
      } else if (
        response?.length !== 0 &&
        response?.data?.panel?.moduleId ===
          sidebarData?.data?.panel?.[favindex]?.modules?.[favmoduleIndex]
            ?.moduleId
      ) {
        sidebarData.data.panel[favindex].modules[favmoduleIndex].children =
          response.data.panel.children;
      } else if (
        response?.length !== 0 &&
        response?.data?.panel?.moduleId ===
          sidebarData?.data?.panel?.[favNullindex?.moduleIndex]?.modules?.[
            favmoduleIndexNullGroup
          ]?.moduleId
      ) {
        sidebarData.data.panel[favNullindex?.moduleIndex].modules[
          favmoduleIndexNullGroup
        ].children = response.data.panel.children;
      }
      setIsSidebarLoading(false);
    });
  };

  const {
    isLoading: isloadingFilterFav,
    data: filterFavsList,
    refetch: filterFavListFatch,
  } = useQuery(
    'filterFavs',
    () => {
      return Get(_isAllFav, sidebarData.data.companyId);
    },
    {
      retry: true,
      refetchOnWindowFocus: false,
    }
  );

  const comp_Id = sidebarData.data.companyId;
  const { isLoading: isFavLoading, mutate: isFavSave } = useMutation(
    (values) => Post(_isFav, values.postData, comp_Id),
    {
      onSuccess: (res, values) => {},
      onError: (e, values) => {},
    }
  );
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    (async function () {
      // await dispatch(setPermissionsList({ name: _fName, id: _fId }));
    })();
  }, []);

  useEffect(() => {
    document.body.classList.toggle('smallSidebar', isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (debouncedSearchTerm) {
      setIsSearching(true);
      setIsSearch(true);
      Get(getNavSearch(debouncedSearchTerm), comp_Id).then((response) => {
        setFilterNav(response.data.panel);
        setIsSearching(false);
      });
    } else {
      setIsSearch(false);
      setIsSearching(false);
      setFilterNav([]);
    }
  }, [debouncedSearchTerm]);

  return (
    <AuthContext.Consumer>
      {(value) => (
        (value.sidebarData.data.panel = isSearch
          ? filterNav
          : value.sidebarNav),
        (serachfilter = [...value.sidebarData.data.panel]),
        (
          <nav
            className={'sidebar' + (miniSidebar ? ' active' : '')}
            id="sidebar"
          >
            <button
              type="button"
              id="sidebarCollapse"
              className="btn btn-info"
              onClick={() => {
                toggleMiniSide();
                setIsOpen(!isOpen);
              }}
            >
              <i className="fa fa-align-left"></i>{' '}
            </button>
            <a
              onClick={() => window.location.assign('/')}
              className="productLogo"
            >
              <img
                src={userInfo.productLogo}
                height="40"
                className="product-img"
                alt=""
              />
            </a>
            <a onClick={() => history.push('/')} className="mobileLogo">
              <img src={MobileLogo} height="40" alt="" />
            </a>
            <Nav tabs id="myTab">
              <NavItem>
                <RNavLink
                  className={classnames({ active: activeTab === '1' })}
                  onClick={() => {
                    toggleTab('1');
                  }}
                >
                  All
                </RNavLink>
              </NavItem>
              <NavItem>
                <RNavLink
                  className={classnames({ active: activeTab === '2' })}
                  onClick={() => {
                    toggleTab('2');
                    filterFavListFatch();
                  }}
                >
                  Favourites
                </RNavLink>
              </NavItem>
            </Nav>

            <TabContent
              activeTab={activeTab}
              id="all-menu-item"
              className={miniSidebar ? '' : 'sticky-top'}
            >
              <TabPane tabId="1">
                <div className="navigation-custom-search">
                  <input
                    type="text"
                    placeholder="Search in navigation"
                    className="form-control navigation-search"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <span className="navigation-search-icon">
                    <a href="#">
                      <i className="fa fa-search" aria-hidden="true"></i>
                    </a>
                  </span>
                </div>

                <List
                  type="unstyled"
                  className="components"
                  id="accordionExample"
                >
                  {isSearch ? (
                    <SearchItems
                      value={filterNav}
                      fnClick={_fnClick}
                      userInfo={userInfo}
                      modulename={fnDState}
                      isSearching={isSearching}
                      miniSidebar={miniSidebar}
                      saveFav={isFavSave}
                      isFvLoading={isFavLoading}
                      favsChild={value.favsChild}
                    />
                  ) : (
                    <SidebarItems
                      miniSidebar={miniSidebar}
                      value={serachfilter}
                      expandMenu={expandMenu}
                      fnClick={_fnClick}
                      toggle={toggle}
                      open={open}
                      setIsLanding={setIsLanding}
                      isSidebarLoading={isSidebarLoading}
                      setOpen={setOpen}
                      toggleSub={toggleSub}
                      openSub={openSub}
                      userInfo={userInfo}
                      saveFav={isFavSave}
                      isFvLoading={isFavLoading}
                      modulename={fnDState}
                      isSearching={isSearching}
                      favsChild={value.favsChild}
                    />
                  )}
                </List>
              </TabPane>
              <TabPane tabId="2">
                <List
                  type="unstyled"
                  className="components"
                  id="accordionExample"
                >
                  <SimpleBar
                    style={{ height: '70vh' }}
                    autoHide={false}
                    className="simpl"
                  >
                    <div>
                      <FavouritesData
                        value={filterFavsList}
                        isloadingFilterFav={isloadingFilterFav}
                        expandMenu={expandMenu}
                        fnClick={_fnClick}
                        toggle={toggle}
                        open={open}
                        setIsLanding={setIsLanding}
                        setOpen={setOpen}
                        toggleSub={toggleSub}
                        openSub={openSub}
                        userInfo={userInfo}
                      />
                    </div>
                  </SimpleBar>
                </List>
              </TabPane>
            </TabContent>
          </nav>
        )
      )}
    </AuthContext.Consumer>
  );
}

export default withRouter(Sidebar);
